class WebhookModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || '';
        this.name = json.name || '';
        this.description = json.description || '';
        this.method = json.method || '';
        this.exampleBody = json.example_body || '';
        this.sorting = json.sorting || 0;
        this.webhookType = json.webhook_type || 0;
        this.created = json.created || '';
        this.modified = json.modified || '';
    }
    
    toJson() { 
        return {
            //id: this.id,
            name: this.name,
            description: this.description,
            method: this.method,
            example_body: this.exampleBody,
            sorting: this.sorting
            // webhook_type: this.webhookType,
            // created: this.created,
            // modified: this.modified
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        if (!Array.isArray(jsonArray)) return [];
        
        let webhooks = [];
        
        for(let i = 0; i < jsonArray.length; i++) { 
            let w = new WebhookModel(jsonArray[i]);
            if (!!w.id) {
                webhooks.push(w);
            }
        }
        
        return webhooks;
    }
}

export default WebhookModel;
