import React from 'react';
import {Line} from "react-chartjs-2";

const CardBrandLineGraph = (props) => {
    const {title, options, data, chartControls, onMouseMove, subtitle, subTitle} = props;

    if (!data) return (<></>);
    
    const createDefaultScales = (data) => {
        return {
            x: {
                grid: {
                    drawBorder: false,
                    color: 'rgba(255, 255, 255, 0.05)',
                }
            },
            y: {
                grid: {
                    drawBorder: false,
                    color: 'rgba(255, 255, 255, 0.05)',
                },
            }
        };        
    };
    
    let lineOptions = options || {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: 12,
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false
            },
        },
    };
    
    if (!lineOptions.scales) lineOptions.scales = createDefaultScales(data);
    
    let chartSubtitle = subtitle || subTitle;
    
    let header = (<></>);
    let footer = (<></>);
    
    let chartTitle = title;
    let controls = chartControls ? (<span className="line-controls">{chartControls}</span>) : (<></>);
    
    if (chartTitle || chartControls) {
        header = (<h3 className="card-brand-line-graph-header">
            <span>{chartTitle}</span>
            {controls}
        </h3>);
    }
    
    if (chartSubtitle) footer = (<div className="card-brand-line-graph-footer">{chartSubtitle}</div>);
    
    return (<div className="card-brand-line-graph">
        {header}
        <div className="card-brand-graph-container">
            <Line options={lineOptions} data={data} onMouseMove={onMouseMove} />
        </div>
        {footer}
    </div>);
};

CardBrandLineGraph.empty = () => {
    return {
        labels: [],
        datasets: []
    };
}

CardBrandLineGraph.createEmptyData = () => {
    return {
        "visa": {
            "submissions": 0,
            "updates": 0
        },
        "mastercard": {
            "submissions": 0,
            "updates": 0
        },
        "amex": {
            "submissions": 0,
            "updates": 0
        },
        "discover": {
            "submissions": 0,
            "updates": 0
        },
        "name": "",
        "data_method": "",
        "service_method": ""
    };
};

CardBrandLineGraph.createMockTrendData = (days) => {
    let cardBrandData = CardBrandLineGraph.createEmptyData();
    let dateRangeLabels = [];
    
    if (typeof days !== 'number') days = 10;
    
    let cursor = new Date().addDays(-days);
    const now = new Date().getTime();
    const dateFormat = { month: 'short', day: 'numeric' };

    while(cursor.getTime() < now) {
        cursor = cursor.addDays(1);
        dateRangeLabels.push(cursor.toLocaleDateString("en-US", dateFormat));
    }

    let cardBrandColors = cardBrandData.map(item => item.color);
    
    return {
        labels: dateRangeLabels,
        datasets: cardBrandData.map((item) => {
            return {
                label: item.name,
                data: dateRangeLabels.map(() => Math.random() * 1000),
                borderColor: item.color,
                backgroundColor: item.color,
                borderWidth: 1,
                pointRadius: 1.5
            };
        })
    };
}
export default CardBrandLineGraph;
