import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import PartnerModel from "../../components/partners/models/PartnerModel";
import MerchantModel from "../../components/partners/models/MerchantModel";
import BatchModel from "../../components/batches/models/BatchModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressCard, faAnchor, faAnchorCircleCheck, faAnchorCircleExclamation,
    faArrowsRotate, faCreditCard, faFileText, faRightToBracket
} from "@fortawesome/free-solid-svg-icons";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import MasterScreenMain from "../../MasterScreenMain";
import PartnerSubMenu from "../../components/partners/ui/PartnerSubMenu";
import BackButton from "../../components/common/ui/BackButton";
import PartnerService from "../../components/partners/services/PartnerService";
import BatchService from "../../components/batches/services/BatchService";
import ProviderTypeModel from "../../components/batches/models/ProviderTypeModel";
import BatchSubMenu from "../../components/batches/ui/BatchSubMenu";
import PagerController from "../../components/common/ui/pager/PagerController";
import Pager from "../../components/common/ui/pager/Pager";
import WebhookService from "../../components/webhooks/services/WebhookService";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";
import ClipBoard from "../../components/common/ui/ClipBoard";
import ActivityHistoryTable from "@paylani/paylani-react-packages/dist/activity/ui/ActivityHistoryTable";

const BatchDetailsScreen = (props) => {
    const {merchantId, batchId} = useParams();
    const empty = "00000000-0000-0000-0000-000000000000";
    const pid = useParams().partnerId || empty;

    let [partnerId, setPartnerId] = useState(pid);

    let [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId] || new PartnerModel());
    let [merchant, setMerchant] = useState(PartnerService.instance.subMerchantMap[merchantId] || new MerchantModel()); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));
    let [batch, setBatch] = useState(BatchService.instance.batchMap[batchId] || new BatchModel()); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));

    let [currentPage, setCurrentPage] = useState(0);
    let [resubmitState, setResubmitState] = useState(0);
    let pageController = useRef(new PagerController(setCurrentPage, 20)).current;
    let _;
    
    const repostWebhook = async (e) => {
        return await WebhookService.instance.repostWebhookBatchAsync(batchId).then((log) => {
            //
        });
    };
    
    const refreshBatch = async (e) => {
        return await BatchService.instance.getBatchAsync(batchId, true).then((batch) => {
            setBatch(batch);
            
            return batch;
        });
    };
    
    const resubmitBatchAsync = async (e) => {
        const batchIds = [batchId];
        
        await BatchService.instance.resubmitBatchesAsync(batchIds).then((batches) => {
            if (!!batches && batches.lengh > 0) {
                setResubmitState(2);
            }
        })
    };

    const getPartnerAsync = async (partId) => {
        await PartnerService.instance.getPartnerAsync(partId || partnerId)
            .then((partner) => {
                setPartner(partner);
            })
            .catch((error) => console.log('Ex: ' + error));
    };

    const getSubMerchantAsync = async (mid) => {
        const id = mid || merchantId;
        if (!id || id === empty) {
            console.warn("No MerchantId: " + mid + ", " + merchantId);
            return null;
        }
        
        return await PartnerService.instance.getSubMerchantAsync(id, partnerId).then((subMerchant) => {
            if (!!subMerchant?.id) {
                setMerchant(subMerchant);
            }
            
            return subMerchant || null;
        });
    }
    
    useEffect(() => {
        if (partner?.id) {
            setPartnerId(partner.id);
        }

        if (!!batch?.subMerchantId && !merchant?.name && (merchantId === empty || !merchantId || merchantId.length < 30)) {
            console.warn(JSON.stringify(batch, null, 4));
            _ = getSubMerchantAsync(batch.subMerchantId);
        } else { 
            console.warn("No Getting Stuff: " + (merchantId === empty).toString());
        }

    },[partner, batch]);

    useEffect(() => {
    }, [merchant, batch]);

    useEffect(() => {
        if (resubmitState === 1) _ = resubmitBatchAsync();
        if (resubmitState === 2) _ = refreshBatch();
    }, [resubmitState]);
    
    useEffect(() => {
        if (!partner.hasDetails) PartnerService.instance.getPartnerAsync(partnerId)
            .then((partner) => {
                console.log('Got Partner: ' + partner.customer.toJson().first_name);
                setPartner(partner);
            })
            .catch((error) => console.log('Ex: ' + error));

        _ = getSubMerchantAsync();

        let x = refreshBatch(null);
        
        console.log('UseEffect: ' + (new Date()).toString());
    }, []);

    const getSortValue = (a) => {
        return a > 0 && (a < 2 || a > 6) ? -a : a;
    };
    
    batch.results = batch.results.sort((a, b) => {
        let aa = getSortValue(a.resultType);
        let bb = getSortValue(b.resultType);
        
        return (aa > bb) ? -1 : 1;
    });
    
    let batchResultsElements = pageController.mapLineItems(batch.results, (result, index) => {
        const message = result.message?.indexOf("SE Number") > -1 ? result.message + " (" + result.txnId + ")" : result.message;
        
        const resultValue = result.resultType >= 0 ? (<>{result.resultTypeName}</>) : (<span className={"error"}>{message}</span>);
        
        return (<tr key={'result-item-' + index.toString()}>
            <td className="name" title={result.txnId}>{result.name}</td>
            <td width="25%">{result.cardMask}</td>
            <td width="12%">{result.expirationDate}</td>
            <td width="25%">{resultValue}</td>
        </tr>);
    });
    
    let providerType = ProviderTypeModel.getProviderByType(batch.providerType);
    let processClassName = (batch.modified === null) ? ' not-processed' : '';

    let pickupMessage = batch.isPickedUp ?
        (<div className="picked-up-message picked-up date-details"><FontAwesomeIcon icon={batch.getIcon()} /> Picked up on <strong><DateTime value={batch.pickupDate} time={true} /></strong></div>) :
        (<div className={"picked-up-message not date-details" + processClassName}><FontAwesomeIcon icon={batch.getIcon()} /> Batch not picked up yet</div>);
    
    let refreshButton = batch.batchStatus === 2 ?
        (<RefreshButton onClick={repostWebhook} caption={"Repost Webhook"} icon={faAnchor} errorIcon={faAnchorCircleExclamation} completedIcon={faAnchorCircleCheck} />) :
        (<></>);
    
    let updateRateElement = (<></>);

    if (batch.batchStatus === 2) {
        let rate = batch.results.reduce((sum, result) => {
            //sum + result.rate;
            if (result.resultType > 1 && result.resultType < 7) return sum + 1;
            return sum;
        }, 0);

        rate = (rate / batch.results.length).toPercent(1);
        updateRateElement = (<strong className={"update-rate"}>{rate}</strong>);
    }

    const processElement = !!batch?.processId && batch.processId.length > 10 ?
        (<ClipBoard value={batch.processId} title={"Copy ProcessId"}><FontAwesomeIcon icon={faArrowsRotate} /> Process Id: <strong>{batch.processId || 'Not available'}</strong></ClipBoard>) :
        resubmitState > 0 ? (<strong>Resubmitted OK</strong>) : (<><FontAwesomeIcon icon={faArrowsRotate} /> <a onClick={(e) => setResubmitState(1)}>Re-Submit Batch</a> </>);
    
    const processActionElement = resubmitState === 0 ? (<><FontAwesomeIcon icon={faArrowsRotate} /> <DateTime value={batch.batchStatus > 0 ? batch.modified : null} time={true} defaultValue="Not processed yet" prefix="Processed on " wrapWithStrong={true} /></>) :
        (<><FontAwesomeIcon icon={faArrowsRotate} /> Resubmitted</>);
    
    const backUrl = merchantId === empty ? null : '/partners/' + partnerId + '/merchants/' + merchant.id;
    const activityHistoryTable = !!batch?.id ? (<ActivityHistoryTable items={null} itemId={batch.id} />) : null;

    return (
        <MasterScreenMain selection="partners">
            <div className="pad" id="partner-batch-results">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    {partner.name}
                </h1>

                <div className="details-body" id="batch-results">
                    <PartnerSubMenu partner={partner} selection="enrollments" />

                    <div className="pad">
                        <h2>
                            <BackButton to={backUrl} />
                            {merchant.name}: 
                            <span className="subtitle">Batch Results for <ClipBoard value={batch.batchId} title={"Copy ResultId"}>{batch.batchId}</ClipBoard></span>
                            <RefreshButton onClick={refreshBatch} />
                        </h2>

                        <BatchSubMenu batch={batch} partner={partner} merchant={merchant} selection={"details"} />
                        
                        <p>
                            The <strong>Batch Results</strong> below were processed through <a>{providerType.name}</a>.
                        </p>

                        <div className="section-wrap" id="batch-result-details">
                            <div className="dark round16">
                                <div className="date-details">
                                    <FontAwesomeIcon icon={faRightToBracket} /> Submitted on <strong><DateTime value={batch.created} time={true} /></strong>
                                </div>
                                <div className={'date-details' + processClassName}>
                                    {processActionElement}
                                </div>
                                { pickupMessage }
                            </div>
                            <div className="dark round16">
                                <div className={"date-details" + processClassName}>
                                    <ClipBoard value={batch.batchFileName} title={"Copy Batch File Name"}><FontAwesomeIcon icon={faFileText} /> SFTP File: <strong>{batch.batchFileName || 'Not returned or saved'}</strong></ClipBoard>
                                </div>
                                <div className={"date-details" + processClassName}>
                                    { processElement }
                                </div>
                                <div className="date-details">
                                    <ClipBoard value={batch.id} title={"Copy ResultId"}><FontAwesomeIcon icon={faFileText} /> Result Id: <strong>{batch.id || 'Not returned or saved'}</strong></ClipBoard>
                                </div>
                            </div>
                        </div>
                        
                        <h3>
                            <FontAwesomeIcon icon={faCreditCard} />
                            Credit Card Updates
                            
                            {updateRateElement}
                            
                            <span className="subtitle">
                                for BatchId: <ClipBoard value={batch.batchId} title={"Copy ResultId"}>{batch.batchId}</ClipBoard>
                            </span>

                            {refreshButton}
                        </h3>
                        <table className="table-large" width="100%">
                            <thead>
                            <tr>
                                <th className="name">Name / Id</th>
                                <th width="25%">CardMask</th>
                                <th width="12%">Expires</th>
                                <th width="25%">Result</th>
                            </tr>
                            </thead>
                            <tbody>
                            {batchResultsElements}
                            </tbody>
                        </table>
                        
                        <Pager items={batch.results} controller={pageController} />

                        { activityHistoryTable }
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
};

export default BatchDetailsScreen;
