import React, {useEffect, useRef, useState} from 'react';
import UserService from "../../components/people/services/UserService";
import PagerController from "../../components/common/ui/pager/PagerController";
import {Link} from "react-router-dom";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCloudMoon, faCloudSun,
    faPersonRunning,
    faPersonWalking
} from "@fortawesome/free-solid-svg-icons";
import Pager from "../../components/common/ui/pager/Pager";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";
import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import SearchFilter from "@paylani/paylani-react-packages/dist/common/ui/search-filter/SearchFilter";

const UsersScreen = (props) => {
    const [users, setUsers] = useState(UserService.instance.users || []);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterText, setFilterText] = useState("");
    
    let pageController = useRef(new PagerController(setCurrentPage)).current;

    let _;
    
    const refreshUsersAsync = async (e) => {
        return await UserService.instance.getUsersAsync().then((us) => {
            setUsers(us);
        });
    };

    useEffect(() => {
        _ = refreshUsersAsync();
    }, []);

    const nameStyle = { width: '24%' };
    const userNameStyle = { width: '22%' };
    const countStyle = { width: '12%' };
    const emailStyle = { width: '25%' };

    const filteredUsers = users.filter((user) => {
        return user;
    });
    
    const userElements = pageController.mapLineItems(users, (user, index) => {
        let path = '/people/' + user.id;
        let cn = user.id === AuthenticationService.instance.session.user.id ? 'current' : '';
        let date = user.created;
        let dateIcon = faCloudMoon;
        
        if (!!user.lastLogin) { 
            date = user.lastLogin;
            dateIcon = faPersonRunning;
            
            let minuteDifference = (new Date().getTime() - user.lastLogin.toLocalTime().getTime()) / 60000;
            
            if (minuteDifference > 60) cn += ' active';
            else cn += ' super-active';
            
        } else if (!!user.modified) {
            date = user.modified;
            dateIcon = faCloudSun;
            cn += ' used';
        }
        
        return (
            <tr key={index} className={cn.trim()}>
                <td style={nameStyle}><Link to={path}>{user.name}</Link></td>
                <td className="phone-cell" style={userNameStyle}><Link to={path}>{ user.username }</Link></td>
                <td className="email-cell" style={emailStyle}>{user.email}</td>
                <td className={"date"}><DateTime time={true} value={date} useUtc={false} /> <FontAwesomeIcon icon={dateIcon} /></td>
            </tr>
        );
    });

    return (
        <MasterScreenMain selection="users">
            <div className="pad">
                <h1>
                    <FontAwesomeIcon icon={faPersonWalking} />
                    Users
                </h1>

                <div className="details-body">
                    <div className="pad">
                        <h2>
                            Super Admin Users
                            <SearchFilter onFilter={setFilterText} />
                            <RefreshButton onClick={refreshUsersAsync.bind(this)} />
                        </h2>

                        <table className="table-x-large" width="100%">
                            <thead>
                            <tr>
                                <th style={nameStyle}>Name</th>
                                <th className="phone-cell" style={userNameStyle}>Username</th>
                                <th className="email-cell" style={emailStyle}>Email</th>
                                <th>Last Activity</th>
                            </tr>
                            </thead>

                            <tbody>
                            { userElements }
                            </tbody>

                        </table>
                        <Pager id="user-pager-bottom" controller={pageController} items={users} />
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
};

export default UsersScreen;
