import React, {useEffect, useState, useRef} from 'react';
import {useParams} from "react-router-dom";
import PartnerService from "../../components/partners/services/PartnerService";
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faAddressCard, faAnchor, faAnchorCircleCheck, faAnchorCircleExclamation, faSync} from "@fortawesome/free-solid-svg-icons";
import PartnerSubMenu from "../../components/partners/ui/PartnerSubMenu";
import PartnerWebhooksSubMenu from "../../components/partners/ui/PartnerWebhooksSubMenu";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import WebhookService from "../../components/webhooks/services/WebhookService";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

import '../../components/common/ui/dialog-box/Dialog.css';
import Pager from "../../components/common/ui/pager/Pager";
import PagerController from "../../components/common/ui/pager/PagerController";
import ClipBoard from "../../components/common/ui/ClipBoard";
import EmptyBox from "../../components/common/ui/EmptyBox";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";

const PartnerWebhookLogsScreen = (props) => {
    const { partnerId } = useParams();
    let _;
    
    const [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId] || null);
    let [webhookLogs, setWebhookLogs] = useState([]);

    const [dialogOpen, setDialogOpen] = useState(false);
    let [selectedWebhookLog, setSelectedWebhookLog] = useState(null);
    let [tabSelection, setTabSelection] = useState('response');
    let [isPosting, setIsPosting] = useState(0);
    let [currentPage, setCurrentPage] = useState(0);
    let pageController = useRef(new PagerController(setCurrentPage, 20)).current;

    const openDialog = (log, e) => {
        setIsPosting(0);
        setSelectedWebhookLog(log);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
        setSelectedWebhookLog(null);
    };
    
    const getWebhookLogsAsync = async () => {
        await PartnerService.instance.getWebhookLogsAsync(partnerId, '', '').then((logs) => {
            setWebhookLogs(logs);
        });        
    }
    
    const getPartnerAsync = async (force = false) => {
        if (!partner?.hasDetails) force = true;
            
        return await PartnerService.instance.getPartnerAsync(partnerId)
            .then((p) => {
                if (!!p) {
                    setPartner(p);
                    return p;
                }
                
                return partner;
            })
            .catch((error) => console.log('Ex: ' + error));
        
    }
    
    useEffect(() => {
        WebhookService.instance.getWebhooksAsync().then(() => setCurrentPage(currentPage));
        

        _ = getPartnerAsync();
        _ = getWebhookLogsAsync();
    }, []);

    const selectDialogScreen = (key, e) => {
        setTabSelection(key);
    };
    
    let website = partner?.website || '';
    let empty = (<i>Not Entered</i>);

    const getResponseText = (text, success) => {
        if (!text) return (success ? '200 OK' : '500 Internal Server Error');
        
        if ((text + '').length > 100) {
            return text.substring(0, 100) + '...';
        }
        return text;
    };
    
    const setPostingError = (error, delay) => {
        setIsPosting(-1);
        if (!delay || typeof delay !== 'number') delay = 2500;
        
        setTimeout(() => {
            setIsPosting(0);
        }, delay);
    };

    const setPostingSuccess = (delay) => {
        setIsPosting(2);
        
        if (!delay || typeof delay !== 'number') delay = 3500;
        
        console.log('setPostingSuccess: ' + delay);
        
        setTimeout(() => {
            setIsPosting(0);
        }, delay);
    };

    const repostWebhook = (log, e) => {
        if (isPosting !== 0) {
            console.log('Nope: ' + isPosting);
            return;
        }
        
        setIsPosting(1);
        
        WebhookService.instance.repostWebhookLogAsync(log.id).then((response) => {
            setPostingSuccess();
            console.log('Reposted Webhook Log OK: ' + log.id);
        }).catch((error) => {
            setPostingError(error);
            console.log('Ex: ' + error);
        });
    };
    
    let tabSelections = { response: '', payload: '' };
    tabSelections[tabSelection] = ' selected';
    
    let webhookLogElements = pageController.mapLineItems(webhookLogs, (w) => {
        let webhook = WebhookService.instance.webhookMap[w.webhookId] || { name: 'Unknown webhook'};
        let cn = w.success ? 'small' : 'small failed';
        
        return (
            <tr key={w.id} className={cn}>
                <td>{webhook.name}</td>
                <td className="tiny"><a onClick={openDialog.bind(this, w)}>{getResponseText(w.response, w.success)}</a></td>
                <td className="tiny dimmed">{w.url || empty }</td>
                <td><DateTime value={w.created} time={true} /></td>
            </tr>
        );
    });

    let emptyBox = webhookLogs.length === 0 ? (<EmptyBox className={"middle"}><span>Nothing found in { partner?.name } Webhook Post Logs</span><FontAwesomeIcon icon={faAnchor} /></EmptyBox>) : (<></>);
    
    let postingIcon = (<FontAwesomeIcon icon={faAnchor} />);
    let repostCaption = 'Repost';
    switch(isPosting) {
        case -1:
            postingIcon = (<FontAwesomeIcon icon={faAnchorCircleExclamation} className="failed" />);
            repostCaption = 'Error';
            break;
        case 1:
            postingIcon = (<span style={{marginRight: '8px', marginTop: '3px'}}><span className="spinner spinning"></span></span>);
            repostCaption = 'Reposting';
            break;
        case 2:
            postingIcon = (<FontAwesomeIcon icon={faAnchorCircleCheck} className="success" />);
            repostCaption = 'Success';
            break;
        default:
            break;
    }

    const contentValue = tabSelection === 'payload' ? selectedWebhookLog?.payload : selectedWebhookLog?.response;
    const partnerSubmenu = !!partner?.id ? (<PartnerSubMenu partner={partner} selection="webhooks" />) : null;
    const webhookSubmenu = !!partner?.id ? (<PartnerWebhooksSubMenu partner={partner} selection="logs" />) : null;
    
    return (
        <MasterScreenMain selection="partners">
            <div className="pad">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    {partner?.name}
                </h1>

                <div className="details-body medium">
                    { partnerSubmenu }

                    <div className="pad">
                        <h2>
                            Webhook Logs
                            <RefreshButton onClick={getWebhookLogsAsync} />
                        </h2>
                        { webhookSubmenu }
                        <p>
                            
                        </p>

                        <table className="table-x-large" width="100%">
                            <thead>
                                <tr>
                                    <th>Webhook</th>
                                    <th>Response/Payload</th>
                                    <th>URL</th>
                                    <th>DateTime</th>
                                </tr>
                            </thead>
                            <tbody>
                                {webhookLogElements}
                            </tbody>
                        </table>
                        <Pager items={webhookLogs} controller={pageController} />
                        {emptyBox}
                    </div>
                </div>
            </div>

            <Dialog className="dialog-body" width={"lg"} open={dialogOpen} onClose={closeDialog} maxWidth={"lg"} keepMounted aria-describedby="dialog-body">
                <span className="dialog-top-right-corner">
                    <a onClick={repostWebhook.bind(this, selectedWebhookLog)}>
                        {postingIcon}
                        {repostCaption}
                    </a>
                </span>
                
                <DialogTitle>{tabSelection === 'payload' ? "Posted Payload" : "Http Response Body"}</DialogTitle>
                
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <label className="tabs">
                            <span className={"tab" + tabSelections['response']}><a onClick={selectDialogScreen.bind(this, 'response')}>Response</a></span>
                            <span className={"tab" + tabSelections['payload']}><a onClick={selectDialogScreen.bind(this, 'payload')}>Payload</a></span>
                        </label>
                        <textarea disabled className={'code' + (selectedWebhookLog?.success ? '' : ' failed')} value={contentValue}></textarea>
                        <span className="url small">
                            Posted to: <ClipBoard fixed={true} value={selectedWebhookLog?.url}>{selectedWebhookLog?.url}</ClipBoard>
                        </span>
                        <span className={"bottom-right"}><ClipBoard fixed={true} value={contentValue}>Copy</ClipBoard></span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Close</Button>
                </DialogActions>
            </Dialog>
            
        </MasterScreenMain>
    );};

export default PartnerWebhookLogsScreen;
