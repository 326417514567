import React, {useEffect, useState} from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp} from "@fortawesome/free-solid-svg-icons";
import ReportingSubMenu from "../../components/reporting/ui/ReportingSubMenu";
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import CardBrandLineGraph from "../../components/reporting/ui/CardBrandLineGraph";
import CardBrandPieChart from "../../components/reporting/ui/CardBrandPieChart";
import {Doughnut} from "react-chartjs-2";
import DateRangeSelector from "@paylani/paylani-react-packages/dist/common/ui/date-range-selector/DateRangeSelector";
import CreditCardModel from "../../components/payments/models/CreditCardModel";
import ReportingService from "../../components/reporting/services/ReportingService";
import CardBrandTrendSeriesModel from "../../components/reporting/models/CardBrandTrendSeriesModel";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import ReportItemModel from "../../components/reporting/models/ReportItemModel";
import PartnerService from "../../components/partners/services/PartnerService";
import ProviderReportingService from "../../components/reporting/services/ProviderReportingService";

const CardBrandsScreen = (props) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        ArcElement
    );

    let ed = new Date().toFirstOfTheMonth();
    let sd = ed.addMonths(-1);

    let initDate = ReportItemModel.createDateRange(sd, ed);
    let [dateRange, setDateRange] = useState(initDate);
    let [selectedControlKey, setSelectedControlKey] = useState('0');
    
    const [cardBrandTrendData, setCardBrandTrendData] = useState(null);
    let [cardBrandResultTypeCountData, setCardBrandResultTypeCountData] = useState(CardBrandLineGraph.createEmptyData());

    const getCachedData = (key, sd, ed) => {
        let dr = (!!sd && !!ed) ? ReportItemModel.createDateRange(sd, ed) : dateRange;
        let cacheKey = ReportItemModel.getKeyFromDate(key, dr.startDate, dr.endDate);

        return { dr: dr, data: ReportingService.instance.reportMap[cacheKey], cacheKey: cacheKey };
    }

    const getFilteredCounts = (key, e) => {
        let providerType = parseInt(key);
        if (isNaN(providerType) || providerType < 0 || providerType > 2) providerType = 0;
        
        setSelectedControlKey(key.toString());
    };

    const getCardBrandResultCountsData = (force, sd, ed) => {
        let providerKey = '-' + selectedControlKey;
        
        let { data, dr, cacheKey } = getCachedData('card-brand-result-counts' + providerKey, sd, ed);

        if (!!data && !force) {
            console.log('Set Cached Card Brand Result Counts: ' + cacheKey);
            setCardBrandResultTypeCountData(data);
            return;
        }

        console.log('Getting card brand results...');

        ProviderReportingService.instance.getCardBrandResultTypeCountsAsync(selectedControlKey, dr.startDate, dr.endDate, cacheKey).then((data) => {
            console.log('Got card brand results...');
            setCardBrandResultTypeCountData(data);
        });
    };

    const getUpdatesData = (force, sd, ed) => {
        let { data, dr, cacheKey } = getCachedData('card-brand-updates', sd, ed);

        if (!!data && !force) {
            console.log('Set Cached Updates Data: ' + cacheKey)
            setCardBrandTrendData(data.toLineChartDataSet());
            return;
        }

        console.log('Getting Updates Data');
        ReportingService.instance.getMasterCardBrandUpdatesAsync(dr.startDate, dr.endDate, cacheKey).then((data) => {
            console.log('Got Updates Data');
            setCardBrandTrendData(data.toLineChartDataSet());
        });
    };
    
    const onDateRangeChange = (sd, ed) => {
        let dr = ReportItemModel.createDateRange(sd, ed);
        setDateRange(dr);

        refreshData(true, sd, ed);
    };

    const refreshData = (force, sd, ed) => {
        getCardBrandResultCountsData(force, sd, ed);
        getUpdatesData(force, sd, ed);
    };

    useEffect(() => {
        refreshData();
    }, []);

    useEffect(() => {
        getCardBrandResultCountsData();
    }, [selectedControlKey]);
    
    
    const createCardBrandResultTypesTableRow = (key, name) => {
        let item = cardBrandResultTypeCountData[key];
        
        if (!item) return (<></>);
        
        return (<tr key={key}>
            <td>{name}</td>
            <td>{(item["cardnumberupdated"] || 0).formatNumber(0)}</td>
            <td>{(item["cardexpupdated"] || 0).formatNumber(0)}</td>
            <td>{(item["contact"] || 0).formatNumber(0)}</td>
            <td>{(item["closedcontact"] || 0).formatNumber(0)}</td>
            <td>{(item["total"] || 0).formatNumber(0)}</td>
        </tr>);
    };
    
    let totalMap = {};

    let cardBrandTable = CreditCardModel.cardKeys.map((key) => {
        let card = CreditCardModel.cardDescriptors[key];
        totalMap[key] = cardBrandResultTypeCountData[key]["total"] || 0;
        
        return createCardBrandResultTypesTableRow(key, card.name);
    });

    let totalsRow = createCardBrandResultTypesTableRow('totals', 'Total');
    let controlSelections = {};
    
    controlSelections[selectedControlKey] = 'selected';
    
    const onLineHover = (event) => {
        let e = false;
        let c = event.target.attributes;

        return event;
    };

    return (
        <MasterScreenMain selection="reporting">
            <div id="reporting-card-brands" className="pad">
                <h1>
                    <FontAwesomeIcon icon={faArrowTrendUp} />
                    Reporting
                </h1>

                <div className="details-body form">
                    <ReportingSubMenu selection="card-brands" />
                    
                    <div className="pad x-large">
                        <h2>Card Brand Reports</h2>

                        <DateRangeSelector startDate={dateRange.startDate} endDate={dateRange.endDate} onRefresh={onDateRangeChange} />

                        <div id="line-pie-graph-container">
                            <div id="pie-graph-container"><CardBrandPieChart title="Updates" visa={totalMap['visa']} masterCard={totalMap['mastercard']} amex={totalMap['amex']} discover={totalMap['discover']} width={240} height={240} /></div>
                            <div id="line-graph-container"><CardBrandLineGraph subtitle={"Updates by Card Brand: " + dateRange.title} data={cardBrandTrendData} /></div>
                        </div>

                        <h3 className={"subtitle bar-chart-graph-header controls"}>
                            <span>Card Brand Update Types: { dateRange.title }</span>

                            <span className={"line-controls provider"}>
                                <span id={"home-line-controls"}>
                                    <label>View by Provider: </label>
                                    <a className={controlSelections['0']} onClick={getFilteredCounts.bind(this, '0')}>All Providers</a>
                                    <a className={controlSelections['1']} onClick={getFilteredCounts.bind(this, '1')}>FiServ</a>
                                    <a className={controlSelections['2']} onClick={getFilteredCounts.bind(this, '2')}>WorldPay</a>
                                </span>
                            </span>
                        </h3>
                        
                        <table className="table-x-large" width="100%">
                            <thead>
                                <tr>
                                    <th>Card Brand</th>
                                    <th>Card Number</th>
                                    <th>Expiration</th>
                                    <th>Contact</th>
                                    <th>Closed</th>
                                    <th>Total</th>
                                </tr>
                            </thead>

                            <tbody>
                            {cardBrandTable}
                            </tbody>
                            
                            <tfoot className="total">
                            {totalsRow}
                            </tfoot>
                        </table>
                    </div>
                    
                </div>
            </div>

        </MasterScreenMain>
    );
    

};

export default CardBrandsScreen;
