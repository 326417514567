import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBriefcase, faDiamond, faFolderOpen, faPerson, faSearch} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import SearchResultModel from "../models/SearchResultModel";

const SearchResult = (props) => {
    const { result, image } = props;

    const getDefaultData = () => {
        let svg = faSearch;
        let path = result?.href;

        // Kind of a hack to make this work for the legacy MasterSearch
        if (path.startsWith("merchants/")) path = "/partners/00000000-0000-0000-0000-000000000000/" + path.replaceAll("/batches/", "/batch/");
        
        if (!!path && !path.startsWith("/")) path = "/" + path;
        
        return {
            icon: (<><FontAwesomeIcon icon={svg} /></>),
            path: path,
        };

    }

    const previewElement = result.preview ? (<span className="preview">{result.preview}</span>) : null;
    const data = getDefaultData();

    return (<Link to={data?.path || "/"} key={result.id}>
        <span>{ data.icon }</span>
        <span>
            <h4>{ result?.name }</h4>
            {previewElement}
            <p>{result?.text}</p>
        </span>
    </Link>);
};

export default SearchResult;
