import React, {useRef, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";
import NumberDisplay from "@paylani/paylani-react-packages/dist/common/formatting/NumberDisplay";
import {faCancel, faCheckSquare, faSquare} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "@mui/material";

const PlanForm = (props) => {
    const { plan, onClick, onComplete } = props;

    const [mrcTiers, setMrcTiers] = useState(plan?.mrcTiers || null);
    const [transactionTiers, setTransactionTiers] = useState(plan?.transactionTiers || null);
    const [allowPooling, setAllowPooling] = useState(plan?.merchantPooling || false);
    let _;

    const nameRef = useRef();
    const descriptionRef = useRef();
    const mrcRef = useRef();
    const updateFeeRef = useRef();
    const setupFeeRef = useRef();
    const minRef = useRef();

    const togglePooling = (e) => {
        setAllowPooling(!allowPooling);
    };

    const onSubmitAsync = async (e) => {
        if (typeof onClick !== 'function') return;
        
        const json = {
            name: nameRef.current?.value || plan?.name,
            description: descriptionRef.current?.value || plan?.description,
            monthly_fee: parseFloat(mrcRef.current?.value || plan?.monthlyFee.toString()),
            transaction_fee: parseFloat(updateFeeRef.current?.value || plan?.transactionFee.toString()),
            setup_fee: parseFloat(setupFeeRef.current?.value || plan?.setupFee.toString()),
            minimum_amount: parseFloat(minRef.current?.value || plan?.minimumAmount.toString()),
            merchant_pooling: allowPooling,
            transaction_tiers: [],
            mrc_tiers: [],
        };

        e?.preventDefault();

        const rsp = onClick(json, e);
        
        if (typeof rsp.then === 'function') { 
            return await rsp.then((r) => {
                if (typeof onComplete === 'function') onComplete(r);
                return r;
            });
        }
        
        return rsp;
    };

    useEffect(() => { 
        if (!!plan) console.warn("UseEffect Plan: " + plan?.setupFee);
    }, []);
    
    const removeTransactionTier = (index) => {
        if (transactionTiers.length <= 1) return;

        let newTiers = transactionTiers.filter((t, i) => i !== index);
        setTransactionTiers(newTiers);
    };

    const removeMrcTier = (index) => {
        if (mrcTiers.length <= 1) return;

        let newTiers = mrcTiers.filter((t, i) => i !== index);
        setMrcTiers(newTiers);
    };

    const onTierChange = (index, isMrc, e) => {
        let tiers = isMrc ? mrcTiers : transactionTiers;
        let t = tiers[index];

        if (!!t) {
            const c = document.getElementById("tier-count-" + index);
            const p = document.getElementById("tier-price-" + index);

            if (!p || !c) return;

            const countValue = c?.value || "";
            const priceValue = p?.value || "";

            let count = parseFloat(countValue);
            let price = parseFloat(priceValue);

            if (isNaN(count) && isNaN(price)) {
                p.value = "";
                c.value = "";
                return;
            }

            if (isNaN(count)) count = 0;
            if (isNaN(price)) price = 0;

            tiers[index] = { ...t, count: count, updateFee: price };

            let newList = tiers.filter((x) => true);
            if (index === tiers.length - 1 && count > 0 && price > 0) newList.push({});

            if (isMrc) setMrcTiers(newList);
            else setTransactionTiers(newList);
        }
    };

    const createTierElements = (tiers, isMrc) => {
        return tiers?.map((t, i) => {
            const placeholder = i === 0 ? "Ex: 1000" : "";

            const low = i === 0 ? 0 : tiers[i - 1].itemCount + 1;
            const summary = t.itemCount > 0 ? (<div className={"tier-note"}>
                <NumberDisplay value={low} decimalPlaces={0} />to&nbsp;<NumberDisplay value={t.itemCount} decimalPlaces={0} /> updates will be billed at&nbsp;
                <NumberDisplay type={"currency"} value={t.updateFee} defaultValue={"..."} disableZero={true} /></div>) : null;

            const removeElement = i === tiers.length - 1 ? null : (<span className={"remove"}>
                        <a onClick={() => removeMrcTier(i, isMrc)}><FontAwesomeIcon icon={faCancel} /></a>
                    </span>);

            const highNote = t.itemCount > 0 ? t.itemCount.formatNumber(0) : "this range of";
            const notePrice = "between " + low.formatNumber(0) + " and " + highNote + " updates";

            return (
                <div key={"tier-" + i.toString()} className={"tier-row"}>
                    <div>
                        <label>Tier { (i + 1).toString() } &nbsp;&nbsp;&nbsp; Count:</label>
                        <span>
                        <Tooltip title={"Max number of updates that set this price"} placement={"top"} arrow={true}>
                            <input id={"tier-count-" + i} type={"number"} placeholder={placeholder} defaultValue={t.itemCount} onBlur={(e) => onTierChange(i, e)} />
                        </Tooltip>
                    </span>
                        <label>Merchant Fee:</label>
                        <span>
                         <Tooltip title={"Price per update charged if the number of updates is " + notePrice} placement={"top"} arrow={true}>
                             <input id={"tier-price-" + i} type={"number"} defaultValue={t.price} onBlur={(e) => onTierChange(i, e)} />
                         </Tooltip>
                    </span>

                        { removeElement }
                    </div>
                    { summary }
                </div>
            );
        });
    }

    const mrcTierElements = createTierElements(mrcTiers, true);
    const transactionTierElements = createTierElements(transactionTiers, false);

    const poolingIcon = allowPooling ? faCheckSquare :  faSquare;
    const poolingClass = allowPooling ? "checked" : "";

    const mrcTiersElement = mrcTiers?.length > 0 ? (<div className={"tiers-section"} id={"plan-tiers-mrc"}>
        <h3>Monthly Fee Tiers</h3>
        <div className={"form-group-columns label-medium"}>{ mrcTierElements }</div>
    </div>) : null;

    const transactionTiersElement = transactionTiers?.length > 0 ? (<div className={"tiers-section"} id={"plan-tiers-updates"}>
        <h3>Update Fee Tiers</h3>
        <div className={"form-group-columns label-medium"}>{ transactionTierElements }</div>
    </div>) : null;

    return (<div className={"form large"}>
            <div className={"form-group"}>
                <label>Plan Name</label>
                <input ref={nameRef} type={"text"} defaultValue={plan?.name} />
            </div>

            <div className={"form-group"}>
                <label>Optional Description (Will not be seen by the Partner)</label>
                <textarea className={"large"} ref={descriptionRef} defaultValue={plan?.description}></textarea>
            </div>

            <div className={"form-group-columns label-medium"}>
                <div className={"row"}>
                    <label>Monthly Fee <span>(Per Merchant)</span>:</label>
                    <span><input type={"number"} ref={mrcRef} defaultValue={plan?.mrc || ""} /></span>
                </div>
                <div className={"row"}>
                    <label>Update Fee:</label>
                    <span><input type={"number"} ref={updateFeeRef} defaultValue={plan?.updateFee || ""} /></span>
                </div>
                <div className={"row"}>
                    <label>Setup Fee:</label>
                    <span><input type={"number"} ref={setupFeeRef} defaultValue={plan?.setupFee || ""} /></span>
                </div>

                <div className={"row"}>
                    <label>Monthly Minimum:</label>
                    <span><input type={"number"} ref={minRef} defaultValue={plan?.minimumAmount || ""} /></span>
                </div>

                <div className={"row"}>
                    <label>Allow Merchant Pooling:</label>
                    <span className={"check"}><a onClick={togglePooling}><FontAwesomeIcon className={poolingClass} icon={poolingIcon} /></a></span>
                    <span className={"form-note"}>
                        Pooling allows the partner to cap a specified group of submerchants to<br/>
                        a maximum monthly fee. Typcially used for sub-locations of merchants
                    </span>
                </div>
            </div>

            { mrcTiersElement }
            { transactionTiersElement }

            <div className={"button low"}>
                <FormButton onClick={onSubmitAsync}>Update Plan Details</FormButton>
            </div>
        </div>
    );
};

export default PlanForm;

