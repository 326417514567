import React, {useEffect, useState} from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard} from "@fortawesome/free-solid-svg-icons";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import PartnerModel from "../../components/partners/models/PartnerModel";
import {useParams} from "react-router-dom";
import PartnerSubMenu from "../../components/partners/ui/PartnerSubMenu";
import EmptyBox from "../../components/common/ui/EmptyBox";
import PartnerService from "../../components/partners/services/PartnerService";

const PartnerAuthenticationDetailsScreen = (props) => {
    const { partnerId } = useParams();
    let [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId] || new PartnerModel({}));

    useEffect(() => {
        if (!partner.hasDetails) PartnerService.instance.getPartnerAsync(partnerId)
            .then((partner) => {
                console.log('Got Partner: ' + partner.customer.toJson().first_name);
                setPartner(partner);
            })
            .catch((error) => console.log('Ex: ' + error));
    }, []);

    console.log('PartnerId: ' + partnerId);

    return (
        <MasterScreenMain selection="partners">
            <div className="pad">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    {partner.name}
                </h1>

                <div className="details-body">
                    <PartnerSubMenu partner={partner} selection="authentication" />

                    <div className="pad">
                        <h2>Authentication Details</h2>
                        
                        <EmptyBox>You do not have permission to view this thing.</EmptyBox>
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
};

export default PartnerAuthenticationDetailsScreen;