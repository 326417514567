import React, { useState } from 'react';
import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogBoxController from "./DialogBoxController";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";

const DialogBox = (props) => {
    let {
        title,
        controller,
        options,
        onCancel,
        onConfirm,
        onOkay,
        onError,
        okayButton,
        cancelCaption,
        okCaption,
        loadingCaption,
        bodyId,
        children,
        successButtonCaption,
        failButtonCaption,
        hasCancelButton,
        hasOkayButton
    } = props;
    
    if (!options) options = { title: "" };

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogState, setDialogState] = useState(0);
    
    // TODO: Add the ability to change the title, body, and buttons with the controller
    const [dialogTitle, setDialogTitle] = useState(title || options.title);
    const [dialogBody, setDialogBody] = useState(children);
    const [errorMessage, setErrorMessage] = useState("");
    
    if (!loadingCaption) loadingCaption = options.loadingCaption;
    if (!loadingCaption) loadingCaption = "Working";
    if (!okCaption) okCaption = (options.okCaption || 'Okay');
    
    let confirmCancel = typeof onCancel === 'function' ? onCancel : (e) => { 
        return true;
    };

    let onCancelClick = (e) => {
        let result = confirmCancel(e);
        if (result === true) {
            setIsDialogOpen(false);
        }
    };
    
    if (!controller) controller = new DialogBoxController();
    
    controller.open = () => {
        setDialogState(0);
        if (!!controller.title) setDialogTitle(controller.title);
        setIsDialogOpen(true);
    }

    controller.close = () => {
        setIsDialogOpen(false);
    }

    const isConfirm = typeof onConfirm === 'function';
    const isOkay = typeof onOkay === 'function';
    
    const onButtonPressAsync = async (e) => {
        if (dialogState >= 1) return;

        setDialogState(dialogState + 1);

        let result = -1;
        
        if (isConfirm) {
            const isConfirmAsync = onConfirm?.constructor?.name === 'AsyncFunction';

            if (isConfirmAsync) result = await onConfirm(e);
            else result = onConfirm(e);
        }

        if (isOkay) {
            let isAsync = onOkay?.constructor?.name === 'AsyncFunction';
            
            if (isAsync) result = await onOkay(e).catch((err) => {
                if (typeof onError === 'function') onError(err);
                
                const em = err?.response?.data?.message || JSON.stringify(err?.response);
                console.error(em);
                
                setErrorMessage(em);
                
                return false;
            });
            else result = onOkay(e);
        }

        console.log('Dialog Result: ' + result);
        
        if (typeof result === 'number') {
            console.log('Result is number: ' + result);
            setDialogState(2);
            
            setTimeout(() => {
                controller.close();
            }, result);
        } else if (result !== false) {
            controller.close();
        } else {
            setDialogState(0);
        }
    };

    const getLoadingButton = () => {
        if (hasOkayButton === false) return null;
        
        let isSpinning = (dialogState === 1);
        if (!!okayButton && !isSpinning) return okayButton;
        
        let cn = '';
        let icon = null;
        let buttonCaption = (okCaption || options.buttonCaption) || 'Okay';
        
        if (dialogState > 0) {
            buttonCaption = isSpinning ? (loadingCaption || 'Working') : (successButtonCaption || 'Success');
            cn = !!isSpinning ? 'spinning' : 'done';
            icon = isSpinning ? (<label></label>) : (<FontAwesomeIcon icon={faCheckCircle}/>);
        }

        return (<Button onClick={onButtonPressAsync}>{buttonCaption} <span className={cn}>{ icon }</span></Button>);
    };

    const confirmButton = getLoadingButton();
    const body = (controller.body || props.children) || (<span>No Data</span>);
    const defaultTitle = (typeof onConfirm === 'function') ? 'Confirm' : 'Alert';

    const cancelButton = (hasCancelButton !== false) ? (<Button autoFocus color="inherit" onClick={onCancelClick}>
        { cancelCaption || "Cancel" }
    </Button>) : null;
    
    const errorElement = (errorMessage) ? (<div className={"error-box"}>{errorMessage}</div>) : null;

    return (
        <Dialog open={isDialogOpen} className={"dialog-state-" + dialogState.toString()}>
            <DialogTitle id={"confirm-dialog-title"}>{ dialogTitle || defaultTitle }</DialogTitle>
            <DialogContent>
                <DialogContentText id={bodyId || "dialog-body"} className={"dialog-body"}>
                    {errorElement}
                    {body}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={"dialog-buttons"}>
                {cancelButton}
                {confirmButton}
            </DialogActions>

        </Dialog>
        
    );
};

export default DialogBox;