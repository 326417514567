import React, { useState } from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faCode, faFlask, faGears, faShieldHalved} from "@fortawesome/free-solid-svg-icons";
import SettingsSubMenu from "../../components/settings/ui/SettingsSubMenu";
import EnvironmentModel from "@paylani/paylani-react-packages/dist/common/models/EnvironmentModel";
import DialogBox from "../../components/common/ui/dialog-box/DialogBox";
import DialogBoxController from "../../components/common/ui/dialog-box/DialogBoxController";
import LoginForm from "@paylani/paylani-react-packages/dist/authentication/ui/LoginForm";

const SystemConfigScreen = (props) => {
    let selections = {};
    selections[EnvironmentModel.environment.key] = "selected";
    
    const [selectedKey, setSelectedKey] = useState(EnvironmentModel.environment.key);
    const dialogController = useState(new DialogBoxController("Login to a new Environment"))[0];
    
    const promptEnvironmentChange = (environmentKey, e) => {
        setSelectedKey(environmentKey);
        dialogController.title = "Login to " + EnvironmentModel.environments[environmentKey].name + " Environment";
        
        dialogController.setBody(<LoginForm onAuthenticate={onEnvironmentAuthenticationChange} environmentKey={environmentKey} />);
        dialogController.open();
    };
    
    const onEnvironmentAuthenticationChange = (session) => {
        
    };
    
    return (
        <MasterScreenMain selection="settings">
            <div className="pad">
                <h1><FontAwesomeIcon icon={faGears} /> Settings</h1>

                <div className="details-body">
                    <SettingsSubMenu selection="env" />

                    <div className="pad">
                        <h2>Environment Settings</h2>

                        <p>
                            Switch environments for the platform. You will be prompted to re-authenticate when you do. Current environment is <strong>{ EnvironmentModel.environment.name }</strong>
                        </p>

                        <ul className={"action-options hover-check"}>
                            <li className={selections.production || ""} onClick={promptEnvironmentChange.bind(this, 'production')}>
                                <div><FontAwesomeIcon icon={faCheckCircle} /></div>
                                <h4>
                                    <FontAwesomeIcon icon={faShieldHalved} />
                                    Production
                                </h4>
                                
                                <p>Live Data! Be careful when navigating through the Production Environment. These are real customers, with real consequences</p>
                            </li>
                            <li className={selections.stage || ""} onClick={promptEnvironmentChange.bind(this, 'stage')}>
                                <div><FontAwesomeIcon icon={faCheckCircle} /></div>
                                <h4>
                                    <FontAwesomeIcon icon={faFlask} />
                                    Staging
                                </h4>

                                <p>Semi-Live Data. Partners use the Staging Environment to test their processes, so although the consequences are lower, they are still quite real</p>
                            </li>
                            <li className={selections.local || ""} onClick={promptEnvironmentChange.bind(this, 'local')}>
                                <div><FontAwesomeIcon icon={faCheckCircle} /></div>
                                <h4>
                                    <FontAwesomeIcon icon={faCode} />
                                    Development (Local)
                                </h4>

                                <p>Development environment, app, and data. If you are not a developer, it's unlikely you'll have much use for this option.</p>
                            </li>
                        </ul>
                        
                        <DialogBox controller={dialogController} hasOkayButton={false}></DialogBox>
                        
                    </div>
                    
                    
                </div>
            </div>
        </MasterScreenMain>
    );

};

export default SystemConfigScreen;
