import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import PartnerService from "../../../components/partners/services/PartnerService";
import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faAddressCard, faCreditCard, faEnvelope, faExplosion, faFileCode, faFilePdf, faFileZipper, faShare } from "@fortawesome/free-solid-svg-icons";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";
import MasterScreenMain from "../../../MasterScreenMain";
import PartnerSubMenu from "../../../components/partners/ui/PartnerSubMenu";
import NumberDisplay from "@paylani/paylani-react-packages/dist/common/formatting/NumberDisplay";
import {Tooltip} from "@mui/material";
import DialogBox from "../../../components/common/ui/dialog-box/DialogBox";
import DialogBoxController from "../../../components/common/ui/dialog-box/DialogBoxController";
import StatementPaymentForm from "../../../components/partners/ui/StatementPaymentForm";
import StatementService from "../../../components/payments/services/StatementService";
import EnvironmentModel from "@paylani/paylani-react-packages/dist/common/models/EnvironmentModel";

const PartnerStatementDetailsScreen = (props) => {
    const { partnerId, statementId } = useParams();

    let _;
    const initPartner = PartnerService.instance.partnerMap[partnerId] || null;
    const initStatement = PartnerService.instance.statementMap[statementId] || null;
    
    const [statementData, setStatementData] = useState({ statement: initStatement, partner: initPartner});
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [controller, setController] = useState(new DialogBoxController("Pay Monthly Statement", (<strong>Body</strong>)));
    
    const partner = statementData.partner;
    const statement = statementData.statement;

    const setPartner = (p) => {
        setStatementData({ ...statementData, partner: p });
    };

    const setStatement = (s) => {
        setStatementData({ ...statementData, statement: s });
    };

    const getHtmlDownloadLink = () => {
        const envKey = EnvironmentModel.getEnvironmentKeyFromUrl(false);
        console.log("Env Key: " + envKey);
        console.log("Base Url: " + EnvironmentModel.environments[envKey]?.baseUrl);
        
        const baseUrl = envKey === "prod" || envKey === "production" ? "https://api.portal.cardsync.io" : "";
        return baseUrl + "/api/statement/" + statementId + "/render?download=true&session-id=" + AuthenticationService.instance.sessionId;
    };
    
    const getStatementHtml = (statementId, e) => {
        const envKey = EnvironmentModel.getEnvironmentKeyFromUrl(false);
        const url = getHtmlDownloadLink();
        console.log("Download [" + envKey + "]: " + url);
        
        window.location.href = url;
    };

    const showPaymentDialog = (e) => {
        console.log("ControllerId: " + controller.id);
        
        controller.setBody((<StatementPaymentForm statement={statement} customer={partner?.customer} />));
        setTimeout(() => {
            controller.open();
        }, 100);
    };
    
    const getGatewayPaymentStatusAsync = async (e) => {
        if (!statement?.paymentId) return {};
        
        return await StatementService.instance.getGatewayPaymentStatusAsync(statement.paymentId).then((statusItem) => {
            console.warn("Payment Status: ");
            setPaymentStatus(statusItem);
        }).catch((err) => { 
            //
        });
    };
    
    const getPartnerAsync = async (force) => {
        if (!partner || force === true) {
            return await PartnerService.instance.getPartnerAsync(partnerId)
                .then((partner) => {
                    return partner;
                })
                .catch((error) => console.log('Ex: ' + error));
        }
        
        return partner;
    };

    const payInvoiceAsync = async (e) => {
        return await PartnerService.instance.payStatementAsync(statementId).then((st) => { 
            return st;
        });
    };
    
    const getStatementAsync = async (force) => {
        if (!statement || force === true) {
            return await PartnerService.instance.getStatementAsync(statementId)
                .then((statement) => {
                    return statement;
                })
                .catch((error) => console.log('Ex: ' + error));
        }
        
        return statement;
    };
    
    const refreshAsync = async (force) => { 
        await Promise.all([getPartnerAsync(force), getStatementAsync(force)]).then((values) => { 
            setStatementData({ statement: values[1], partner: values[0] });
        })
    }
    
    const refreshAllAsync = async (e) => {
        await refreshAsync(true);
    };
    
    useEffect(() => {
        
        if (!!statementData?.statement?.paymentId) _ = getGatewayPaymentStatusAsync();
        else console.warn("No PaymentId");
        
    }, [statementData]);
    
    useEffect(() => {
        _ = refreshAsync(true);
    }, []);

    const keyFormat = 'yyyy-MM-01';
    const todayKey = (new Date()).addMonths(-1).formatDate(keyFormat);
    const submenu = !!partner?.id ? (<PartnerSubMenu partner={partner} selection="statements" />) : null;
    
    const customer = partner?.customer;
    const address = customer?.address;
    const sendElement = !!customer?.email && customer.email.indexOf("@") > 0 ?
        (<FontAwesomeIcon icon={faEnvelope} />) :
        null;
    
    const careOfCustomer = !!customer?.firstName && !!customer?.lastName;
    const careOfText = careOfCustomer ? "c/o " + customer?.firstName + " " + customer?.lastName : "";
    
    const customerNameElement = sendElement !== null ? (<Tooltip arrow={true} placement={"right"} title={"Re-Send to " + customer.email}><a onClick={(e) => alert("Re-Send to " + customer.email)}>c/o { customer?.firstName + " " + customer?.lastName } { sendElement }</a></Tooltip>) :
        (careOfText);
    
    const addressElements = [];
    
    if (!!address?.street) addressElements.push(<div>{ address?.street + " " + address?.unit }</div>);
    if (!!address?.city) { 
        if (!!address?.zip || address?.state) addressElements.push(<div>{ (address?.city + ", " + address?.state + " " + address?.zip).trim() }</div>);
        else addressElements.push(<div>{ address?.city }</div>);
    }
    
    if (addressElements.length === 0) addressElements.push((<div>No Address on File <Link className={"recover"} to={"/partners/" + partnerId + "/billing"}>Update Address</Link></div>));
    
    let paidText = "Paid";
    
    if (!!paymentStatus) { 
        switch (paymentStatus.status) {
            case "pendingsettlement":
                paidText = "Pending Approval";
        }
    }
    
    const statusElement = (!!statement?.paymentDate) ? 
        (<a title={paymentStatus?.status} className={"paid"}>{ paidText }</a>) : 
        (<a onClick={(e) => showPaymentDialog()}>Pay Now</a>);
    
    const billMethod = !!customer?.bankAccount?.id ? "ACH Bank Transfer" : (!!customer?.customerVaultId ? "Credit Card" : "Invoice");
    
    const activityItems = [
        { name: "Statement Created", date: statement?.created, icon: faExplosion },
        { name: "Sent to jade@jadecharles.com", date: statement?.created?.addMinutes(17), icon: faShare },
        { name: "Paid", date: statement?.created?.addMinutes(67 * 24), icon: faCreditCard },
    ];
    
    const activityElements = activityItems.map((item, index) => {
        return (<tr key={"activity-" + index}>
            <td>
                <FontAwesomeIcon icon={item.icon} />
                { item.name }
            </td>
            <td><DateTime time={true} value={item.date} /></td>
        </tr>);
    });
    
    const statementBody = (<div className={"statement-body"}>
        <div>
            <span>Monthly (Per-Submerchant)</span>
            <span><NumberDisplay type={"currency"} value={statement?.merchantFee} decimalPlaces={2} /></span>
            <span>x <NumberDisplay value={statement?.subMerchantCount} decimalPlaces={0} /></span>
            <span><NumberDisplay type={"currency"} value={statement?.submerchantMrcAmount} decimalPlaces={2} /></span>
        </div>
        <div>
            <span>Per-Update Fee</span>
            <span><NumberDisplay type={"currency"} value={statement?.updateFee} decimalPlaces={2} /></span>
            <span>x <NumberDisplay value={statement?.updateCount} decimalPlaces={0} /> <label>Updates</label></span>
            <span><NumberDisplay type={"currency"} value={statement?.updatesAmount} decimalPlaces={2} /></span>
        </div>
        <div>
            <span>Enrollment Fees</span>
            <span><NumberDisplay type={"currency"} value={statement?.enrollmentFee} decimalPlaces={2} /></span>
            <span>x <NumberDisplay value={statement?.enrollmentCount} decimalPlaces={0} /></span>
            <span><NumberDisplay type={"currency"} value={statement?.enrollmentFeesAmount} decimalPlaces={2} /></span>
        </div>
        <div className={statement?.monthlyMinimum > 0 ? "" : "faded"}>
            <span>Monthly Minimum</span>
            <span><NumberDisplay type={"currency"} value={statement?.monthlyMinimum} decimalPlaces={2} /></span>
            <span>Met/Unmet</span>
            <span><NumberDisplay type={"currency"} value={statement?.minimumAmount} decimalPlaces={2} /></span>
        </div>
        <div className={"total"}>
            <span>Total:</span>
            <span><NumberDisplay type={"currency"} value={statement?.total} decimalPlaces={2} /></span>
        </div>
    </div>);

    console.warn("ControllerId: " + controller.id);
    
    const hasExtras = false;
    const resendElements = hasExtras ? [
        (<a onClick={(e) => alert("Toast")}><FontAwesomeIcon icon={faShare} /> Re-Send</a>),
        (<a onClick={(e) => alert("Toast")}><FontAwesomeIcon icon={faFilePdf} /> Download PDF</a>),
    ] : null;

    return (
        <MasterScreenMain selection="partners">
            <div className="pad full-screen-dialog">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    {partner?.name}
                </h1>

                <div className="details-body body">
                    { submenu }

                    <div className="pad">
                        <h2>
                            Statement { statement?.invoiceNumberDisplay}
                            <RefreshButton onClick={refreshAllAsync} />
                        </h2>
                        
                        <div className={"p-columns"}>
                            <p>
                                {partner?.name} is billed via <strong>{billMethod}</strong>
                                , which can be changed by editing their <Link to={"/partners/" + partnerId + "/billing"}>billing details</Link>.
                            </p>
                            <div className={"statement-status"}>
                                { statusElement }
                            </div>
                        </div>
                        
                        <div id="statement-details">
                            <div className={"statement-header"}>
                                <div className="statement-header-left">
                                    <div>{ partner?.name }</div>
                                    <div className={"customer-name"}>{ customerNameElement }</div>
                                    { addressElements }
                                    <div>Service Dates: <DateTime value={statement?.serviceDate } /> to <DateTime value={statement?.serviceDate?.addMonths(1).addMinutes(-1)} /></div>
                                </div>
                                <div className="statement-header-right">
                                    <h1>{ statement?.total.formatCurrency()}</h1>
                                    <div>Statement Generated On:</div>
                                    <div><DateTime value={statement?.created} time={true} /></div>
                                </div>
                            </div>

                            { statementBody }
                            
                            <div className={"action-panel"}>
                                {resendElements}
                                <a onClick={(e) => getStatementHtml(statement?.id, e)}><FontAwesomeIcon icon={faFileCode} /> Download HTML</a>
                                <a className={"archive"} onClick={(e) => alert("Toast")}><FontAwesomeIcon icon={faFileZipper} /> Archive</a>
                            </div>
                            
                        </div>
                        
                        <div id={"statement-activity-log"}>
                            <table width={"100%"} className={"table-large"}>
                                <thead>
                                    <tr>
                                        <th>Activity</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { activityElements }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <DialogBox controller={controller} onOkay={payInvoiceAsync} />
            
        </MasterScreenMain>
    );

};

export default PartnerStatementDetailsScreen;
