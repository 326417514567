import React from 'react';
import {Link} from "react-router-dom";

const AutomationSubMenu = (props) => {
    const { selection } = props;

    let sel = selection || '';
    let selections = {};

    selections[sel] = 'selected';

    return (
        <div className="submenu merchant">
            <Link className={selections['jobs']} to={'/settings/automation/jobs'}>Run Jobs</Link>
            <Link className={selections['logs']} to={'/settings/automation/joblogs'}>Job History</Link>
            <Link className={selections['main']} to={'/settings/automation'}>Configurations</Link>
        </div>
    );
};

export default AutomationSubMenu;
