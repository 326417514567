import React, { useState } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faCopy} from "@fortawesome/free-solid-svg-icons";
import './ClipBoard.css';

const ClipBoard = (props) => {
    const { children, value, title, fixed, copyDelay } = props;
    
    let [icon, setIcon] = useState(faCopy);
    let [copiedClass, setCopiedClass] = useState('');
    
    if (!value) return (<>{children}</>);
    
    let elementTitle = title || 'Copy to Clipboard';
    const copyElementId = 'clipboard-element-' + Math.floor(Math.random() * 9999999);
    let iconClass = (!!fixed) ? '' : 'copy-clipboard';
    
    const copyToClipboard = (e) => {
        navigator.clipboard.writeText(value).then(() => {
            setIcon(faCheckCircle);
            setCopiedClass(' copied');
            
            let delay = copyDelay || 3500;
            setTimeout(() => {
                setIcon(faCopy);
                setCopiedClass('');
            }, delay);
        });
    };
    
    return (<a title={elementTitle} className={"copy-clipboard" + copiedClass} onClick={copyToClipboard}>{children} <FontAwesomeIcon className={iconClass} icon={icon} /></a>);
};

export default ClipBoard;
