import React, {useEffect, useState} from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faAddressCard, faBuildingColumns, faCalendarCheck, faCalendarPlus, faCalendarWeek, faFileCsv, faIdBadge, faPenToSquare, faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import PartnerSubMenu from "../../components/partners/ui/PartnerSubMenu";
import '../../components/partners/ui/Partners.css';

import {Link, useParams} from "react-router-dom";
import BackButton from "../../components/common/ui/BackButton";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import MerchantSubMenu from "../../components/partners/ui/MerchantSubMenu";

import MerchantModel from "../../components/partners/models/MerchantModel";
import PartnerModel from "../../components/partners/models/PartnerModel";
import PartnerService from "../../components/partners/services/PartnerService";
import ToolTip from "../../components/common/ui/ToolTip";
import DialogBox from "../../components/common/ui/dialog-box/DialogBox";
import DialogBoxController from "../../components/common/ui/dialog-box/DialogBoxController";
import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";

const MerchantEnrollmentDetailsScreen = (props) => {
    const {partnerId, merchantId} = useParams();

    let [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId] || new PartnerModel());
    let [merchant, setMerchant] = useState(PartnerService.instance.subMerchantMap[merchantId] || new MerchantModel()); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));
    let [downloadingVisaClassName, setDownloadingVisaClassName] = useState('');
    let [downloadingMasterCardClassName, setDownloadingMasterCardClassName] = useState('');
    let [enrollmentPayload, setEnrollmentPayload] = useState({ provider: 0, date: null });
    
    let dialogController = new DialogBoxController();
    
    useEffect(() => {
        if (!partner.hasDetails) PartnerService.instance.getPartnerAsync(partnerId)
            .then((partner) => {
                console.log('Got Partner: ' + partner.customer.toJson().first_name);
                setPartner(partner);
            })
            .catch((error) => console.log('Ex: ' + error));

        PartnerService.instance.getSubMerchantAsync(merchantId, partnerId).then((subMerchant) => {
            console.log('Got SubMerchant: ' + subMerchant.name);
            setMerchant(subMerchant);
        });

    }, []);

    const generateExcel = (cardType, e) => {
        if (cardType === 4) setDownloadingVisaClassName('spinning')
        else setDownloadingMasterCardClassName('spinning')
        
        console.log('CardType: ' + cardType);        
        PartnerService.instance.createSubMerchantSpreadsheetAsync(merchant, cardType).then((fileKey) => {
            if (cardType === 4) setDownloadingVisaClassName('');
            else setDownloadingMasterCardClassName('');

            window.location.href = '/api/partner/enrollmentspreadsheet/' + fileKey;
        }).catch((error) => {
            if (cardType === 4) setDownloadingVisaClassName('');
            else setDownloadingMasterCardClassName('');
            console.log('Ex: ' + error);
        });
    };
    
    const toggleEnrollmentDateForm = (e) => {
        console.log('Toggle');
        dialogController.title = "Complete Final Enrollment";
        dialogController.open();
    };
    
    const onEnrollmentDateChange = (key, e) => {
        let value = e?.target?.value || 'NULL';
        let ec = {
            date: enrollmentPayload.date,
            provider: enrollmentPayload.provider
        };
        
        if (key === 'date') { 
            console.log('Date: ' + value);
        } else if (key === 'provider') { 
            console.log('Provider: ' + value);
        }

        ec[key] = value;
        setEnrollmentPayload(ec);
    };
    
    const enrollMerchantAsync = async (e) => {
        if (enrollmentPayload.provider !== '2' && enrollmentPayload.provider !== '1') return false;
        
        return await PartnerService.instance.completeSubMerchantEnrollmentAsync(merchantId, enrollmentPayload.provider, partnerId, enrollmentPayload.date).then((m) => {
            setMerchant(m);
            return 1200;    // Delay for the dialog "Done" button (1.2 seconds)
        }).catch((err) => { 
            //
        });
    };
    
    const enrollmentDateElement = (<a onClick={toggleEnrollmentDateForm}>Not Entrolled Yet (Edit)</a>);
    
    const d = new Date();
    const dateKey = d.getMonth().toString() + "_" + d.getDate().toString() + "_" + d.getFullYear().toString();
    
    let myName = "";
    
    if (!!AuthenticationService.instance?.session?.user) {
        myName = AuthenticationService.instance.session.user.firstName + " " + AuthenticationService.instance.session.user.lastName
    }
    
    const clearEnrollmentDate = (e) => {
        setEnrollmentPayload({ provider: enrollmentPayload.provider, date: null });
    };
    
    let clearDateButton = !!enrollmentPayload?.date ? (<> (<a onClick={clearEnrollmentDate}>Clear</a>)</>) : (<></>);
    
    return (
        <MasterScreenMain selection="partners">
            <div className="pad" id="partner-merchant-details">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    {partner.name}
                </h1>

                <div className="details-body">
                    <PartnerSubMenu partner={partner} selection="enrollments" />

                    <div className="pad">
                        <h2>
                            <BackButton to={'/partners/' + partnerId + '/enrollments'} />
                            {merchant.name}:
                            <span className="subtitle">Details</span>
                        </h2>

                        <MerchantSubMenu partner={partner} merchant={merchant} selection={"details"} />

                        <p>
                            Merchants (aka: Submerchants) are not billed directly. <Link to={"/partners/" + partnerId}>{partner.name}</Link> is the parent Partner of {merchant.name}, and therefore will be billed for all updates associated with this Merchant.
                        </p>

                        <h3>
                            <FontAwesomeIcon icon={faBuildingColumns} />
                            Merchant Details
                        </h3>

                        <div className="section dark round12 merchant-details-section" id="merchant-identifiers">
                            <div className="rows" id="merchant-identifiers">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faIdBadge} size="3x" />
                                </span>
                                <span>
                                    <ul>
                                        <li>Identifiers</li>
                                        <li>Legal Entity: {merchant.enrollmentName || 'N/A'}</li>
                                        <li>MID: {merchant.mid}</li>
                                        <li>Id: {merchant.id}</li>
                                        <li>User: <strong>{merchant.user?.username || '[No User Assigned]'}</strong></li>
                                    </ul>
                                </span>
                            </div>

                            <div className={"status-floater"}>
                                <ToolTip value={merchant.providerStatusDescription}>
                                    <span className={"status-box " + merchant.providerStatusKey}>
                                        <label>{ merchant.providerStatus }</label>
                                    </span>
                                </ToolTip>
                            </div>
                        </div>

                        <div className="section dark round12 merchant-details-section" id="merchant-enrollment-details">
                            <div className="rows" id="merchant-enrollment-info">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faPeopleGroup} size="3x" />
                                </span>
                                
                                <span>
                                    <ul>
                                        <li>{merchant.providerName} Enrollment Details</li>
                                        <li id="enrollment-card-counts">
                                            <span className="visa">Visa: {merchant.visaCount}</span>
                                            <span className="mastercard">MasterCard: {merchant.masterCardCount}</span>
                                            <span className="discover">Discover: {merchant.discoverCount}</span>
                                            <span className="amex">Amex: {merchant.amexCount}</span>
                                        </li>
                                        <li className={"date"}><FontAwesomeIcon icon={faCalendarPlus} /> <DateTime value={merchant.created} time={true} prefix="Submitted on: " /></li>
                                        <li className={"date" + (merchant.approveDate ? " approved" : "")}><FontAwesomeIcon icon={faCalendarCheck} /> <DateTime value={merchant.approveDate} time={true} defaultValue="Not Approved" prefix="Approved on: " /></li>
                                        <li className={"date" + (merchant.providerAssignDate ? " completed" : " disabled")}>
                                            <FontAwesomeIcon icon={merchant.providerAssignDate ? faCalendarWeek : faPenToSquare} /> <DateTime value={merchant.providerAssignDate} time={true} defaultValue={enrollmentDateElement} prefix="Assigned on: " />
                                        </li>
                                        <li className="excel">
                                            <a onClick={generateExcel.bind(this, 4)} className={downloadingVisaClassName}>
                                                <span><FontAwesomeIcon icon={faFileCsv} size="2x" /></span>
                                                <span>Visa Excel File: Paylani_DBA_CardSync_{dateKey}.xlsx</span>
                                                <span className="loading-icon"><label></label></span>
                                            </a>
                                        </li>
                                        <li className="excel">
                                            <a onClick={generateExcel.bind(this, 5)} className={downloadingMasterCardClassName}>
                                                <span><FontAwesomeIcon icon={faFileCsv} size="2x" /></span>
                                                <span>MasterCard Excel File: ABU_CardSync_Registration_{dateKey}.xlsx</span>
                                                <span className="loading-icon"><label></label></span>
                                            </a>
                                        </li>
                                    </ul>
                                </span>

                                <DialogBox controller={dialogController} onOkay={enrollMerchantAsync}>
                                    <span className={"form small"}>
                                        <label>Provider:</label>
                                        <span>
                                            <select onChange={onEnrollmentDateChange.bind(this, 'provider')}>
                                                <option value="0">Select a Provider</option>
                                                <option value="2">WorldPay</option>
                                                <option value="1">FiServ</option>
                                            </select>
                                        </span>
                                        
                                        <label>Optional Enrollment Date{clearDateButton}:</label>
                                        <span>
                                            <input value={enrollmentPayload.date || new Date().toFormDate()} type={"datetime-local"} id={"enrollment-date"} onChange={onEnrollmentDateChange.bind(this, 'date')} />
                                        </span>
                                        
                                        <span id={"assigned-by"}>
                                            Assigned By: {myName}
                                        </span>
                                    </span>
                                </DialogBox>
                                
                            </div>
                            
                        </div>

                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
};

export default MerchantEnrollmentDetailsScreen;

