import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import UserService from "../../components/people/services/UserService";
import UserModel from "@paylani/paylani-react-packages/dist/people/models/UserModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faCommentDots,
    faEnvelope, faFaceDizzy, faFaceFrown,
    faMessage,
    faPaperPlane,
    faPersonFallingBurst,
    faPersonWalking, faRightFromBracket, faSkull
} from "@fortawesome/free-solid-svg-icons";
import MasterScreenMain from "../../MasterScreenMain";
import UserSubMenu from "../../components/people/ui/UserSubMenu";
import BackButton from "../../components/common/ui/BackButton";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";
import PhoneNumber from "@paylani/paylani-react-packages/dist/common/formatting/PhoneNumber";
import {AppBar, Button, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";

const UserAccessScreen = (props) => {
    const { userId } = useParams();
    
    let [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    let [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    let [dialogData, setDialogData] = useState({title: (<></>), text: (<></>)});
    
    let _;
    
    const getWorkingButton = (caption, isSpinning) => {
        let buttonCaption = caption || 'Updating';
        let cn = 'spinning'; // !!isSpinning ? 'spinning' : 'done';
        let icon = isSpinning ? (<label></label>) : (<FontAwesomeIcon icon={faCheckCircle} />);
        
        return (
            <Button className={"spinner"}>
                <div>{buttonCaption} <span className={cn}>{ icon }</span></div>
            </Button>
        );
    };
    
    const defaultDialogAction = () => {
        console.log('Nada');
    }
    
    let [confirmButton, setConfirmButton] = useState((<></>));
    
    const refreshUser = () => {
        UserService.instance.getUserAsync(userId).then((user) => {
            setUser(user);
        });
    };

    const confirmOpen = (e) => {
        setIsConfirmationOpen(true);
    };

    const confirmClose = (e) => {
        setIsConfirmationOpen(false);
    };
    
    const confirmCancel = (e) => {
        console.log("Confirmation canceled");
        setIsConfirmationOpen(false);
    };

    const setDoneButton = (caption, delay) => {
        if (!caption) caption = 'Done';

        setConfirmButton(getWorkingButton(caption, false));
        
        setTimeout(() => {
            setIsConfirmationOpen(false);
        }, delay || 1000);
    }

    useEffect(() => {
        if (!user?.id) {
            refreshUser()
        }
    }, []);
    
    const onClickMe = (key, e) => {
        console.log('Clicked: ' + key);
        confirmOpen(e);
    };

    // Send reset
    const sendResetLink = (key, e) => {
        UserService.instance.sendPasswordResetLinkAsync(user.id).then(() => {
            setDoneButton();
        }).catch((error) => {
            console.error('Error: ' + error);
        });
        
    };
    
    const confirmSendResetLink = (key, e) => {
        let btn = (<Button autoFocus color="inherit" onClick={sendResetLink.bind(this, key)}>
           Send Now
        </Button>);
        
        let dest = key === 'reset-email' ? (<>{user?.email}</>) : (<PhoneNumber value={user?.phone} />);
        let icon = key === 'reset-email' ? (<FontAwesomeIcon icon={faEnvelope} />) : (<FontAwesomeIcon icon={faCommentDots} />);
        let sendVia = key === 'reset-email' ? 'Email' : 'Text Message';
        
        let dd = {
            title: (<>{icon} Send Password Link via {sendVia}</>),
            text: (<>Are you sure you want to send a reset password link to <a>{dest}</a>...?<br/>They might be confused if they aren't ready for the message.</>)
        }
        
        setDialogData(dd);
        setConfirmButton(btn);
        
        confirmOpen(e);
    };

    const killSessionsAsync = async (key, e) => {
        console.log('Kill Sessions: ' + key);

        setConfirmButton(getWorkingButton('Killing', true));
        
        _ = await UserService.instance.deleteSessionsAsync(userId);
        
        setDoneButton();
    };

    const confirmKillSessions = (key, e) => {
        let btn = (<Button autoFocus onClick={killSessionsAsync.bind(this, key)}>
            Yes, Kill Sessions
        </Button>);

        let dd = {
            title: (<><FontAwesomeIcon icon={faPersonFallingBurst} /> Boot <a>{user?.name}</a>...?</>),
            text: (<>All sessions will end and this user will be redirected to the nearest login screen. Continue?</>)
        }

        setDialogData(dd);
        setConfirmButton(btn);

        confirmOpen(e);
    };

    const deactivateUser = (key, e) => {
        console.log('DEACTIVATE: ' + key);
        setDoneButton();
    };
    
    const confirmDeactivate = (key, e) => {
        let btn = (<Button autoFocus color="inherit" onClick={deactivateUser.bind(this, key)}>
           Yes, Deactivate
        </Button>);

        let dd = {
            title: (<><FontAwesomeIcon icon={faSkull} /> Deactivate <a>{user?.name}</a>...?</>),
            text: (<>This user will no longer have access to this system, and all notifications will stop. Continue?</>)
        }

        setDialogData(dd);
        setConfirmButton(btn);

        confirmOpen(e);
    };

    const sendViaTextElement = !!user?.phone ? (<> via email, or <strong><PhoneNumber value={user?.phone} /></strong> via text message</>) : (<></>);
    const sendViaElement = (<><strong>{user?.email}</strong> {sendViaTextElement}</>);
    
    const userSubMenu = !!user?.id ? (<UserSubMenu user={user} selection={"access"} />) : null;
    
    return (
        <MasterScreenMain selection="users">
            <div className="pad">
                <h1>
                    <FontAwesomeIcon icon={faPersonWalking} />
                    Users
                </h1>

                <div className="details-body">
                    { userSubMenu }
                    <div className="pad">
                        <h2>
                            <BackButton path="/people" />
                            { user?.name }
                            <span className="subtitle">Account Access</span>
                        </h2>

                        <p>
                            Manage access to {user?.name}'s account here.
                        </p>

                        <div id="user-access-body">
                            <ul id="user-access-options" className={"action-options"}>
                                <li>
                                    <h4><FontAwesomeIcon icon={faPaperPlane} /> Re-Send Password Reset Link</h4>
                                    <p>A link will be sent to {sendViaElement} which will take them to a password reset screen</p>
                                    <div className="buttons">
                                        <a onClick={confirmSendResetLink.bind(this, 'reset-email')}><FontAwesomeIcon icon={faEnvelope} /> Send va Email</a>
                                        <a onClick={confirmSendResetLink.bind(this, 'reset-sms')}><FontAwesomeIcon icon={faCommentDots} /> Send va Text Message</a>
                                    </div>
                                </li>
                                <li>
                                    <h4><FontAwesomeIcon icon={faPersonFallingBurst} /> End Sessions (Force Logout)</h4>
                                    <p>{user?.name} will be forceably logged out and all sessions will be revoked</p>
                                    <div className="buttons">
                                        <a onClick={confirmKillSessions.bind(this, 'kill-sessions')}><FontAwesomeIcon icon={faRightFromBracket} /> Force Logout Now</a>
                                    </div>
                                </li>
                                <li>
                                    <h4><FontAwesomeIcon icon={faSkull} /> Deactivate Account</h4>
                                    <p>Account will be rendered unusable and {user?.name} will be forceably logged, to never return again</p>
                                    <div className="buttons">
                                        <a onClick={confirmDeactivate.bind(this, 'delete-account')}><FontAwesomeIcon icon={faFaceDizzy} /> Deactivate {user?.firstName}</a>
                                    </div>
                                </li>
                            </ul>

                            <Dialog open={isConfirmationOpen}>
                                <DialogTitle id={"confirm-dialog-title"}>{dialogData.title}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="confirm-dialog-description">
                                        {dialogData.text}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button autoFocus color="inherit" onClick={confirmCancel}>
                                        Cancel
                                    </Button>
                                    {confirmButton}
                                </DialogActions>                                
                            </Dialog>
                            
                        </div>

                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );

};

export default UserAccessScreen;
