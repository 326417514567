import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import SupportTicketModel from "../models/SupportTicketModel";
import SupportTicketThreadModel from "../models/SupportTicketThreadModel";
import GoogleOAuthModel from "../../authentication/models/GoogleOAuthModel";

class SupportTicketService { 
    static instance = new SupportTicketService();

    constructor() {
        this.threads = [];
        this.ticketMap = {};
        this.oAuthModel = null;
    }

    async getSupportTicketAsync(ticketId) {
        if (!ticketId || ticketId.length < 30) {
            console.log("No Ticket.");
            return null;
        }
        
        const path = '/api/support/ticket/' + ticketId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const ticket = new SupportTicketModel(response.data);
            
            if (!!ticket?.id) {
                ticket.isLoaded = true;
                me.ticketMap[ticketId] = ticket;
                return ticket;
            }
            
            return null;
        });
    }

    async getSupportTicketThreadsAsync(startDate, endDate) {
        const path = "/api/support/thread";
        const me = this;

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((response) => {
            const th = SupportTicketThreadModel.fromJsonArray(response?.data);
            
            if (typeof th === 'object' && Array.isArray(th)) {
                me.threads = th;
                return th;
            }
            
            return null;
        });
    }
    
    async getSupportTicketThreadAsync(threadId) {
        const path = "/api/support/thread/" + threadId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const th = new SupportTicketThreadModel(response?.data);
            if (!!th?.id) return th;
            
            return null;
        });
    }
    
    async createSupportTicketAsync(data) {
        const path = "/api/support/ticket";
        const me = this;

        console.warn("Creating Ticket Reply...");
        console.log(JSON.stringify(data, null, 4));

        return await HttpService.instance.postAsync(path, data).then((response) => {
            return new SupportTicketModel(response?.data);
        });
    };
    
    async getGoogleOAuthUrlAsync(returnUrl) {
        const path = "/api/google?return=" + encodeURI(returnUrl || "");
        const me = this;
        
        return await HttpService.instance.getAsync(path).then((response) => {
            const oa = new GoogleOAuthModel(response?.data);
            
            me.oAuthModel = oa;
            
            return oa;
        });
    };
    
    async deleteSupportTicketAsync(ticketId) {
        if (!ticketId || ticketId.length < 30) {
            console.error("No Ticket.");
            return null;
        }
        
        const path = '/api/support/ticket/' + ticketId;
        const me = this;

        return await HttpService.instance.deleteAsync(path).then((rsp) => rsp);
    };
    
    async deleteUserTokenAsync(userToken) {
        const path = "/api/user-token/" + userToken?.id;
        const me = this;
        
        console.warn("DELETE: " + path);
        
        await HttpService.instance.deleteAsync(path).catch((ex) => {
            console.error(ex);
        });
    }
}

export default SupportTicketService;
