import React from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGears} from "@fortawesome/free-solid-svg-icons";
import SettingsSubMenu from "../../components/settings/ui/SettingsSubMenu";

const MessagingScreen = (props) => {

    return (
        <MasterScreenMain selection="settings">
            <div className="pad">
                <h1><FontAwesomeIcon icon={faGears} /> Settings</h1>

                <div className="details-body">
                    <SettingsSubMenu selection="messaging" />

                    <div className="pad">
                        <h2>Messaging Credentials</h2>

                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
};

export default MessagingScreen;
