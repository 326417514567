import React from 'react';
import {Link} from "react-router-dom";

const BatchSubMenu = (props) => {
    const { batch, partner, merchant, selection } = props;
    const partnerId = partner.id;
    const merchantId = merchant.id;

    let sel = selection || '';
    let selections = {};

    selections[sel] = 'selected';

    return (
        <div className="submenu batch">
            <Link className={selections['batches']} to={'/partners/' + partnerId }>{ partner.name }</Link>
            <span className="submenu-delimiter">»</span>
            <Link className={selections['batches']} to={'/partners/' + partnerId + '/merchants/' + merchantId + ''}>{merchant.name} Batches</Link>
            <span className="submenu-delimiter">»</span>
            <Link className={selections['details']} to={'/partners/' + partnerId + '/merchants/' + merchantId + '/details'}>Batch Results</Link>
        </div>
    );
};

export default BatchSubMenu;
