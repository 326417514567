import React from 'react';

class DialogBoxController { 
    constructor(title, body, id) {
        this.id = id || "dialog-box-" + Math.random().toString(36);
        this.title = title || "";
        this.body = body || (<></>);
        this.payload = null;
        
        this.open = () => {
        };
        
        this.close = () => {
        };
    }
    
    setBody(body) { 
        this.body = body;
        return this.body;
    }
    
    setPayload(payload) {
        this.payload = payload;
    }
}

export default DialogBoxController;
