import React from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGears} from "@fortawesome/free-solid-svg-icons";
import SettingsSubMenu from "../../components/settings/ui/SettingsSubMenu";
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";
import AutomationSubMenu from "../../components/settings/ui/AutomationSubMenu";
import BackButton from "../../components/common/ui/BackButton";

const AutomationScreen = (props) => {

    const onSave = async (e) => {
        return await new Promise((resolve, reject) => {
            console.log('Saving...');
            
            setTimeout(() => {
                resolve('Toast message');
            }, 2000);
        });
    };
    
    return (
        <MasterScreenMain selection="settings">
            <div className="pad form">
                <h1><FontAwesomeIcon icon={faGears} /> Settings</h1>

                <div className="details-body">
                    <SettingsSubMenu selection="automation" />

                    <div className="pad">
                        <h2>
                            <BackButton show={"back"}></BackButton>
                            Automation Configurations
                        </h2>
                        <AutomationSubMenu selection="main" />
                        
                        <p>
                            This section contains the configurations for the automated processes like: Batch Processing, Sensitive Data Purging, etc.
                        </p>
                        
                        <div id="automation-settings">
                            <ul className="ul">
                                <li>
                                    <span>Batch Processing: </span>
                                    <span>
                                        <select>
                                            <option value={"60"}>Every Hour</option>
                                            <option value={"120"}>Every 2 Hours</option>
                                            <option value={"240"}>Every 4 Hours</option>
                                            <option value={"360"}>Every 6 Hours</option>
                                            <option value={"480"}>Every 8 Hours</option>
                                            <option value={"720"}>Every 12 Hours</option>
                                            <option value={"1440"}>Once a Day</option>
                                        </select>
                                    </span>
                                </li>

                                <li>
                                    <span>Sync Batch Result Data: </span>
                                    <span>
                                        <select>
                                            <option value={"0"}>When Batch Process Completes</option>
                                            <option value={"12"}>Twice Per Day (Noon and Midnight)</option>
                                            <option value={"24"}>Once Per Day at Midnight</option>
                                            <option value={"30"}>On the Following Billing Period</option>
                                        </select>
                                    </span>
                                </li>

                                <li>
                                    <span>Enrollment Waiting Period: </span>
                                    <span>
                                        <select>
                                            <option value={"24"}>24 Hours</option>
                                            <option value={"36"}>36 Hours</option>
                                            <option value={"48"}>2 Days</option>
                                            <option value={"72"}>3 Days</option>
                                            <option value={"96"}>4 Days</option>
                                            <option value={"120"}>5 Days</option>
                                        </select>
                                    </span>
                                </li>
                                
                                <li>
                                    <span>Sensitive Data Purge: </span>
                                    <span>
                                        <select>
                                            <option value={"10"}>10 Days</option>
                                            <option selected value={"14"}>14 Days</option>
                                            <option value={"18"}>18 Days</option>
                                            <option value={"21"}>21 Days</option>
                                            <option value={"30"}>30 Days</option>
                                        </select>
                                    </span>
                                </li>

                            </ul>
                        </div>
                        
                        <div className="button">
                            <FormButton onClick={onSave}>Update Configurations</FormButton>
                        </div>
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
};

export default AutomationScreen;
