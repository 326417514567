import React from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBriefcase, faCloudSun, faComments, faGears, faRobot, faShieldHalved} from "@fortawesome/free-solid-svg-icons";
import SettingsSubMenu from "../../components/settings/ui/SettingsSubMenu";
import {Link} from "react-router-dom";

const PlansMasterScreen = (props) => {
    const { children, title, bodyClassName } = props;
    
    const cn = typeof bodyClassName === "string" && bodyClassName.length > 0 ? " " + bodyClassName : "";
    return (
        <MasterScreenMain selection="plans">
            <div className={"pad"}>
                <h1><FontAwesomeIcon icon={faGears} /> Settings</h1>

                <div className={"details-body" + cn}>
                    <SettingsSubMenu selection="plans" />

                    <div className="pad">
                        <h2>{ title || "Plans" }</h2>
                        { children }
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
    
};

export default PlansMasterScreen;

