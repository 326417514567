import CreditCardModel from "../../payments/models/CreditCardModel";
import ReportItemModel from "./ReportItemModel";
import BatchResultModel from "../../batches/models/BatchResultModel";

class BatchResultTypeTrendSeriesModel {
    constructor(json) {
        if (!json) json = {};

        this.cardNumberUpdates = ReportItemModel.fromJsonArray(json.card_number);
        this.expirationUpdates = ReportItemModel.fromJsonArray(json.expiry);
        this.contactUpdates = ReportItemModel.fromJsonArray(json.contact);
        this.closedContactUpdates = ReportItemModel.fromJsonArray(json.closed_contact);
        this.validUpdates = ReportItemModel.fromJsonArray(json.valid);
        this.noMatchUpdates = ReportItemModel.fromJsonArray(json.no_match);
        this.noChangeUpdates = ReportItemModel.fromJsonArray(json.no_change);
        
        this.resultTypes = {
            card_number: this.cardNumberUpdates,
            expiry: this.expirationUpdates,
            contact: this.contactUpdates,
            closed_contact: this.closedContactUpdates,
            valid: this.validUpdates,
            no_match: this.noMatchUpdates,
            no_change: this.noChangeUpdates
        };
        
        this.startDate = json.start_date || null;
        this.endDate = json.end_date || null;
        this.name = json.name || 'Update Types Over Time';
        this.dataMethod = json.data_method || '';
        this.serviceMethod = json.service_method || '';
        this.isEmpty = json.is_empty === true;
    }

    hasItems(resultTypeKey) {
        let x = this.resultTypes[resultTypeKey];
        return !(!x || !Array.isArray(x) || x.length === 0);
    }
    
    toLineChartDataSet(borderWidth, pointRadius) {
        const me = this;
        
        let validResults = BatchResultModel.resultTypes.filter((r) => me.hasItems(r.key));
        
        let dataSets = validResults.map((resultType) => {
            return me.toLineChartSeries(resultType, borderWidth, pointRadius);
        });

        let labels = validResults.length > 0 ? this.resultTypes[validResults[0].key].map((x) => x.label || x.xAxisDate.formatDate("MONTH/d")) : [];

        return {
            labels: labels,
            datasets: dataSets
        };
    }

    toLineChartSeries(resultType, borderWidth, pointRadius) {
        if (typeof borderWidth !== 'number') borderWidth = 1;
        if (typeof pointRadius !== 'number') pointRadius = 1.5;

        let typeKey = resultType.key;
        let items = this.resultTypes[typeKey];

        let color = resultType?.color || 'rgba(255, 255, 255, 0.8)';

        return ReportItemModel.createLineChartSeries(items, resultType.name || typeKey, color, borderWidth, pointRadius);
    }
}

export default BatchResultTypeTrendSeriesModel;
