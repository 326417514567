import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import UserService from "../../components/people/services/UserService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPersonWalking } from "@fortawesome/free-solid-svg-icons";
import MasterScreenMain from "../../MasterScreenMain";
import UserSubMenu from "../../components/people/ui/UserSubMenu";
import BackButton from "../../components/common/ui/BackButton";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import PagerController from "../../components/common/ui/pager/PagerController";
import Pager from "../../components/common/ui/pager/Pager";

const UserSessionHistoryScreen = (props) => {

    const { userId } = useParams();
    const [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    const [currentPage, setCurrentPage] = useState(0);
    const [userSessions, setUserSessions] = useState([]);
    
    let pageController = useRef(new PagerController(setCurrentPage, 16)).current;

    const refreshSessions = () => {
        UserService.instance.getUserSessionsAsync(userId, pageController.startDate, pageController.endDate)
            .then((userSessions) => setUserSessions(userSessions));
    };

    useEffect(() => {
        if (!user?.id) {
            UserService.instance.getUserAsync(userId).then((user) => {
                setUser(user);
            });
        }
        
        refreshSessions();
        
    }, []);

    const d = (new Date()).addMinutes(-10);

    let sessionElements = pageController.mapLineItems(userSessions,(activity) => {
        return (
            <tr key={activity.id}>
                <td>{activity.ip}</td>
                <td>{activity.location}</td>
                <td><DateTime value={activity.created} time={true} /></td>
            </tr>
        );
    });

    const userSubMenu = !!user?.id ? (<UserSubMenu user={user} selection={"sessions"} />) : null;
    
    return (
        <MasterScreenMain selection="users">
            <div className="pad">
                <h1>
                    <FontAwesomeIcon icon={faPersonWalking} />
                    Users
                </h1>

                <div className="details-body">
                    { userSubMenu }
                    <div className="pad">
                        <h2>
                            <BackButton path="/people" />
                            { user?.name }
                            <span className="subtitle">Session History</span>
                            <RefreshButton onRefresh={refreshSessions} />
                        </h2>

                        <p>
                            Below are the sessions initiated by <Link to={"/people/" + userId}>{user?.name}</Link> that include IP address and client location. Data is obtained via IP address geo-locator with proxy detection.
                        </p>

                        <div id="user-activity-body">
                            <table className={"table-x-large"} width={"100%"}>
                                <thead>
                                <tr>
                                    <th>IP Address</th>
                                    <th>Location (Estimate)</th>
                                    <th>Date/Time</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {sessionElements}
                                </tbody>
                            </table>
                            
                            <Pager controller={pageController} items={userSessions} />
                        </div>
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );

    
};

export default UserSessionHistoryScreen;
