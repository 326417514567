import PlanModel from "../../plans/models/PlanModel";

class StatementModel {
    static json = {
        "id": "ae3f28cf-a343-4aa3-98d1-d786bbdb4882",
        "card_sync_partner_id": "5ccd9ccb-3a43-4812-bcdb-997dc6f09044",
        "submerchant_count": 1,
        "enrollment_count": 0,
        "update_count": 0,
        "monthly_minimum": 0.0,
        "submerchant_mrc_amount": 7.5,
        "updates_amount": 0.0,
        "enrollment_fees_waived": false,
        "mrc_waived": false,
        "enrollment_fees_amount": 0.0,
        "discount": 0.0,
        "subTotal": 7.5,
        "message": null,
        "total": 7.5,
        "update_type_counts": [
            {
                "name": "Valid",
                "itemType": 4,
                "itemCount": 0
            }
        ],
        "card_type_counts": [],
        "service_date": "2022-06-01T00:00:00",
        "generated_on": "2022-07-17T16:34:04.727",
        "start_date": "2022-06-01T00:00:00",
        "end_date": "2022-06-30T23:59:59",
        "invoice_number_display": "202206-ae3f28cf",
        "created": "2022-07-17T16:34:04.727",
        "modified": null
    };

    constructor(json) {
        this.id = json.id;
        this.partnerId = json.card_sync_partner_id;
        
        this.planId = json.plan_id || (json.partner?.plan_id);
        this.plan = !!json.plan ? new PlanModel(json.plan) : (!!json.partner?.plan ? new PlanModel(json.partner.plan) : null);
        
        this.enrollmentFeesWaived = json.enrollment_fees_waived;
        this.mrcWaived = json.mrc_waived;

        // Counts
        this.subMerchantCount = (json.active_merchants || json.sub_merchant_count) || 0;
        this.enrollmentCount = json.enrollment_count || 0;
        this.updateCount = json.update_count || 0;

        // Prices
        this.updateFee = json.update_fee || 0;
        this.merchantFee = json.merchant_fee || 0;
        this.enrollmentFee = json.enrollment_fee || 0;
        this.monthlyMinimum = json.monthly_minimum || 0;
        
        // Amounts
        this.updatesAmount = json.updates_amount;
        this.submerchantMrcAmount = json.sub_merchant_mrc_amount || (json.submerchantMrcAmount || 0);
        this.enrollmentFeesAmount = json.enrollment_fees_amount || (json.enrollmentFeesAmount || 0);
        this.minimumAmount = json.minimum_amount;
        
        this.discount = json.discount;
        this.subTotal = json.subTotal;
        this.message = json.message;
        this.total = json.total;
        
        this.updateTypeCounts = json.update_type_counts;
        this.cardTypeCounts = json.card_type_counts;
        
        this.invoiceNumberDisplay = json.invoice_number_display;
        
        this.serviceDate = json.service_date ? new Date(json.service_date) : null;
        this.generatedOn = json.generated_on ? new Date(json.generated_on) : null;
        this.paymentDate = json.payment_date ? new Date(json.payment_date) : null;
        this.paymentId = json.payment_id || null;
        
        this.startDate = json.start_date ? new Date(json.start_date) : null;
        this.endDate = json.end_date ? new Date(json.end_date) : null;
        
        this.message = json.message || null;
        
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }    
}

export default StatementModel;
