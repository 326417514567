import AddressModel from "@paylani/paylani-react-packages/dist/geo/models/AddressModel";
import CreditCardModel from "../../payments/models/CreditCardModel";
import BankAccountModel from "../../payments/models/BankAccountModel";

class CustomerModel { 
    constructor(json) {
        if (typeof json !== 'object') json = {};
        
        this.id = json.id;
        this.customerNumber = json.customer_number;
        this.firstName = json.first_name;
        this.lastName = json.last_name;
        this.email = json.email;
        this.phone = json.phone;
        this.customerVauleId = json.customer_vaule_id;
        this.bankAccountId = json.bank_account_id;
        this.bankAccount = new BankAccountModel(json.bank_account);
        this.customerVaultId = json.customer_vault_id || null;
        this.cardVaultId = json.card_vault_id || null;
        this.imageUrl = json.image_url;
        this.addressId = json.address_id;
        
        this.address = new AddressModel(json.address);
        this.creditCard = new CreditCardModel(json.credit_card);
        
        if (!this.creditCard.number && !!json.credit_card_last4) {
            this.creditCard.number = json.credit_card_last4
            this.cardType = json.credit_card_type_key || (this.cardType || 0);
        }
        
    }

    toJson = () => {
        return {
            "id": this.id,
            "first_name": this.firstName || '',
            "last_name": this.lastName || '',
            "email": this.email || '',
            "phone": this.phone || '',
            "image_url": this.imageUrl || '',
            "user_name": this.username || '',
            "user_type": this.userType || 0,
            "address": this.address.toJson(),
            // "bank_account": this.bankAccount?.toJson(),
            // "bank_account_id": this.bankAccountId || 0,
        };
    };    
}

export default CustomerModel;
