import React from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faCommentDots, faComments} from "@fortawesome/free-solid-svg-icons";
import PlatformMessageModel from "../../components/settings/models/PlatformMessageModel";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import {Link} from "react-router-dom";
import SettingsSubMenu from "../../components/settings/ui/SettingsSubMenu";

const MessagingScreen = (props) => {
    const {x} = props;

    let messages = PlatformMessageModel.getMockMessages().map((m) => new PlatformMessageModel(m));
    
    let messageList = messages.map((m) => {
        let messageTypeIcon = m.messageType === 'Email' ? (<FontAwesomeIcon icon={faEnvelope} />) : (<FontAwesomeIcon icon={faComments} />);
        
        return (<tr key={m.id}>
            <td><Link to={'/settings/system-messages/' + m.id}>{ messageTypeIcon } {m.name}</Link></td>
            <td className="type">{m.messageType}</td>
            <td><DateTime time={true} value={m.created} /></td>
        </tr>)
    });
    
    return (
        <MasterScreenMain selection="settings">
            <div className="pad">
                <h1>
                    <FontAwesomeIcon icon={faComments} />
                    Messaging
                </h1>

                <div className="details-body">
                    <SettingsSubMenu selection="messages" />
                    
                    <div className="pad">
                        <h2>Platform Messages</h2>
                        <p>
                            All emails and text messages (sms) that get sent out, platform-wide.
                        </p>
                        
                        <table className="table-x-large" width="100%" id="messaging-list-table">
                            <thead>
                            <tr>
                                <th>&nbsp; Name</th>
                                <th className="type">Type</th>
                                <th>Created</th>
                            </tr>
                            </thead>

                            <tbody>
                            { messageList }
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
};

export default MessagingScreen;
