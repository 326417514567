import React from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp} from "@fortawesome/free-solid-svg-icons";
import ReportingSubMenu from "../../components/reporting/ui/ReportingSubMenu";

const TrendsScreen = (props) => {
    const {} = props;

    return (
        <MasterScreenMain selection="reporting">
            <div id="reporting-" className="pad">
                <h1>
                    <FontAwesomeIcon icon={faArrowTrendUp} />
                    Reporting
                </h1>

                <div className="details-body">
                    <ReportingSubMenu selection="trends" />

                    <div className="pad">
                        <h2>Trends</h2>

                        <p>
                            Reporting is currently under construction.
                        </p>
                    </div>
                </div>
            </div>

        </MasterScreenMain>
    );
};

export default TrendsScreen;
