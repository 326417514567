
class WebhookLogModel { 
    static json = {
        "merchant_id": "b7e6b0d5-eb03-4d75-acf8-83f40845ec2b",
        "webhook_id": "62168d4e-ab2f-4fb1-938c-71797b763f3a",
        "url": "https://webhook.site/0ebbb128-8213-4785-ba99-331904094162",
        "payload": "{\"batch_id\":\"e1758c80a77a4e11946747820\",\"card_stats\":null,\"stats\":{\"number_submitted\":5,\"no_change\":0,\"updated_card\":1,\"updated_expiry\":1,\"no_match\":0,\"valid\":0,\"contact\":0,\"contact_closed\":3},\"id\":\"e1758c80-a77a-4e11-9467-c7f84c370f5f\",\"trigger_id\":23,\"trigger_date\":\"2022-07-12T17:56:14.907\",\"event_id\":\"b8e82d07-757a-442b-a282-a520388b3439\",\"message\":\"Amex update\",\"expires\":\"2022-07-13T17:56:15.215338Z\"}",
        "response": "",
        "success": true,
        "id": "c14ea493-e6f7-4395-b707-b9711a60db7f",
        "created": "2022-07-12T17:56:15.233",
        "modified": null
    };
    
    constructor(json) {
        this.id = json.id;
        this.merchantId = json.merchant_id;
        this.webhookId = json.webhook_id;
        this.url = json.url;
        this.payload = json.payload;
        this.response = json.response;
        this.success = json.success;
        this.created = json.created;
        this.modified = json.modified;
    }

    static fromJsonArray(jsonArray) {
        let list = [];

        for(let i = 0; i < jsonArray.length; i++) {
            let wl = new WebhookLogModel(jsonArray[i]);
            if (!!wl.id) {
                list.push(wl);
            }
        }

        return list;
    }
}

export default WebhookLogModel;
