import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import logo from './images/cardsync-logo-vertical-color.png';
import cardSyncLogo from './images/cardsync-logo-white.png';
import evokeLogo from './images/evoke-logo-white.png';
import paylaniLogo from './images/paylani-p-white.png';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressCard,
    faArrowTrendUp,
    faGears,
    faHouseChimneyCrack,
    faUserGear,
    faBell,
    faHandPeace,
    faSignOutAlt,
    faPersonRifle,
    faPersonChalkboard,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";

import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import ActivityNotificationRow from "./components/messaging/ui/ActivityNotificationRow";
import LoginScreen from "@paylani/paylani-react-packages/dist/authentication/ui/screens/LoginScreen";
import NotificationService from "./components/messaging/services/NotificationService";
import EnvironmentModel from "@paylani/paylani-react-packages/dist/common/models/EnvironmentModel";
import {Tooltip} from "@mui/material";
import JobService from "./components/settings/services/JobService";

const MasterScreenMain = (props) => {
    let _;
    let selections = {};
    let selector = props.selection || 'general';
    let onSessionChange = props.onSessionChange || (() => {});

    const [notificationState, setNotificationState] = useState(0);  // Notification dropdown shown (1) or not (0)
    const [session, setSession] = useState(AuthenticationService.instance.session);
    const [sessionState, setSessionState] = useState(0);
    const [environment, setEnvironment] = useState(EnvironmentModel.environment);
    const [updateCount, setUpdateCount] = useState(0);
    const [ssoUrl, setSsoUrl] = useState("");

    if (selector) selections[selector] = 'selected';

    const ftpCheckMinuteInterval = 15;
    
    const onSessionStatusChange = () => {
        if (typeof onSessionChange === 'function') onSessionChange(AuthenticationService.instance.session);
    };

    const pauseAsync = async (millis) => {
        await new Promise((resolve) => {
            setTimeout(resolve, millis);
        });
    };

    const checkFtpFilesAsync = async (force = false) => { 
        const timerKey = "worldpay";
        const lastCheckTime = JobService.instance.lastFtpChekcs[timerKey];
        const thresh = new Date().addMinutes(-ftpCheckMinuteInterval);
        
        if (!force && typeof lastCheckTime?.getTime === "function") {
            if (thresh.getTime() < lastCheckTime.getTime()) {
                console.log("Skipping FTP notification check: " + thresh);
                return;
            }
        }
        
        console.log("Getting FTP Files...");
        const result = await JobService.instance.getFtpFilesAsync(timerKey);
        
        if (result === null) return;
        
        if (result?.success === true) {
            if(result.file_count > 0) {
                const s = result.file_count === 1 ? "" : "s";
                const path = "/settings/automation/jobs";
                NotificationService.instance.setNotification(timerKey, result.file_count + " Batch File" + s + " Available", path);
            }
            else {
                NotificationService.instance.removeNotification(timerKey);
                console.log("Zero new files, but successfully returned")
            }
            setUpdateCount(updateCount + 1);
        }
    }
    
    const ssoNavigateAsync = async () => {
        if (!ssoUrl) return;

        //const token = await AuthenticationService.instance.createSingleSignOnTokenAsync();
        const s = ssoUrl.indexOf("?") > 0 ? "&" : "?";
        await pauseAsync(1000);

        window.open(ssoUrl + s + "access-token=" + session?.id);
        setSsoUrl("");
    };

    NotificationService.instance.onUiUpdate = (data) => {
        console.warn("MasterScreenMain: " + JSON.stringify(data, null, 4));
        setUpdateCount(updateCount + 1);
    };
    
    useEffect(() => {
        if (!!ssoUrl && ssoUrl.startsWith("http")) {
            _ = ssoNavigateAsync();
        }

    }, [ssoUrl]);
    
    AuthenticationService.instance.onUpdate = (value) => {
        console.log('onAuthUpdate[' + updateCount + ']: ' + value);
        setUpdateCount(updateCount + 1);
        _ = checkFtpFilesAsync();
    };
    
    const onAuthenticate = (session) => {
        if (!AuthenticationService.instance.session) {
            onSessionStatusChange();
            return;
        }
        
        setSession(AuthenticationService.instance.session);
        setSessionState(1);
        
        onSessionStatusChange();
    };

    AuthenticationService.instance.onSignOut = () => {
        setSession(null);
        setNotificationState(0);
        onSessionStatusChange();
    };

    if (!AuthenticationService.hasValidSession()) {
        AuthenticationService.instance.onLogin = () => { 
            //
        };
        
        if (AuthenticationService.instance.isLoggedIn()) {
            //console.log('Getting existing session: ' + AuthenticationService.instance.sessionId);
            AuthenticationService.instance.getSessionAsync().then((x) => onAuthenticate(AuthenticationService.instance.session));
            if (AuthenticationService.instance.session === null) return (<div style={{padding: '32px'}}>Logging in...</div>);
        }
        else return (<LoginScreen onAuthenticate={onAuthenticate} version={"3.2.0"} />);
    }

    const signOut = (e) => {
        AuthenticationService.instance.signOutAsync().then(() => {
            setSession(null);
            setSessionState(0);
            if (typeof onSessionChange === 'function') onSessionChange(null);
        }).catch((error) => console.log('Ex: ' + error));
    };

    let user = session?.user;
    let imageUrl = user?.imageUrl || '';

    let notifications = NotificationService.instance.notifications;
    let notificationsList = notifications.map((notification, index) => (<ActivityNotificationRow key={'notifications-item-' + index.toString()} notification={notification} index={index} />));

    const onNotificationClick = () => {
        
        
        if (notifications.length === 0 && notificationState === 0) return;
        console.log('Clicked notification');
        
        let newState = notificationState;
        
        if (newState === 0) newState = 1;
        else newState = 0;
        
        setNotificationState(newState);
    };
    
    const closeNotificationBox = (e) => {
        if (notificationState === 0) return;
        if (e.target.tagName === 'path' || (e.target.id && e.target.id.indexOf('notification') >= 0)) {
            return;
        }
        
        setNotificationState(0);
    };

    let notificationBadge = notifications.length > 0 ? (<span className="badge" id="notification-004">
                            <span className="badge-content" id="main-notification-badge">{ notifications.length.formatNumber(0)}</span>
                        </span>) : (<></>);

    let notificationsBox = (notificationState === 0) ?
        (<></>) :
        (<div id="notifications-box">
        <div className="arrow-holder" id="notifications-box-arrow-holder">
            <div className="arrow-up" id="notifications-box-arrow-up"></div>
        </div>
        <div className="content" id="notifications-box-content">
            <ul id="notifications-item-list">
                { notificationsList }
            </ul>
        </div>
    </div>);

    let profileDetails = !!user?.firstName ?
        (<ul><li><Link to={"/people/" + user?.id}>{user?.firstName + ' ' + user?.lastName}</Link></li><li>Master User</li></ul>) :
        (<ul><li>Loading...</li><li>.</li></ul>);

    let profileImage = imageUrl.length > 5 ?
        (<img id="my-profile-image-top" src={imageUrl} alt="Cardsync Logo" />) :
        (<span className="default-image"><FontAwesomeIcon icon={faUserGear} /></span>);

    const navClasses = {
        popperArrow: "nav-tooltip",
        tooltipPlacementRight: "nav-tooltip-placement",
        tooltipPlacementLeft: "nav-tooltip-placement",
        tooltipPlacement: "nav-tooltip-placement",
        tooltipArrow: "nav-tooltip-arrow",
    };

    // TODO: Move this to a config file
    const paylaniPortalUrl = "http://localhost:5002/";
    const evokePortalUrl = "https://dev.portal.cardsync.io";

    const getLoadingElement = (url) => {
        if (url === ssoUrl) return (<span className={"nav-spinner"}><FontAwesomeIcon icon={faSpinner} /></span>);
        return null;
    }

    const platformMenu = (<ul>
        <li className={"header"}>Platform Portals</li>
        <li id={"cardsync-nav"}><a onClick={() => setSsoUrl(paylaniPortalUrl)}><img src={paylaniLogo} alt={"Paylani Portal"} /> <span>Paylani Portal</span>{ getLoadingElement(paylaniPortalUrl)}</a></li>
        <li id={"evoke-nav"}><a onClick={() => setSsoUrl(evokePortalUrl)}><img src={evokeLogo} alt={"eVoke Portal"} /> <span>eVoke Portal</span>{getLoadingElement(evokePortalUrl)}</a></li>
        <li className={"header"}>Corporate Websites</li>
        <li id={"cardsync-home-nav"}><a target={"_cardsync_home"} href={"https://cardsync.io/"}><img src={cardSyncLogo} alt={"CardSync Portal"} /> <span>CardSync.io</span></a></li>
        <li id={"paylani-home-nav"}><a target={"_paylani"} href={"https://paylani.com"}><img src={paylaniLogo} alt={"CardSync Portal"} /> <span>Paylani.com</span></a></li>
        <li id={"evoke-terminal-nav"}><a target={"_evoke_terminal"} href={"https://portal.cardsync.io/"}><img src={evokeLogo} alt={"CardSync Portal"} /> <span>eVoke Terminal</span></a></li>
    </ul>);

    const isSsoWorking = !!ssoUrl ? true : undefined;
    const navLogo = (<Tooltip title={platformMenu} placement={"right"} arrow={true} classes={navClasses}>
        <Link to="/"><img src={logo} alt="Cardsync.io" /></Link>
    </Tooltip>);

    return (
        <div id="main" onClick={closeNotificationBox}>
            <div id={"environment-div"} className={EnvironmentModel.environment.key}>{ EnvironmentModel.environment.name?.toUpperCase() }</div>
            
            <div id="my-header">
                <span className="section">
                    <span id="my-profile-top-menu-item">
                        {profileImage}
                        <span id="my-profile-account-details-top">
                            {profileDetails}
                        </span>
                    </span>
                </span>
                <span className="section" id="main-notification-section" onClick={onNotificationClick}>
                    <span className="content notifications" id="notification-001">
                        <span id="notifications-top">
                            <FontAwesomeIcon icon={faBell} id="notification-003" />
                        </span>
                        {notificationBadge}
                    </span>
                </span>
            </div>
            
            <div id="main-column">
                <div id="logo">
                    {navLogo}
                </div>
                <ul>
                    <li className={selections['home']} id="menu-home"><Link to="/"><FontAwesomeIcon icon={faHouseChimneyCrack} /> Home</Link></li>
                    <li className={selections['partners']} id="menu-partners"><Link to="/partners"><FontAwesomeIcon icon={faAddressCard} /> Partners</Link></li>
                    <li className={selections['reporting']} id="menu-reporting"><Link to="/reporting"><FontAwesomeIcon icon={faArrowTrendUp} /> Reporting</Link></li>
                    <li className={selections['users']} id="menu-users"><Link to="/people"><FontAwesomeIcon icon={faPersonRifle} /> Users</Link></li>
                    <li className={selections['plans']} id="menu-plans"><Link to="/plans"><FontAwesomeIcon icon={faPersonChalkboard} /> Plans</Link></li>
                    <li className={selections['support']} id="menu-messaging"><Link to="/support"><FontAwesomeIcon icon={faHandPeace} /> Customer Support</Link></li>
                    <li className={selections['settings']} id="menu-settings"><Link to="/settings"><FontAwesomeIcon icon={faGears} /> Settings</Link></li>
                </ul>
                
                <ul id="mini-menu">
                    <li>
                        <a onClick={signOut}>
                            <FontAwesomeIcon icon={faSignOutAlt} /> Sign Out
                        </a>
                    </li>
                </ul>
            </div>

            <div id="main-body" className={selector}>
                { props.children }
            </div>
            { notificationsBox }
        </div>
        
    )
};

export default MasterScreenMain;
