import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import PartnerModel from "../models/PartnerModel";
import MerchantModel from "../models/MerchantModel";
import WebhookLogModel from "../../webhooks/models/WebhookLogModel";
import PartnerWebhookModel from "../models/PartnerWebhookModel";
import StatementHistoryItemModel from "../models/StatementHistoryItemModel";
import BatchModel from "../../batches/models/BatchModel";
import ReportItemModel from "../../reporting/models/ReportItemModel";
import BatchResultTypeTrendSeriesModel from "../../reporting/models/BatchResultTypeTrendSeriesModel";
import CardBrandTrendSeriesModel from "../../reporting/models/CardBrandTrendSeriesModel";
import CustomerModel from "../../people/models/CustomerModel";
import StatementModel from "../models/StatementModel";

class PartnerService { 
    static instance = new PartnerService();

    constructor(props) {
        this.partnerMap = {};
        this.subMerchantMap = {};
        this.subMerchantsMap = {};
        this.partners = [];
        this.statementHistory = [];
        this.statementMap = {};
        this.batchActivity = {};
        this.cardBrandMap = {};
        
        this.reportMap = {};
    }

    async getPartnersAsync() { 
        const me = this;
        const url = '/api/partner';
        
        return await HttpService.instance.getAsync(url).then((rsp) => me.createPartnersList(rsp.data));
    }

    async updatePartnerAsync(partnerId, partnerJson) { 
        if (typeof partnerId !== "string" || partnerId.length === 0)
            throw new Error("Missing PartnerId");
        
        const path = "/api/partner/" + partnerId;
        const me = this;
        
        return await HttpService.instance.postAsync(path, partnerJson).then((rsp) => {
            const partner = new PartnerModel(rsp.data);
            me.partnerMap[partner.id] = partner;
            return partner;
        });
    }
    
    createPartnersList(jsonArray) {
        this.partners = [];

        for(let i = 0; i < jsonArray.length; i++) {
            let p = new PartnerModel(jsonArray[i]);
            
            if (!!p.id) {
                this.partners.push(p);
                this.partnerMap[p.id] = p;
            }
        }

        return this.partners;
    }
    
    async getPartnerAsync(partnerId) {
        if (!partnerId) {
            console.error('Failed to get partner. Partner id no good: ' + partnerId);
            return null;
        }
        
        const me = this;
        const url = '/api/partner/' + partnerId;

        return await HttpService.instance.getAsync(url).then((rsp) => me.setPartner(rsp.data));
    }
    
    async getBatchActivityAsync(partnerId, startDate, endDate) {
        if (!partnerId) {
            console.error('Failed to get sub merchants. Partner id no good: ' + partnerId);
            return null;
        }
        
        const me = this;
        const url = '/api/partner/' + partnerId + '/batch';

        return HttpService.instance.getWithDateRangeAsync(url, startDate, endDate).then((rsp) => {
            me.batchActivity[partnerId] = BatchModel.fromJsonArray(rsp.data);
            return me.batchActivity[partnerId];
        });
    }
    
    async getSubMerchantsAsync(partnerId) {
        if (!partnerId) {
            console.error('Failed to get sub merchants. Partner id no good: ' + partnerId);
            return null;
        }

        const me = this;
        const url = '/api/partner/' + partnerId + '/submerchant';

        return await HttpService.instance.getAsync(url).then((rsp) => me.createSubMerchantsList(rsp.data));
    }

    async getSubMerchantAsync(subMerchantId, partnerId) {
        if (!subMerchantId) {
            console.error('Failed to get sub merchant. Sub merchant id no good: ' + subMerchantId);
            return null;
        }
        
        const me = this;
        const url = '/api/partner/' + partnerId + '/submerchant/' + subMerchantId;

        return await HttpService.instance.getAsync(url).then((rsp) => me.setSubMerchant(rsp.data));
    }

    async getStatementHistoryAsync(partnerId, startDate, endDate) {
        const me = this;
        const path = '/api/partner/' + partnerId + '/statement'; //?start-date=' + startDate + '&end-date=' + endDate;

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            return StatementHistoryItemModel.fromJsonArray(rsp.data);
        });
    }
    
    async getStatementAsync(statementId) { 
        const me = this;
        const path = '/api/statement/' + statementId;

        return await HttpService.instance.getAsync(path).then((rsp) => {
            const statement = new StatementModel(rsp.data);
            me.statementMap[statementId] = statement;
            
            return statement;
        });
    }

    // async generateStatementAsync(partnerId, serviceDate) {
    //     if (!partnerId) {
    //         console.error('Failed to get partner. Partner id no good: ' + partnerId);
    //         return null;
    //     }
    //
    //     const me = this;
    //     const url = '/api/partner/' + partnerId;
    //
    //     let payload = {};
    //     return await HttpService.instance.postAsync(url, payload).then((rsp) => {
    //         const statement = new StatementHistoryItemModel(rsp.data);
    //         if (!!statement?.id) {
    //             me.statementMap[statement.id] = statement;
    //         }
    //
    //         return statement;
    //     });
    // };

    async createStatementAsync(partnerId, serviceDate) {
        const me = this;
        const path = '/api/partner/' + partnerId + '/statement?date=' + (serviceDate || "")
        
        return await HttpService.instance.postAsync(path).then((rsp) => {
            let statement = new StatementHistoryItemModel(rsp.data);
            console.log('Created statement: ' + statement?.id);
            
            return statement;
        });
    };
    
    async payStatementAsync(statementId, creditCard = null) {
        const me = this;
        const path = '/api/statement/' + statementId + '/payment';
        
        const data = {
            amount: null,
            credit_card: null,
        };
        
        return await HttpService.instance.postAsync(path, data).then((rsp) => {
            const statement = new StatementModel(rsp.data);
            console.log('Paid statement: ' + statement?.id);
            
            return statement;
        });
    };
    
    async updateBillingCustomerAsync(partnerId, customerJson) { 
        const path = "/api/partner/" + partnerId + "/billing";
        const me = this;
        
        console.log("Updating Partner Billing Customer: " + customerJson.name);
        return await HttpService.instance.postAsync(path, customerJson).then((rsp) => {
            console.log(JSON.stringify(rsp.data, null, 4));
            return new CustomerModel(rsp.data);
        });
    }
    
    async getCardUpdatesByMonthAsync(partnerId, startDate, endDate, cacheKey) {
        const me = this;
        const path = '/api/partner/' + partnerId + '/report/batch/card/result/monthly';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let models = ReportItemModel.fromJsonArray(rsp.data);

            if (!!cacheKey && typeof cacheKey === 'string' && !models.isEmpty) {
                me.reportMap[cacheKey] = models;
            }

            return models;
        });
    }

    async getCardSubmissionsByMonthAsync(partnerId, startDate, endDate, cacheKey) {
        const me = this;
        const path = '/api/partner/' + partnerId + '/report/batch/card/submission/monthly';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let models = ReportItemModel.fromJsonArray(rsp.data);

            if (!!cacheKey && typeof cacheKey === 'string' && !models.isEmpty) {
                me.reportMap[cacheKey] = models;
            }

            return models;
        });
    }

    async getBatchSubmissionsByMonthAsync(partnerId, startDate, endDate, cacheKey) {
        const me = this;
        const path = '/api/partner/' + partnerId + '/report/batch/submission/monthly';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let models = ReportItemModel.fromJsonArray(rsp.data);

            if (!!cacheKey && typeof cacheKey === 'string' && !models.isEmpty) {
                me.reportMap[cacheKey] = models;
            }

            return models;
        });
    }

    async getBatchResultTypesByMonthAsync(partnerId, startDate, endDate, cacheKey) {
        const me = this;
        const path = '/api/partner/' + partnerId + '/report/batch/card/resulttype/monthly';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let model = new BatchResultTypeTrendSeriesModel(rsp.data)

            if (!!cacheKey && typeof cacheKey === 'string' && !model.isEmpty) {
                me.reportMap[cacheKey] = model;
            }

            return model;
        });
    }

    async getCardBrandResultTypeCountsAsync(partnerId, startDate, endDate, cacheKey) {
        const me = this;
        const path = '/api/partner/' + partnerId + '/report/batch/card/brand/resulttype/count';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            if (!!cacheKey && typeof cacheKey === 'string') {
                me.reportMap[cacheKey] = rsp.data;
            }

            return rsp.data;
        });
    }

    async getDailyCardBrandSubmissionsAsync(partnerId, startDate, endDate, cacheKey) {
        const me = this;
        const path = '/api/partner/' + partnerId + '/report/batch/card/brand/submission/daily';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let model = new CardBrandTrendSeriesModel(rsp?.data || {});

            if (!!cacheKey && typeof cacheKey === 'string' && !model.isEmpty) {
                me.reportMap[cacheKey] = model;
            }

            return model;
        });
    }

    async getDailyCardBrandUpdatesAsync(partnerId, startDate, endDate, cacheKey) {
        const me = this;
        const path = '/api/partner/' + partnerId + '/report/batch/card/brand/result/daily';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let model = new CardBrandTrendSeriesModel(rsp?.data);

            if (!!cacheKey && typeof cacheKey === 'string' && !model.isEmpty) {
                console.log('Cached Updates Data: ' + cacheKey);
                me.reportMap[cacheKey] = model;
            }

            return model;
        });
    }

    async getCardBrandSubmissionReportAsync(partnerId, startDate, endDate) {
        const me = this;
        const path = '/api/partner/' + partnerId + '/report/batch';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            me.partnerCardBrandMap[partnerId] = rsp.data;
            return rsp.data;
        });
    }
    
    
    async getWebhooksAsync(partnerId) {
        const me = this;
        const url = '/api/partner/' + partnerId + '/webhook';
        
        return await HttpService.instance.getAsync(url).then((rsp) => PartnerWebhookModel.fromJsonArray(rsp.data));
    }

    async updateWebhookUrlAsync(webhookUrl, webhookId, partnerId) {
        let url = '/api/webhook/' + webhookId + '/partner/' + partnerId;
        let data = { url: webhookUrl, webhook_id: null, webhook_type: null };

        return await HttpService.instance.postAsync(url, data).then((rsp) => {
            return new PartnerWebhookModel(rsp.data);
        });
    }
    
    async approveSubMerchantAsync(subMerchantId, partnerId) {
        const me = this;
        const url = '/api/partner/' + partnerId + '/submerchant/' + subMerchantId + '';
        
        return await HttpService.instance.putAsync(url, {}).then((rsp) => new MerchantModel(rsp.data));
    }
    
    async completeSubMerchantEnrollmentAsync(subMerchantId, providerType, partnerId, enrollmentDate = null) {
        const me = this;
        let url = '/api/partner/' + partnerId + '/submerchant/' + subMerchantId + '/enrollment/' + providerType;
        
        if (typeof enrollmentDate === 'object' && enrollmentDate !== null) { 
            url += '?date=' + enrollmentDate.toDate().toFormDate();
        }
        
        console.log('Complete SubMerchant Enrollment URL: ' + url);
        return await HttpService.instance.putAsync(url, {}).then((rsp) => new MerchantModel(rsp.data));
    };

    async getWebhookLogsAsync(partnerId, startDate, endDate) {
        const me = this;
        const url = '/api/partner/' + partnerId + '/webhooklog?start-date=' + startDate + '&end-date=' + endDate;

        return await HttpService.instance.getAsync(url).then((rsp) => WebhookLogModel.fromJsonArray(rsp.data));
    }

    async createPartnerSpreadsheetAsync(partnerId, cardType) {
        const me = this;

        const url = "/api/partner/" + partnerId + "/spreadsheet/" + cardType.toString();

        return await HttpService.instance.postAsync(url, {}).then((rsp) => rsp.data.id);
    }

    async createSubMerchantSpreadsheetAsync(subMerchant, cardType) {
        const me = this;
        const partnerId = subMerchant.partnerId;
        const merchantId = subMerchant.id;
        
        const url = "/api/partner/" + partnerId + "/submerchant/" + merchantId + "/spreadsheet/" + cardType.toString();
        
        return await HttpService.instance.postAsync(url, {}).then((rsp) => rsp.data.id);
    }
    
    setSubMerchant(json) {
        let subMerchant = new MerchantModel(json);
        
        if (!!subMerchant.id) {
            this.subMerchantMap[subMerchant.id] = subMerchant;
        }
        
        return subMerchant;
    }
    
    setPartner(json) {
        let p = new PartnerModel(json, true);
        
        if (!!p?.id) {
            console.log('Good partner: ' + p.id);
            this.partnerMap[p.id] = p;
        } else { 
            console.log('Invalid Partner Model when setting partner map');
            console.error(JSON.stringify(json));
        }
        
        return p;
    }
    
    setStatementHistory(jsonArray) {
        this.statementHistory = StatementHistoryItemModel.fromJsonArray(jsonArray);
        if (this.statementHistory.length > 0) this.statementHistory[0].status = 0;
        
        return this.statementHistory;
    }

    createSubMerchantsList(jsonArray) {
        let subMerchants = [];
        
        for(let i = 0; i < jsonArray.length; i++) {
            let m = new MerchantModel(jsonArray[i]);
            
            if (!!m.id) {
                subMerchants.push(m);
            }
        }

        if (jsonArray.length > 0) {
            let partnerId = jsonArray[0].partner_id;
            this.subMerchantsMap[partnerId] = subMerchants;
        }
        
        return subMerchants;
    }
}

export default PartnerService;
