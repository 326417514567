import {faF, faW} from "@fortawesome/free-solid-svg-icons";

class ProviderTypeModel {
    static providerMap = {
        '1': new ProviderTypeModel({ id: 1, name: 'FiServ', notes: '', color: 'rgba(255, 110, 110, 0.7)' }),
        '2': new ProviderTypeModel({ id: 2, name: 'WorldPay', notes: '', color: 'rgba(255, 210, 120, 0.8)' }),
        '3': new ProviderTypeModel({ id: 3, name: 'FluidPay', notes: '', color: 'rgba(210, 210, 255, 0.8)' }),
    };
    
    static defaultProvider = new ProviderTypeModel({ id: 0, name: 'Default Provider', notes: 'Will default to WorldPay if not specified', color: 'rgba(255, 255, 255, 0.7)' });
    
    static getProviderByType(type) { 
        if (!!type) return this.providerMap[type.toString()] || this.defaultProvider;
        return this.defaultProvider;
    }
    
    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.notes = json.notes;
        this.color = json.color || 'white';
    }
    
    getIcon() {
        return this.id === 1 ? faF : faW;
    }
}

export default ProviderTypeModel;
