import React, {useEffect, useState} from 'react';
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";
import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import {useParams} from "react-router-dom";

const PasswordResetScreen = (props) => {
    const { resetId } = useParams();

    let [creds, setCreds] = useState({password1: '', password2: ''});
    let [buttonCaption, setButtonCaption] = useState('Reset Account');
    let [errorMessage, setErrorMessage] = useState('');
    let [passwordSet, setPasswordSet] = useState({});
    let [isComplete, setIsComplete] = useState(false);
    
    const onChange = (fieldId, e) => {
        let c = { password1: creds.password1, password2: creds.password2 };
        c[fieldId] = e.target.value;
        
        setCreds(c);
    };
    
    useEffect(() => {
        AuthenticationService.instance.getAccountResetAsync(resetId).then((model) => {
            console.log(JSON.stringify(model));
            if (!model.is_active) setPasswordSet(model);
            else setErrorMessage('This account reset link has expired or otherwise no good.');
        }).catch((err) => {
            console.warn("Nope: " + err);
        });
        
    }, []);

    const onSubmitAsync = async (e) => {
        // Validate:
        if (creds.password1 !== creds.password2) {
            setErrorMessage('Passwords do not match.');
            return;
        }
        
        if (creds.password1.length < 8) {
            setErrorMessage('Password must be at least 8 characters long.');
            return;
        }
        
        setErrorMessage('');
        
        return await AuthenticationService.instance.updateAccountPasswordAsync(resetId, creds.password1).then((model) => {
            console.log('Password updated: ' + JSON.stringify(model));
            setIsComplete(true);
        }).catch((err) => {
            let msg = Array.isArray(err?.response?.data?.messages) ? err?.response?.data?.messages[0] : err;
            setErrorMessage(msg);

        });
    };

    let formButton = (passwordSet.id) ? (<FormButton onClick={onSubmitAsync}>{buttonCaption}</FormButton>) : (<></>);
    let errorBox = (errorMessage) ? (<div className={"error-box"}>{errorMessage}</div>) : (<></>);
    
    let body = (<>
            <h2>Activate Account</h2>
    
            <label>Enter an Account Password:</label>
            <div><input autoComplete="off" onChange={onChange.bind(this, 'password1')} type={"password"} /></div>
        
            <label>Re-Enter Account Password:</label>
            <div><input autoComplete="new-password" onChange={onChange.bind(this, 'password2')} type={"password"} /></div>
        
            {errorBox}
        
            <div className={"button"}>
                {formButton}
            </div>
        </>   
    );
    
    if (isComplete) { 
        body = (<>
            <h2>Account Update Complete</h2>
            <p className={"reset-good"}>
                Your account has been updated.<br/>
                <a href="/">You can now login</a>.
            </p>
        </>);
    }
    
    return (
        <div id="main">
            <div id="main-body-login">
                <div className="login-panel form">
                    { body }
                </div>
            </div>
        </div>
    );
    
};

export default PasswordResetScreen;
