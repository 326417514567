import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBarsProgress,
    faBoxesStacked,
    faDownload
} from "@fortawesome/free-solid-svg-icons";
import PlansMasterScreen from "./PlansMasterScreen";
import "./Plans.css";
import PartnerService from "../../components/partners/services/PartnerService";
import PlanService from "../../components/plans/services/PlanService";
import NumberDisplay from "@paylani/paylani-react-packages/dist/common/formatting/NumberDisplay";
import {Tooltip} from "@mui/material";
import {Link} from "react-router-dom";

const PlansScreen = (props) => {
    const [plans, setPlans] = useState(PartnerService.instance.plans);
    let _;
 
    const getPlansAsync = async (force) => {
        if (!force && !!plans && plans.length > 0) return plans;
        
        return await PlanService.instance.getPlansAsync().then((ps) => {
            if (!!ps) setPlans(ps);
            return ps;
        });
    }

    useEffect(() => {
        //
    }, [plans]);


    useEffect(() => {
        _ = getPlansAsync();
    }, []);
    
    const elements = plans?.map((p) => {
        let details = [];
        
        const minText = "Monthly Minimum";

        if (p.mrcTiers.length > 0) details.push((<Tooltip key={"mx-001"} title={"MRC Tiered Plan"} placement={"top"} arrow={true}><><FontAwesomeIcon className={"mrc-tiers"} icon={faBarsProgress} /></></Tooltip>));
        if (p.transactionTiers.length > 0) details.push((<Tooltip key={"mx-002"} title={"Update Tiered Plan"} placement={"top"} arrow={true}><><FontAwesomeIcon className={"transaction-tiers"} icon={faBarsProgress} /></></Tooltip>));

        if (p.merchantPooling) details.push((<Tooltip key={"mxx-003"} title={"Pooled Merchants"} placement={"top"} arrow={true}><><FontAwesomeIcon className={"pool"} icon={faBoxesStacked} /></></Tooltip>));
        if (p.minimumAmount > 0) details.push(<Tooltip key={"mx-004"} title={minText} placement={"top"} arrow={true}><><FontAwesomeIcon className={"minimum"} icon={faDownload} /></></Tooltip>);
        
        return (<tr key={"plan-tr-" + p.id}>
            <td><Link to={"/plans/" + p.id}>{p.name}</Link></td>
            <td><NumberDisplay value={p.mrc} type={"currency"} /></td>
            <td><NumberDisplay value={p.updateFee} type={"currency"} /></td>
            <td><NumberDisplay value={p.setupFee} type={"currency"} /></td>
            <td className={"plan-detail-icons"}>{ details }</td>
        </tr>);
    }) || null;
    
    return (<PlansMasterScreen title={"CardSync Plans"}>
        <p>Below are the available Plans for CardSync Partners.</p>

        <div id={"plans-main"}>
            <table width={"100%"} className={"table-x-large plans"}>
                <thead>
                <tr>
                    <th>Plan</th>
                    <th>Merchant Fee per mo</th>
                    <th>Update Fee</th>
                    <th>Setup Fee</th>
                    <th>Details</th>
                </tr>
                </thead>
                
                <tbody>
                    {elements}
                </tbody>
            </table>
        </div>

    </PlansMasterScreen>);
};


export default PlansScreen;

