class SupportAccessModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.merchantId = json.merchant_id;
        this.isBlocked = json.is_blocked;
        this.platform = json.platform || 0;
        this.accountName = json.account_name;
        this.sender = json.sender;
        this.notes = json.notes;
        
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            support_white_list_id: this.supportWhiteListId,
            merchant_id: this.merchantId,
            is_blocked: this.isBlocked,
            account_name: this.accountName,
            sender: this.sender,
            notes: this.notes,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        return jsonArray.map(json => new SupportAccessModel(json));
    }
}

export default SupportAccessModel;
