import React, {createRef, useState} from 'react';
import './ToolTip.css';

const ToolTip = (props) => {
    let { value, width, offset } = props;
    let tipContent = createRef();

    if (typeof offset === 'object' || Array.isArray(offset)) {
        let y = (offset.y || offset.vertical) || 0;
        let x = (offset.x || offset.horizontal) || 0;

        offset = { x: x, y: y };
    } else {
        offset = { x: 0, y: 0 };
    }

    let [showClassName, setShowClassName] = useState('');
    let [absoluteStyle, setAbsoluteStyle] = useState({});
    
    //let [isAnchored, setIsAnchored] = useState(false);
    const onMouseOver = (e) => {
        let rect = tipContent.current.getBoundingClientRect();
        let c = 'hide';
        let overflow = 0;
        
        if (rect.width > 0) {
            c = 'show';
            let bubbleWidth = tipContent.current.offsetWidth;
            let right = bubbleWidth + rect.x;
            overflow = document.body.clientWidth - right;
            
            if (overflow < 0) {
                setAbsoluteStyle({left: Math.floor(overflow) + 'px'});
            }
        }
        
        setShowClassName(' ' + c);
    };

    const onMouseOut = (e) => {
        //if (isAnchored) return;
        setShowClassName('');
        setAbsoluteStyle({});
    };

    let style = {};
    
    if (typeof width === 'number' && width > 0) {
        style.width = width.toString() + 'px';
    } else {
        style.whiteSpace = 'nowrap';
    }
    
    return (<span className="tool-tipper" onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
        <span className="tool-tipper-controller">{props.children}</span>
        <span ref={tipContent} className={'tool-tip' + showClassName} style={absoluteStyle}>
            <span className="tool-tip-body" style={style}>{value}</span>
            <span className="tool-tip-caret"><span className="tool-tip-caret-tri"></span></span>
        </span>
    </span>);
};

export default ToolTip;
