import React, {useState, useRef, useEffect} from 'react';
import {useParams} from "react-router-dom";
import MasterScreenMain from "../../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import PersonForm from "@paylani/paylani-react-packages/dist/people/ui/PersonForm";
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";
import AddressForm from "@paylani/paylani-react-packages/dist/geo/ui/AddressForm";
import CreditCardForm from "@paylani/paylani-react-packages/dist/payments/ui/CreditCardForm";
import AchForm from "@paylani/paylani-react-packages/dist/payments/ui/AchForm";

import {faAddressCard, faBuildingColumns, faCreditCard} from "@fortawesome/free-solid-svg-icons";
import PartnerSubMenu from "../../../components/partners/ui/PartnerSubMenu";
import PartnerService from "../../../components/partners/services/PartnerService";
import PlanService from "../../../components/plans/services/PlanService";
import Controller from "@paylani/paylani-react-packages/dist/common/controllers/Controller";

const PartnerBillingDetailsScreen = (props) => {
    const { partnerId } = useParams();

    let _;
    
    const [billingData, setBillingData] = useState({ partner: null, customerJson: null, addressJson: null, creditCardJson: null, bankAccountJson: null, planId: null });
    const [paymentState, setPaymentState] = useState(-1);
    const [errors, setErrors] = useState({});
    const [plans, setPlans] = useState(PlanService.instance.plans);
    const controller = useState(new Controller())[0];
    
    const partner = billingData.partner;
    const planId = billingData?.planId;
    
    const planRef = useRef();
    
    const handlePageError = (error) => {
        //console.error('Handled Error: ' + error);
    };

    const onFormChange = (formKey, data) => {
        let jsonBillingData = null;
        
        switch (formKey) { 
            case "customer":
                jsonBillingData = {...billingData.customerJson};
                jsonBillingData.customerJson = {...data};
                break;
        }
    }
    
    const onSubmitAsync = async (e) => {
        controller.clearErrors();
        
        const _addressJson = controller.getFormData("address");
        const _customerJson = controller.getFormData("person");
        const _creditCardJson = (paymentState === 0) ? controller.getFormData("credit_card") : null;
        const _achJson = (paymentState === 1) ? controller.getFormData("ach") : null;
        const _planId = planRef.current?.value || null;

        let errs = controller.errors || {};
        
        if (typeof _planId !== "string" || _planId.length !== 36) 
            errs.planId = "Please select a plan.";
        
        if (Object.keys(errs).length > 0) {
            setErrors(errs);
            return;
        }
        
        const c = billingData.customerJson;
        const customerData = {...c};
        const customerId = customerData.id;
        
        delete customerData.image_url;
        delete customerData.user_type;
        delete customerData.user_name;
        delete customerData.id;

        customerData.address = {...billingData.addressJson};

        if (paymentState === 0) {
            customerData.credit_card = {...billingData.creditCardJson};
        } else {
            customerData.bank_account = {...billingData.bankAccountJson};
            customerData.bank_account_id = partner.customer?.bankAccountId;
        }

        if (!!customerData.bank_account) { 
            if (typeof customerData.bank_account.account_type !== "number") customerData.bank_account.account_type = parseInt(customerData.bank_account.account_type);
            if (typeof customerData.bank_account.account_holder_type !== "number") customerData.bank_account.account_holder_type = parseInt(customerData.bank_account.account_holder_type);
        }
        
        if (!!_addressJson?.street) {
            if (!customerData.address) customerData.address = {};
            customerData.address.street = _addressJson.street;
            customerData.address.street2 = _addressJson.street2 || null;
            customerData.address.city = _addressJson.city || null;
            customerData.address.state = _addressJson.state || null;
            customerData.address.zip = _addressJson.zip || null;
        }
        
        customerData.first_name = _customerJson.first_name || null;
        customerData.last_name = _customerJson.last_name || null;
        customerData.email = _customerJson.email || null;
        customerData.phone = _customerJson.phone || null;
        customerData.phone_ext = _customerJson.phone_ext || null;
        
        customerData.credit_card = _creditCardJson || null;
        customerData.bank_account = _achJson || null;
        customerData.plan_id = _planId;

        console.warn("Actual Data");
        console.warn(JSON.stringify(customerData, null, 4));
        
        await PartnerService.instance.updateBillingCustomerAsync(partnerId, customerData).then((c) => {
            onFormChange("customer", c);
        });
    };

    const getPartnerAsync = async (force = false) => {
        if (!!partner) return partner;
        
        const p = await PartnerService.instance.getPartnerAsync(partnerId).catch((error) => console.log('Ex: ' + error));
        
        const data = {
            customerJson: p.customer?.toJson() || null,
            addressJson: p.customer?.address?.toJson() || null,
            creditCardJson: p.creditCard?.toJson() || {},
            bankAccountJson: p.customer?.bankAccount?.toJson() || null,
            planId: p.planId || "0",
            partner: p,
        }

        if (!data.planId) console.warn("No PlanId: " + data.planId);
        
        setBillingData(data);
    }
    
    const getPlansAsync = async (force) => {
        if (plans?.length > 0 && !force) return plans;
        
        console.log("Getting plans...");
        
        const ps = await PlanService.instance.getPlansAsync().catch((ex) => handlePageError(ex));
        
        if (!!ps) { 
            console.log("Plans good.");
            setPlans(ps);
        } else { 
            console.warn("Failed to get plans: " + ps);
        }
        
        return ps || [];
    }
   
    useEffect(() => {
        if (!billingData) return;
        
        if (paymentState < 0 && typeof billingData.partner?.customer?.bankAccountId !== "undefined") {
            const st = !!billingData.partner?.customer?.bankAccountId ? 1 : 0;
            setPaymentState(st);
        }

    }, [billingData]);

    useEffect(() => {
       _ = getPartnerAsync();
       _ = getPlansAsync();
    }, []);
    
    let customerJson = billingData?.customerJson || null;
    let addressJson = billingData?.addressJson || null;
    let creditCardJson = billingData?.creditCardJson || null;
    let bankAccountJson = billingData?.bankAccountJson || null;
    
    const plansDropdown = plans?.map((plan, index) => {
        let minValue = (<></>);
        
        if (plan.minimumAmount > 0) minValue = (<>&nbsp;&nbsp; | &nbsp;&nbsp; Min: {plan.minimumAmount.formatCurrency()} </>);
        
        return (
            <option value={plan.id} key={"plan-item-" + index}>
                {plan.name} &nbsp;&nbsp; | &nbsp;&nbsp;
                MRC: {plan.mrc.formatCurrency(2)} &nbsp;&nbsp; | &nbsp;&nbsp;
                Update Fee: {plan.updateFee.formatCurrency()}
                {minValue}
            </option>
        );
    }) || [];

    let customerForm = null;
    let paymentJson = null;
    let selectedPaymentForm = null;
    let addressForm = null;
    let partnerSubmenu = null;
    
    const paymentTitle = paymentState === 0 ? "Credit Card" : "ACH";
    
    if (!!partner?.id) {
        partnerSubmenu = (<PartnerSubMenu partner={partner} selection="billing" />);
        customerForm = customerJson !== null ? (<PersonForm controller={controller} value={customerJson} onChange={(c) => onFormChange("customer", c)} />) : null;
        paymentJson = bankAccountJson || (creditCardJson || null); // Must be in this conditional order
        selectedPaymentForm = paymentState === 0 ?
            (<CreditCardForm controller={controller} isDebug={true} value={creditCardJson} onChange={(json) => onFormChange("credit_card", json)} />) :
            (<AchForm controller={controller} isDebug={true} value={bankAccountJson} onChange={(json) => onFormChange("ach", json)} />);

        addressForm = (<AddressForm controller={controller} value={partner.customer?.address?.toJson()} onChange={(json) => onFormChange("address", json)} />);
    }

    const paymentClass = {};
    paymentClass[paymentState.toString()] = "selected";
    
    const plansElement = plans?.length > 0 && billingData?.planId ? (<select id="plan" ref={planRef} defaultValue={planId} onChange={(p) => onFormChange("plan", p)}>
        <option>Select a plan</option>
        { plansDropdown }
    </select>) : null;
    
    return (
        <MasterScreenMain selection="partners">
            <div className="pad">
            <h1>
                <FontAwesomeIcon icon={faAddressCard} />
                {partner?.name}
            </h1>

            <div className="details-body large multi-form">
                {partnerSubmenu}

                <div className="pad form">
                    <h2>Billing Details</h2>
                    <p>
                        Information below is used to charge the {partner?.name} credit card or bank account (ACH).
                    </p>

                    <div className="form-group">
                        <label>Billing Plan</label>
                        { plansElement }
                        <div className={"form-error"}>{ errors.planId }</div>
                    </div>
                    
                    <h4>Billing Contact</h4>
                    {customerForm}
                    
                    <h4 className={"menu"} style={{width: "640px"}}>
                        <span>{paymentTitle} Payment Details</span>
                        <span className={"menu"}>
                            <a className={paymentClass["0"]} onClick={(e) => setPaymentState(0)}><FontAwesomeIcon icon={faCreditCard} /> Credit Card</a>
                            <a className={paymentClass["1"]} onClick={(e) => setPaymentState(1)}><FontAwesomeIcon icon={faBuildingColumns} /> ACH / eCheck</a>
                        </span>
                    </h4>
                    
                    {selectedPaymentForm}

                    <h4>Billing Address</h4>
                    {addressForm}

                    <div className="button">
                        <FormButton id="update-partner-button" onClick={onSubmitAsync} label="Update Billing Details" />
                    </div>
                    
                </div>
            </div>
        </div>
                
        </MasterScreenMain>
    );
};

export default PartnerBillingDetailsScreen;