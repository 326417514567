import React, {useState, useEffect, useRef} from 'react';
import {useParams} from "react-router-dom";
import PartnerModel from "../../components/partners/models/PartnerModel";
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard, faEdit} from "@fortawesome/free-solid-svg-icons";
import PartnerSubMenu from "../../components/partners/ui/PartnerSubMenu";
import PartnerForm from "../../components/partners/ui/PartnerForm";
import PartnerService from "../../components/partners/services/PartnerService";
import MerchantSubMenu from "../../components/partners/ui/MerchantSubMenu";
import PartnerWebhooksSubMenu from "../../components/partners/ui/PartnerWebhooksSubMenu";
import WebhookService from "../../components/webhooks/services/WebhookService";

const PartnerWebhooksScreen = (props) => {
    const { partnerId } = useParams();
    let [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId] || new PartnerModel({}));
    let [partnerWebhooks, setPartnerWebhooks] = useState([]);
    let [webhookEditor, setWebhookEditor] = useState(null);
    
    const getPartnerWebhooks = () => {
        PartnerService.instance.getWebhooksAsync(partnerId).then((partnerWebhooks) => {
            console.log('Got Webhooks: ' + partnerWebhooks.length);
            setPartnerWebhooks(partnerWebhooks);
        });
    };
    
    useEffect(() => {
        if (!partner.hasDetails) PartnerService.instance.getPartnerAsync(partnerId)
            .then((partner) => {
                console.log('Got Partner: ' + partner.customer.toJson().first_name);
                setPartner(partner);
            })
            .catch((error) => console.log('Ex: ' + error));
        
        getPartnerWebhooks();
    }, []);

    console.log('PartnerId: ' + partnerId);
    let website = partner.website || '';
    let empty = (<i>Not Entered</i>);

    const createPayload = (partnerWebhook, url) => {
        console.log('Click');
        
        if (typeof url === 'undefined') url = partnerWebhook.url;
        
        return {
            webhookId: partnerWebhook.webhookId,
            url: url
        };
    };
    
    const showEditForm = (partnerWebhook, e) => {
        let editor = createPayload(partnerWebhook);
        setWebhookEditor(editor);
    };
    
    const cancelEditor = (e) => {
        setWebhookEditor(null);
    };

    const onWebhookUrlChange = (partnerWebhook, e) => {
        console.log('onWebhookUrlChange e.target.value: ' + e.target.value);
        let editor = createPayload(partnerWebhook, e.target.value);
        setWebhookEditor(editor);
    };

    const updateWebhookUrl = (e) => {
        let webhookId = webhookEditor.webhookId + '';
        let url = (webhookEditor?.url || '') + '';

        cancelEditor(e);

        PartnerService.instance.updateWebhookUrlAsync(url, webhookId, partnerId).then((model) => {
            let index = partnerWebhooks.findIndex((p) => p.webhookId === webhookId);
            getPartnerWebhooks();
            partnerWebhooks[index].url = model.url;
            setPartnerWebhooks(partnerWebhooks);
        });
    };

    let webhookElements = partnerWebhooks.map((w) => {
        return (
            <div key={w.webhook.id} className={"webhook-item" + (webhookEditor?.webhookId === w.webhookId ? " show" : "")}>
                <span className={"hover-edit float-right padded"}>
                    <a onClick={showEditForm.bind(this, w)}><FontAwesomeIcon icon={faEdit} /> Edit</a>
                </span>
                <div className={"webhook-item-display"}>
                    <h4>{w.webhook.name}</h4>
                    <p>{w.url || empty }</p>
                </div>
                <div className={"webhook-url"}>
                    <label htmlFor={"webhook-url-" + w.id}>Webhook Post-to Url: </label>
                    <div className="webhook-url-inputs">
                        <input id={"webhook-url-" + w.id} value={webhookEditor === null ? w.url : webhookEditor?.url} onChange={onWebhookUrlChange.bind(this, w)} type={"text"} />
                        <button onClick={updateWebhookUrl}>Update</button>
                        <a onClick={cancelEditor}>Cancel</a>
                    </div>
                </div>
            </div>
        );
    });
    
    console.log('Re-Render');
    
    return (
        <MasterScreenMain selection="partners">
            <div className="pad">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    {partner.name}
                </h1>

                <div className="details-body medium form">
                    <PartnerSubMenu partner={partner} selection="webhooks" />

                    <div className="pad">
                        <h2>Webhooks</h2>
                        <PartnerWebhooksSubMenu partner={partner} selection="" />
                        <p>
                            Webhooks are posted from the following triggers:
                        </p>

                        {webhookElements}
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
};

export default PartnerWebhooksScreen;
