import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import PartnerService from "../../components/partners/services/PartnerService";
import PartnerModel from "../../components/partners/models/PartnerModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard, faCheckDouble, faFaceMeh, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import MasterScreenMain from "../../MasterScreenMain";
import PartnerSubMenu from "../../components/partners/ui/PartnerSubMenu";
import PartnerOverviewSubMenu from "../../components/partners/ui/PartnerOverviewSubMenu";
import DateRangeSelector from "@paylani/paylani-react-packages/dist/common/ui/date-range-selector/DateRangeSelector";
import ReportItemModel from "../../components/reporting/models/ReportItemModel";
import CardBrandLineGraph from "../../components/reporting/ui/CardBrandLineGraph";
import CardBrandTrendSeriesModel from "../../components/reporting/models/CardBrandTrendSeriesModel";
import ReportingService from "../../components/reporting/services/ReportingService";
import CardBrandPieChart from "../../components/reporting/ui/CardBrandPieChart";
import CreditCardModel from "../../components/payments/models/CreditCardModel";
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";

const PartnerCardBrandReportingScreen = (props) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        ArcElement
    );

    const { partnerId } = useParams();
    let [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId] || new PartnerModel({}));

    let ed = new Date().addDays(1);
    let sd = ed.toFirstOfTheMonth().addMonths(-1);

    const initDate = ReportItemModel.createDateRange(sd, ed);
    let [dateRange, setDateRange] = useState(initDate);

    const [statementOpen, setStatementOpen] = useState(false);
    const [cardBrandResultsTrendData, setCardBrandResultsTrendData] = useState(null);
    
    const countsKey = "partner-brand-counts";
    const updatesKey = "partner-brand-updates";
    
    let [cardBrandResultTypeCountData, setCardBrandResultTypeCountData] = useState(CardBrandLineGraph.createEmptyData());
    let _;
    
    const getCachedData = (key, sd, ed) => {
        let dr = (!!sd && !!ed) ? ReportItemModel.createDateRange(sd, ed) : dateRange;
        let cacheKey = ReportItemModel.getKeyFromDate(key + '-' + partnerId, dr.startDate, dr.endDate);

        return { dr: dr, data: PartnerService.instance.reportMap[cacheKey], cacheKey: cacheKey };
    }

    const refreshData = (force, sd, ed) => {
        _ = getCardBrandResultTypeCountsDataAsync(force, sd, ed);
        _ = getCardBrandUpdatesDataAsync(force, sd, ed);
    };

    const onDateRangeChange = (sd, ed) => {
        let dr = ReportItemModel.createDateRange(sd, ed);
        setDateRange(dr);

        refreshData(true, sd, ed);
    };

    const getCardBrandResultTypeCountsDataAsync = async (force, sd, ed) => {
        let { data, dr, cacheKey } = getCachedData(countsKey, sd, ed);

        if (!!data && !force) {
            setCardBrandResultTypeCountData(data);
            return;
        }

        return await PartnerService.instance.getCardBrandResultTypeCountsAsync(partnerId, dr.startDate, dr.endDate, cacheKey).then((data) => {
            setCardBrandResultTypeCountData(data);
        });
    };

    const refreshCardBrandResultCountsAsync = async (e) => { 
        return await getCardBrandResultTypeCountsDataAsync(true);
    };
    
    const getCardBrandUpdatesDataAsync = async (force, sd, ed) => {
        let { data, dr, cacheKey } = getCachedData(updatesKey, sd, ed);

        if (!!data && !force) {
            setCardBrandResultsTrendData(data.toLineChartDataSet());
            return;
        }

        console.log('Getting Updates Data');
        await PartnerService.instance.getDailyCardBrandUpdatesAsync(partnerId, dr.startDate, dr.endDate, cacheKey).then((data) => {
            console.log('Got Updates Data');
            setCardBrandResultsTrendData(data.toLineChartDataSet());
        });
    };

    useEffect(() => {
        if (!partner.hasDetails) PartnerService.instance.getPartnerAsync(partnerId)
            .then((partner) => {
                console.log('Got Partner: ' + partner.customer.toJson().first_name);
                setPartner(partner);
            })
            .catch((error) => console.log('Ex: ' + error));
        refreshData(true);

    }, []);

    const createCardBrandResultTypesTableRow = (key, name) => {
        let item = cardBrandResultTypeCountData[key];

        if (!item) return (<></>);

        return (<tr key={key}>
            <td>{name}</td>
            <td>{(item["cardnumberupdated"] || 0).formatNumber(0)}</td>
            <td>{(item["cardexpupdated"] || 0).formatNumber(0)}</td>
            <td>{(item["contact"] || 0).formatNumber(0)}</td>
            <td>{(item["closedcontact"] || 0).formatNumber(0)}</td>
            <td>{(item["total"] || 0).formatNumber(0)}</td>
        </tr>);
    };

    let totalMap = {};

    let cardBrandTable = CreditCardModel.cardKeys.map((key) => {
        let card = CreditCardModel.cardDescriptors[key];
        totalMap[key] = cardBrandResultTypeCountData[key]["total"] || 0;

        return createCardBrandResultTypesTableRow(key, card.name);
    });

    let totalsRow = createCardBrandResultTypesTableRow('totals', 'Total');

    const onLineHover = (event) => {
        let e = false;
        let c = event.target.attributes;

        return event;
    };
    
    return (
        <MasterScreenMain selection="partners">
            <div className="pad full-screen-dialog">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    {partner.name}
                </h1>

                <div className="details-body body">
                    <PartnerSubMenu partner={partner} selection="overview" />

                    <div className="pad form">
                        <h2>Card Brand Activity <span className={"subtitle"}>All {partner.name} Submerchants</span></h2>
                        <PartnerOverviewSubMenu partner={partner} selection={"cardbrands"} />
                        
                        <p>
                        </p>

                        <DateRangeSelector startDate={dateRange.startDate} endDate={dateRange.endDate} onRefresh={onDateRangeChange} />
                        <div id="line-pie-graph-container">
                            <div id="pie-graph-container"><CardBrandPieChart title="Updates" visa={totalMap['visa']} masterCard={totalMap['mastercard']} amex={totalMap['amex']} discover={totalMap['discover']} width={240} height={240} /></div>
                            <div id="line-graph-container"><CardBrandLineGraph subtitle={"Updates by Card Brand: " + dateRange.title} data={cardBrandResultsTrendData} /></div>
                        </div>

                        <h3 className={"subtitle"}>
                            Card Brand Update Types: { dateRange.title }
                            <RefreshButton onClick={refreshCardBrandResultCountsAsync.bind(this)} />
                        </h3>
                        
                        <table className="table-x-large" width="100%">
                            <thead>
                            <tr>
                                <th>Card Brand</th>
                                <th>Card Number</th>
                                <th>Expiration</th>
                                <th>Contact</th>
                                <th>Closed</th>
                                <th>Total</th>
                            </tr>
                            </thead>

                            <tbody>
                            {cardBrandTable}
                            </tbody>

                            <tfoot className="total">
                            {totalsRow}
                            </tfoot>
                        </table>                        
                    </div>
                </div>
            </div>

        </MasterScreenMain>
    );

};

export default PartnerCardBrandReportingScreen;

