class BankAccountModel {
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        this.merchantId = json.merchant_id;
        this.name = json.name || null;
        this.accountNumber = json.account_number;
        this.routingNumber = json.routing_number;
        this.directDepositRoutingNumber = json.direct_deposit_routing_number;
        this.identifier = json.identifier;
        this.bankAccountType = json.bank_account_type;
        this.bankAccountTypeName = json.bank_account_type_name;
        this.secCode = json.sec_code || "web";
        this.accountHolderType = json.account_holder_type;
        this.accountHolderTypeName = json.account_holder_type_name;
        this.status = json.status;
        
        this.created = json.created ? new Date(json.created) : null;
        this.updated = json.updated ? new Date(json.updated) : null;
    }
    
    toJson = () => {
        return { 
            //id: this.id,
            name: this.name,
            account_number: this.accountNumber,
            routing_number: this.routingNumber,
            //direct_deposit_routing_number: this.directDepositRoutingNumber,
            bank_account_type: this.bankAccountType,
            sec_code: this.secCode,
            account_holder_type: this.accountHolderType,
        };
    };
}

export default BankAccountModel;
