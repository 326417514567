import React, {useState, useEffect} from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp, faGears} from "@fortawesome/free-solid-svg-icons";
import ReportingSubMenu from "../../components/reporting/ui/ReportingSubMenu";
import '../../components/reporting/ui/Reporting.css';
import {Doughnut, Pie} from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend, 
    ArcElement
} from 'chart.js';

import CardBrandLineGraph from "../../components/reporting/ui/CardBrandLineGraph";
import CardBrandPieChart from "../../components/reporting/ui/CardBrandPieChart";
import ReportingService from "../../components/reporting/services/ReportingService";
import {Link} from "react-router-dom";
import NumberDisplay from "@paylani/paylani-react-packages/dist/common/formatting/NumberDisplay";
import ReportItemModel from "../../components/reporting/models/ReportItemModel";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";

const ReportingScreen = (props) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        ArcElement
    );

    const defaultLineGraphTitle = 'Submissions by Card Brand';
    
    let stateTitles = [
        defaultLineGraphTitle,
        'Updates by Card Brand'
    ];

    const submissionsReportKey = 'master-submissions';
    const updatesReportKey = 'master-updates';
    const cardBrandDataReportKey = 'cardbrand-data';
    
    const [chartState, setChartState] = useState({title: defaultLineGraphTitle, state: 0});
    const [cardBrandTrendData, setCardBrandTrendData] = useState(null);

    let [cardBrandData, setCardBrandData] = useState(CardBrandLineGraph.createEmptyData());
    let [snapshotDuration, setSnapshotDuration] = useState(14);

    let startDate = new Date().addDays(-snapshotDuration);
    
    const setLineChartData = (data, isValid) => {
        if (!isValid) return;
        setCardBrandTrendData(data);
    };
    
    const getSubmissionDataAsync = async (force) => {
        let cacheKey = ReportItemModel.getKeyFromDate(submissionsReportKey, startDate);
        let submissionData = ReportingService.instance.reportMap[submissionsReportKey];
        let isValid = chartState.state === 0;
        
        console.log('Getting Submission Data CacheKey: ' + cacheKey);

        if (!!submissionData && submissionData.hasStartDate(startDate) && !force) {
            console.warn('Set Cached Submission Data: ' + isValid + ': ' + submissionData.startDate + ' - ' + submissionData.endDate);
            setLineChartData(submissionData, isValid);
            return;
        }

        console.log('Getting Submission Data from server');
        return await ReportingService.instance.getMasterCardBrandSubmissionsAsync(startDate, null, submissionsReportKey).then((model) => {
            console.log('Got Submission Data: ' + isValid);
            setLineChartData(model, isValid);
        });
    };

    const getUpdatesDataAsync = async (force) => {
        let cacheKey = ReportItemModel.getKeyFromDate(updatesReportKey, startDate);
        let updateData = ReportingService.instance.reportMap[updatesReportKey];
        let isValid = chartState.state === 1;

        if (!!updateData && updateData.hasStartDate(startDate) && !force) {
            console.warn('Set Cached Updates Data: ' + isValid + ': ' + updateData.startDate + ' - ' + updateData.endDate);
            setLineChartData(updateData, isValid);
            return;
        }

        return await ReportingService.instance.getMasterCardBrandUpdatesAsync(startDate, null, updatesReportKey).then((model) => {
            console.log('Got Updates Data: ' + isValid);
            setLineChartData(model, isValid);
        });
    };

    const getCardBrandDataAsync = async (force) => {
        let cacheKey = ReportItemModel.getKeyFromDate(cardBrandDataReportKey, startDate);
        let cardData = ReportingService.instance.reportMap[cardBrandDataReportKey];
        
        if (!!cardData) {
            console.log('Set Cached Card Brand Data')
            setCardBrandData(cardData);
            return;
        }
        
        return await ReportingService.instance.getMasterCardBrandCountsAsync(startDate, null).then((json) => {
            setCardBrandData(json);
        });
    };

    let _;
    const getSnapShotData = (force) => {
        _ = getSubmissionDataAsync(force);
        _ = getUpdatesDataAsync(force);
        _ = getCardBrandDataAsync(force);
    };

    const getSnapShotDataAsync = async (force) => {
        return Promise.all([
            getSubmissionDataAsync(force),
            getUpdatesDataAsync(force),
            getCardBrandDataAsync(force)
        ]);
    };
    
    let controlSelections = ['', ''];
    controlSelections[chartState.state] = 'selected';

    let controls = (<span id="home-line-controls">
        <a className={controlSelections[0]} onClick={(e) => setLineGraphState(0)}>Submissions</a>
        <a className={controlSelections[1]} onClick={(e) => setLineGraphState(1)}>Updates</a>
    </span>)
    
    useEffect(() => {
        getSnapShotData(false);
    }, [snapshotDuration]);

    useEffect(() => {
        if (chartState.state === 1) _ = getUpdatesDataAsync();
        else _ = getSubmissionDataAsync();
        
    }, [chartState, snapshotDuration]);
    
    const toggleState = (e) => {
        let newState = { title: defaultLineGraphTitle, state: 0 };
        if (chartState.state === 0) {
            newState.state = 1;
        }

        newState.title = stateTitles[newState.state];
        setChartState(newState);
    };

    const setLineGraphState = (state) => {
        if (state === chartState.state) {
            console.log('Same state. Exiting...');
            return;
        }

        let newState = { title: stateTitles[state], state: state };
        setChartState(newState);
    };

    const getCardBrandRow = (item, name) => {
        return (<tr>
            <td>{name}</td>
            <td>{item.submissions.formatNumber(0)}</td>
            <td>{item.updates.formatNumber(0)}</td>
        </tr>);
    };
    
    let cardBrandTable = (<>
        { getCardBrandRow(cardBrandData.visa, 'VISA') }
        { getCardBrandRow(cardBrandData.mastercard, 'MasterCard') }
        { getCardBrandRow(cardBrandData.discover, 'Discover') }
        { getCardBrandRow(cardBrandData.amex, 'American Express') }
    </>);
    
    const onLineHover = (event) => {
        let e = false;
        let c = event.target.attributes;

        return event;
    };

    const onSnapShotDateChange = (event) => {
        let value = parseInt(event.target.value);
        if (isNaN(value)) return;

        setSnapshotDuration(value);
    };
    
    let pieKey = chartState.state === 1 ? 'updates' : 'submissions';
    let updateRate = (typeof cardBrandData.totals?.submissions === 'number') ? (cardBrandData.totals.updates / cardBrandData.totals.submissions) : 0.0;

    let s = snapshotDuration === 1 ? "" : "s";
    let pastDisplay = (<>past {snapshotDuration} day{s}</>);
    
    return (
        <MasterScreenMain selection="reporting">
            <div id="reporting-main" className="pad">
                <h1>
                    <FontAwesomeIcon icon={faArrowTrendUp} />
                    Reporting
                </h1>

                <div className="details-body">
                    <ReportingSubMenu selection="overview" />

                    <div className="pad x-large">
                        <h2>
                            Snapshot
                            <RefreshButton onClick={getSnapShotDataAsync.bind(this,true)} />
                        </h2>

                        <div className={"flex"}>
                            <p>
                                Reporting Snapshot displays information based on the <strong>{pastDisplay}</strong>.
                                See the reconciliation view in the <Link to="/reporting/card-brands">Card Brands section</Link>.
                            </p>
    
                            <div className="right form horizontal">
                                <span className="label-small medium">
                                    Show Data for:
                                </span>
                                <select value={snapshotDuration} onChange={onSnapShotDateChange.bind(this)} className="shrinkwrap trend">
                                    <option value="3">3 Days</option>
                                    <option value="7">1 Week</option>
                                    <option value="14">2 Weeks</option>
                                    <option value="30">Month</option>
                                </select>
                            </div>
                        </div>
                        
                        <div id="reporting-snapshot">
                            <div id="snapshot-list">
                                <table className="table-small" id="card-brand-list">
                                    <thead>
                                    <tr>
                                        <th>Card Brand</th>
                                        <th>Submission</th>
                                        <th>Updates</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {cardBrandTable}
                                    </tbody>
                                </table>
                            </div>

                            <div className="pies">
                                <CardBrandPieChart title={chartState.title}
                                   visa={cardBrandData?.visa[pieKey] || 0}
                                   masterCard={cardBrandData?.mastercard[pieKey] || 0} 
                                   discover={cardBrandData?.discover[pieKey] || 0}
                                   amex={cardBrandData?.amex[pieKey] || 0}
                                   width={240} height={240} 
                                />
                                <div className="dark round24">
                                    <div className="panel" style={{width: '240px', height: '240px'}} id="snap-shot-percent">
                                        <div>Update Rate</div>
                                        <h1><NumberDisplay value={updateRate} isComponent={true} type={"percent"} decimalPlaces={1} /></h1>
                                        <div className="small">Submissions resulting in billable updates in the { pastDisplay }</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <CardBrandLineGraph title={chartState.title} data={cardBrandTrendData?.toLineChartDataSet()} chartControls={controls} />
                    </div>
                </div>
            </div>
            
        </MasterScreenMain>
    );
};

export default ReportingScreen;
