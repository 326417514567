import React, { useState, useEffect, useRef } from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import "../../components/partners/ui/Partners.css";

import {faAddressCard} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useParams} from "react-router-dom";

import PartnerModel from "../../components/partners/models/PartnerModel";
import PartnerService from "../../components/partners/services/PartnerService";

import PartnerSubMenu from "../../components/partners/ui/PartnerSubMenu";
import BarGraph from "../../components/analytics/ui/BarGraph";
import BatchSubmissionActivity from "../../components/partners/ui/activity/BatchSubmissionActivity";
import VisaCard from "../../components/payments/ui/CreditCards/VisaCard";
import MasterCard from "../../components/payments/ui/CreditCards/MasterCard";
import DiscoverCard from "../../components/payments/ui/CreditCards/DiscoverCard";
import AmericanExpressCard from "../../components/payments/ui/CreditCards/AmericanExpressCard";
import BatchResultsActivity from "../../components/partners/ui/activity/BatchResultsActivity";
import BatchService from "../../components/batches/services/BatchService";
import ReportingService from "../../components/reporting/services/ReportingService";
import EmptyBox from "../../components/common/ui/EmptyBox";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";
import PartnerOverviewSubMenu from "../../components/partners/ui/PartnerOverviewSubMenu";
import PagerController from "../../components/common/ui/pager/PagerController";
import Pager from "../../components/common/ui/pager/Pager";

const PartnerDetailsScreen = (props) => {
    const { partnerId } = useParams();
    
    const [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId] || new PartnerModel({}));
    const [batchActivity, setBatchActivity] = useState(PartnerService.instance.batchActivity[partnerId] || null);
    const [cardBrandSeriesJson, setCardBrandSeriesJson] = useState([]);

    const [startDate, setStartDate] = useState(new Date().addDays(-7));
    let _;

    const [currentPage, setCurrentPage] = useState(0);
    const pageController = useState(new PagerController(setCurrentPage, 12))[0];

    const refreshBatchActivityAsync = async () => {
        return await PartnerService.instance.getBatchActivityAsync(partnerId, startDate).then((batchActivity) => {
            console.log('Got batch activity: ' + batchActivity.length);
            setBatchActivity(batchActivity);
        });
    };

    const getBatchActivityAsync = async () => {
        return await refreshBatchActivityAsync();
    };

    const getSubmissionReportsAsync = async () => {
        await ReportingService.instance.getCardBrandSubmissionReportAsync(partnerId).then((json) => {
            setCardBrandSeriesJson(json);
        });
    };
    
    const getPartner = () => {
        PartnerService.instance.getPartnerAsync(partnerId)
            .then((partner) => {
                console.log('Got Partner: ' + partner.customer.toJson().first_name);
                setPartner(partner);
            });        
    };
    
    useEffect(() => {
        if (!partner.hasDetails) getPartner();
        _ = getBatchActivityAsync();
        _ = getSubmissionReportsAsync();
    }, []);

    const updateBatchCounts = (batchId) => {
        if (batchActivity === null) return;
        console.log('Updating batch counts: ' + batchId);
        BatchService.instance.updateBatchCountsAsync(batchId).then((batch) => {
            const index = batchActivity.findIndex((b) => b.batchId === batch.batchId);
            
            if (index >= 0) {
                console.log('Refresh activity record at ' + index.toString());
                batchActivity[index] = batchActivity[index].setCounts(batch);
                setBatchActivity(batchActivity);
                
                _ = getBatchActivityAsync();
            } else {
                console.log('Failed to find activity record for batchId: ' + batchId.toString());
            }
        });
    };

    const amexData = cardBrandSeriesJson.amex;
    const discoverData = cardBrandSeriesJson.discover; // toMockGraphValues(createMockData(6));
    const visaData = cardBrandSeriesJson.visa;
    const masterCardData = cardBrandSeriesJson.mastercard;

    let formatAxisLabel = null;
    let barGraphSize = "";
    
    let noAmex = null;
    
    if (cardBrandSeriesJson.amex && cardBrandSeriesJson.amex.length > 0) { 
        barGraphSize = "small";
        formatAxisLabel = (item) => {
            return item.x_axis_date.formatDate('MONTH-DAY');
        };
    } else noAmex = (<div className={"no-amex"}><Link to={"/partners/" + partnerId + "/american-express"}>No Recent American Express Submissions</Link></div>);
    
    let batchElements = pageController.mapLineItems(batchActivity || [], (batch, index) => {
        if (batch.batchStatus === 2) return (<BatchResultsActivity onClick={() => updateBatchCounts(batch.batchId)} partner={partner} key={index} batch={batch} />);
        return (<BatchSubmissionActivity onClick={() => updateBatchCounts(batch.batchId)} partner={partner} key={index} batch={batch} />);
    });
    
    if ((batchActivity || []).length === 0) {
        let pastDuration = Math.round((new Date().getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
        let s = (pastDuration === 1) ? "" : "s";
        batchElements = (<EmptyBox>No batch activity in the last { pastDuration } day{s}. <Link to={"/partners/" + partnerId + "/batchactivity"}>Click here for all Batch Activity</Link></EmptyBox>);
    }

    let visaHeader = (<VisaCard height="32" />);
    let masterCardHeader = (<MasterCard height="28" />);
    let discoverHeader = (<DiscoverCard height="32" />);
    let amexHeader = (<AmericanExpressCard height="32" />);
    
    return (
        <MasterScreenMain selection="partners">
            <div className="pad">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    {partner.name}
                </h1>

                <div className="details-body body">
                    <PartnerSubMenu partner={partner} />
                    
                    <div className="pad">
                        <h2>
                            Overview
                            <RefreshButton onClick={getSubmissionReportsAsync.bind(this)} />
                        </h2>

                        <PartnerOverviewSubMenu partner={partner} />

                        { noAmex }
                        
                        <div id="metrics-row">
                            <BarGraph data={visaData} name="visa" header={visaHeader} subtitle="VISA Submissions By Day" size={barGraphSize} onAxisFormat={formatAxisLabel} />
                            <BarGraph data={masterCardData} name="mastercard" header={masterCardHeader} subtitle="MasterCard Submissions By Day" size={barGraphSize} onAxisFormat={formatAxisLabel} />
                            <BarGraph data={discoverData} name="discover" header={discoverHeader} subtitle="Discover Submissions By Day" size={barGraphSize} onAxisFormat={formatAxisLabel} />
                            <BarGraph data={amexData} href={"/partners/" + partnerId + "/american-express"} name="amex" header={amexHeader} subtitle="American Express Submissions By Day" size={barGraphSize} onAxisFormat={formatAxisLabel} />
                        </div>
                        
                        <div id="batch-activity">
                            <h3>
                                Batch Activity
                                <RefreshButton onClick={refreshBatchActivityAsync} />
                            </h3>
                            { batchElements }

                            <Pager id="partner-batch-pager-bottom" controller={pageController} items={batchActivity} />
                        </div>
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
};

export default PartnerDetailsScreen;
