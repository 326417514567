class SearchResultModel { 
    static json = {
        "id": "9E2C75BE-B742-4279-8F14-CE9EA744E346",
        "sub_item_id": "C252CD4B-3290-496C-9890-6D0707EF7591",
        "entity_type": "Batch",
        "name": "Batch 9e2c75beb74242798f1494716",
        "text": "2 cards, submitted on Oct 9, 2022 02:15, Updated on Oct 9, 2022 02:19",
        "match_text": "",
        "score": 0.0,
        "href": "merchants/c252cd4b-3290-496c-9890-6d0707ef7591/batches/9e2c75be-b742-4279-8f14-ce9ea744e346",
        "meta_data": "BatchFileName:Card_Sync_221009638009217434119142.asc|ProcessId:79ED0900-B778-4E7D-85DF-68A5009076E2|Message:WorldPay: 2",
        "created": "0001-01-01T00:00:00",
        "modified": null
    };
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        this.subItemId = json.sub_item_id;
        this.entityTypeName = json.entity_type;
        this.name = json.name;
        this.text = json.text;
        this.matchText = json.match_text;
        this.score = json.score || 0;
        this.href = json.href;
        this.metaData = json.meta_data;
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new SearchResultModel(json));
    }
}

export default SearchResultModel;