import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import CardBrandTrendSeriesModel from "../models/CardBrandTrendSeriesModel";
import DashboardDataModel from "../models/DashboardDataModel";
import ReportItemModel from "../models/ReportItemModel";
import BatchResultTypeTrendSeriesModel from "../models/BatchResultTypeTrendSeriesModel";
import PartnerActivityStatsModel from "../models/PartnerActivityStatsModel";

class ReportingService { 
    static instance = new ReportingService();
    
    constructor() {
        this.reportMap = {};
        this.partnerCardBrandMap = {};
        this.dashboard = null;
        this.dashboardDate = null;
    }

    async getMasterDashboardDataAsync(startDate, endDate) {
        const me = this;
        const path = '/api/report';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let model = new DashboardDataModel(rsp?.data);
            me.dashboard = model;

            return model;
        });
    };

    async getPartnerStatsAsync(startDate, endDate, cacheKey) {
        const me = this;
        const path = "/api/report/partner";

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let models = PartnerActivityStatsModel.fromJsonArray(rsp.data);

            if (!!cacheKey && typeof cacheKey === 'string' && models.length > 0) {
                me.reportMap[cacheKey] = models;
            }

            return models;
        });
    }

    async getMasterCardUpdatesByMonthAsync(startDate, endDate, cacheKey) {
        const me = this;
        const path = '/api/report/batch/monthly';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let models = ReportItemModel.fromJsonArray(rsp.data);

            if (!!cacheKey && typeof cacheKey === 'string' && !models.isEmpty) {
                me.reportMap[cacheKey] = models;
            }

            return models;
        });
    }

    async getMasterCardSubmissionsByMonthAsync(startDate, endDate, cacheKey) {
        const me = this;
        const path = '/api/report/batch/cardsubmissions/monthly';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let models = ReportItemModel.fromJsonArray(rsp.data);

            if (!!cacheKey && typeof cacheKey === 'string' && !models.isEmpty) {
                me.reportMap[cacheKey] = models;
            }

            return models;
        });
    }
    
    async getMasterBatchSubmissionsByMonthAsync(startDate, endDate, cacheKey) {
        const me = this;
        const path = '/api/report/batch/submissions/monthly';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let models = ReportItemModel.fromJsonArray(rsp.data);

            if (!!cacheKey && typeof cacheKey === 'string' && !models.isEmpty) {
                me.reportMap[cacheKey] = models;
            }

            return models;
        });
    }

    async getMasterBatchResultTypesByMonthAsync(startDate, endDate, cacheKey) {
        const me = this;

        startDate = new Date(2022, 0, 1);
        const path = '/api/report/batch/result/monthly';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let model = new BatchResultTypeTrendSeriesModel(rsp.data)

            if (!!cacheKey && typeof cacheKey === 'string' && !model.isEmpty) {
                me.reportMap[cacheKey] = model;
            }

            return model;
        });
    }
    
    async getMasterBatchResultsAsync(startDate, endDate, cacheKey) {
        const me = this;
        const path = '/api/report/batch/result';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let model = new BatchResultTypeTrendSeriesModel(rsp.data);

            if (!!cacheKey && typeof cacheKey === 'string' && !model.isEmpty) {
                me.reportMap[cacheKey] = model;
            }

            return model;
        });
    }

    async getMasterCardBrandCountsAsync(startDate, endDate, cacheKey) {
        const me = this;
        const path = '/api/report/batch/cardbrandcount';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            return rsp.data;
        });
    }
    
    async getMasterCardBrandResultCountsAsync(startDate, endDate, cacheKey) {
        const me = this;
        const path = '/api/report/batch';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            if (!!cacheKey && typeof cacheKey === 'string') {
                me.reportMap[cacheKey] = rsp.data;
            }
            
            return rsp.data;
        });
    }
    
    async getMasterCardBrandSubmissionsAsync(startDate, endDate, cacheKey) {
        const me = this;
        const path = '/api/report/batch/cardbrand';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let model = new CardBrandTrendSeriesModel(rsp?.data || {});

            if (!!cacheKey && typeof cacheKey === 'string' && !model.isEmpty) {
                me.reportMap[cacheKey] = model;
            }

            return model;
        });
    }

    async getMasterCardBrandUpdatesAsync(startDate, endDate, cacheKey) {
        const me = this;
        const path = '/api/report/batch/cardbrand/update';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let model = new CardBrandTrendSeriesModel(rsp?.data);

            if (!!cacheKey && typeof cacheKey === 'string' && !model.isEmpty) {
                console.log('Cached Updates Data: ' + cacheKey);
                me.reportMap[cacheKey] = model;
            }

            return model;
        });
    }

    async getCardBrandSubmissionReportAsync(partnerId, startDate, endDate) {
        const me = this;
        const path = '/api/partner/' + partnerId + '/report/batch/card/brand/submission/daily';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            me.partnerCardBrandMap[partnerId] = rsp.data;
            return rsp.data;
        });
    }

}

export default ReportingService;
