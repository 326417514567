import UserTokenModel from "./UserTokenModel";

class GoogleOAuthModel { 
    static create = (json) => new GoogleOAuthModel(json);
    constructor(json) {
        if (!json) json = {};
        
        this.authTokens = UserTokenModel.fromJsonArray(json.auth_tokens);
        this.initUrl = json.init_url || null;
        this.redirectUrl = json.redirect_url || null;
        this.name = json.name || null;
        this.clientAppId = json.client_app_id || null;
    }
}

export default GoogleOAuthModel;
