import React from 'react';
import {Link} from "react-router-dom";
import {
    faAddressCard,
    faPeopleGroup,
    faAnchor,
    faFolderOpen,
    faCreditCard,
    faIdCardClip,
    faLock,
    faFileWaveform, faBook, faRectangleList, faClipboardList, faClipboardCheck
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const PartnerSubMenu = (props) => {
    let selections = {};
    let partner = props.partner;
    let partnerId = partner?.id;
    
    let selector = props.selection || 'overview';
    selections[selector] = 'selected';
    
    return (
        <div className="sub-column">
            <ul>
                <li className="sub-menu-header">
                    More
                </li>
                <li className={selections['overview']}><Link to={ '/partners/' + partnerId}><FontAwesomeIcon icon={faIdCardClip} /> Overview</Link></li>
                <li className={selections['enrollments']}><Link to={ '/partners/' + partnerId + '/enrollments'}><FontAwesomeIcon icon={faPeopleGroup} /> Submerchants</Link></li>
                <li className={selections['account']}><Link to={ '/partners/' + partnerId + '/account'}><FontAwesomeIcon icon={faFolderOpen} /> Account Details</Link></li>
                <li className={selections['statements']}><Link to={ '/partners/' + partnerId + '/statements'}><FontAwesomeIcon icon={faClipboardCheck} /> Statements</Link></li>
                <li className={selections['billing']}><Link to={ '/partners/' + partnerId + '/billing'}><FontAwesomeIcon icon={faCreditCard} /> Billing Details</Link></li>
                <li className={selections['webhooks']}><Link to={ '/partners/' + partnerId + '/webhooks'}><FontAwesomeIcon icon={faAnchor} /> Webhooks</Link></li>
                <li style={{display: 'none'}} className={selections['authentication']}><Link to={ '/partners/' + partnerId + '/authentication'}><FontAwesomeIcon icon={faLock} /> Authentication</Link></li>
            </ul>
        </div>
    );
};

export default PartnerSubMenu;
