import React, {useEffect, useState} from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard} from "@fortawesome/free-solid-svg-icons";

import {Link, useParams} from "react-router-dom";
import PartnerSubMenu from "../../components/partners/ui/PartnerSubMenu";
import PartnerForm from "../../components/partners/ui/PartnerForm";

import PartnerService from "../../components/partners/services/PartnerService";
import Controller from "@paylani/paylani-react-packages/dist/common/controllers/Controller";

const PartnerAccountDetailsScreen = (props) => {
    const { partnerId } = useParams();
    const [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId] || null);
    const controller = useState(new Controller())[0]
    let _;

    const updateAccountDetailsAsync = async (e) => {
        const data = controller.getFormData("partner");
        return await PartnerService.instance.updatePartnerAsync(partnerId, data).then((p) => { 
            setPartner(p);
            return true;
        });
    };

    const getPartnerAsync = async () => {
        console.warn('Getting partner: ' + partnerId);
        await PartnerService.instance.getPartnerAsync(partnerId)
            .then((partner) => setPartner(partner))
            .catch((error) => console.log('Ex: ' + error));
        
    };
    
    useEffect(() => {
        _ = getPartnerAsync();
    }, []);
    
    const form = !!partner?.id ? (<PartnerForm partner={partner} controller={controller} onSubmit={updateAccountDetailsAsync} useButton={true} />) : null;
    
    return (
        <MasterScreenMain selection="partners">
            <div className="pad form">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    {partner?.name}
                </h1>

                <div className="details-body medium">
                    <PartnerSubMenu partner={partner} selection="account" />

                    <div className="pad">
                        <h2>Account Details</h2>
                        <p>
                            Account details are mainly for communication purposes. When { partner?.name } enrolls a new Submerchant,
                            the <Link to={'/messaging/' + partner?.id}>email message</Link> will go to the email below.
                        </p>

                        { form }
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
};

export default PartnerAccountDetailsScreen;