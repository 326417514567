class JobModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.runByUserId = json.run_by_user_id || '';
        this.jobType = json.job_type || 0;
        this.jobTypeName = json.job_type_name || '';
        this.itemCount = json.item_count || 0;
        this.startTime = json.start_time ? json.start_time.toDate() : null;
        this.endTime = json.end_time ? json.end_time.toDate() : null;
        this.message = json.message || '';
        this.status = json.status || 0;
        this.statusName = json.status_name || '';
        this.id = json.id || '';
        this.created = json.created ? json.created.toDate() : null;
        this.modified = json.modified ? json.modified.toDate() : null;
    }
    
    static fromJsonArray(jsonArray) {
        let models = [];
        
        for (let json of jsonArray) {
            models.push(new JobModel(json));
        }
        
        return models;
    }
}

export default JobModel;
