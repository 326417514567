import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";

const BackButton = (props) => {
    let { to, path, show } = props;

    if (typeof show === 'string') {
        if (window.location.hash !== ("#" + show)) return (<></>);
    }
    
    const content = (<FontAwesomeIcon icon={faAngleLeft} />);
    
    let onClick = (e) => {
        if (!hasLink) {
            e.preventDefault();
            window.history.back();
            return false;
        }
    };
    
    let hasLink = true;
    let href = to || path;
    
    if (!href) {
        hasLink = false;
        href = '/';
    }
    
    return (<span className="back-button">
        <Link onClick={onClick} to={href}>{ content }</Link>
    </span>);
};

export default BackButton;