import ReportItemModel from "./ReportItemModel";

class DashboardDataModel { 
    static json = {
        "batch_submissions": {
            "label": "Batches Submitted",
            "id": "batch_submissions",
            "value": 59.0,
            "xAxisSortValue": 0,
            "xAxisDate": null,
            "xAxisValue": null
        },
        "batch_updates": {
            "label": "Batches Processed",
            "id": "batch_updates",
            "value": 28.0,
            "xAxisSortValue": 0,
            "xAxisDate": null,
            "xAxisValue": null
        },
        "card_updates": {
            "label": "Card Updates",
            "id": "card_updates",
            "value": 90.0,
            "xAxisSortValue": 0,
            "xAxisDate": null,
            "xAxisValue": null
        },
        "job_run": {
            "label": "Jobs Run",
            "id": "job_run",
            "value": 27.0,
            "xAxisSortValue": 0,
            "xAxisDate": null,
            "xAxisValue": null
        }
    };
    
    constructor(json) {
        if (!json) json = {};
        this.batchSubmissions = new ReportItemModel(json.batch_submissions);
        this.batchUpdates = new ReportItemModel(json.batch_updates);
        this.cardUpdates = new ReportItemModel(json.card_updates);
        this.jobRun = new ReportItemModel(json.job_run);
        this.lastProcess = new ReportItemModel(json.last_process);
        this.lastSubmission = new ReportItemModel(json.last_submission);
        this.lastUpdate = new ReportItemModel(json.last_update);
    }
}

export default DashboardDataModel;
