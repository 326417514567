import React, {useRef} from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGears} from "@fortawesome/free-solid-svg-icons";
import AddressForm from "@paylani/paylani-react-packages/dist/geo/ui/AddressForm";
import SettingsSubMenu from "../../components/settings/ui/SettingsSubMenu";
import ConfigModel from "@paylani/paylani-react-packages/src/common/models/ConfigModel";

const CompanyInfoScreen = (props) => {
    let company = {
        name: ConfigModel.companyName || "CardSync.io",
        email: 'support@cardsync.io',
        address: {
            street1: '123 Main St',
            street2: '',
            city: 'Las Vegas',
            state: 'NV',
            zip: '08902',
            country: 'US'
        }
    };
    
    const [payload, setPayload] = React.useState(company);
    const [errors, setErrors] = React.useState({ name: "", email: "" });
    
    const nameRef = useRef();
    const emailRef = useRef();
    
    const onFormChange = (e) => { 
        //
    };
    
    const validate = (data) => {
        let errs = {};
        
        if (!data.name) errs.name = "Name is required";
        if (!data.email) errs.email = "Email is required";
        
        setErrors(errs);
        
        return (Object.keys(errs).length === 0);
    };
    
    const updateInfoAsync = async (addressJson, e) => {
        let data = {
            name: nameRef.current?.value,
            email: emailRef.current?.value,
        }

        if (!validate(data)) return;
        
        data.address = addressJson;
        
        console.log(JSON.stringify(data, null, 4));
    }
    
    return (
        <MasterScreenMain selection="settings">
            <div className="pad">
                <h1><FontAwesomeIcon icon={faGears} /> Settings</h1>

                <div className="details-body medium">
                    <SettingsSubMenu selection="company" />

                    <div className="pad">
                        <h2>Company Information</h2>
                        <p>This information is displayed on CardSync statements, and other correspondence originating from this platform.</p>
                        
                        <div className="form">
                            <div className="form-group">
                                <label>Display Name</label>
                                <input type="text" id="name" ref={nameRef} defaultValue={payload.name} onChange={onFormChange.bind(this, 'name')} />
                                <div className={"form-error"}>{ errors?.name }</div>
                            </div>

                            <div className="form-group">
                                <label>From Email Address</label>
                                <input type="text" id="email" ref={emailRef} defaultValue={payload.email} onChange={onFormChange.bind(this, 'email')} />
                                <div className={"form-error"}>{ errors?.email }</div>
                            </div>

                        </div>
                        
                        <AddressForm address={payload.address} useButton={true} onSubmit={updateInfoAsync} />
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
};

export default CompanyInfoScreen;
