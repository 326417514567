import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPersonWalking,
    faArrowRightToBracket,
    faUserPlus,
    faFeatherPointed
} from "@fortawesome/free-solid-svg-icons";

import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";
import PersonForm from "@paylani/paylani-react-packages/dist/people/ui/PersonForm";
import Controller from "@paylani/paylani-react-packages/src/common/controllers/Controller";

import UserService from "../../components/people/services/UserService";
import MasterScreenMain from "../../MasterScreenMain";
import '../../components/people/ui/users.css';
import BackButton from "../../components/common/ui/BackButton";
import UserSubMenu from "../../components/people/ui/UserSubMenu";

const UserDetailsScreen = (props) => {
    const { userId } = useParams();
    const [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    const controller = useState(new Controller())[0];
    let _;
    
    const getUserAsync = async () => {
        return await UserService.instance.getUserAsync(userId).then((u) => {
            if (!!u?.id) setUser(u);
        });
    };

    const updateUserAsync = async (user) => {
        console.log(JSON.stringify(user, null, 4));
        
        await UserService.instance.updateUserAsync(user, userId).then((u) => {
            if (!!u?.id) setUser(u);
        });
    };
    
    useEffect(() => {
        _ = getUserAsync();
    }, []);

    const userForm = !!user ?
        (<PersonForm className={"top"} value={user.toJson()} useButton={true} buttonCaption={"Update User Details"} onSubmit={updateUserAsync} />) : 
        null;
    
    const subMenu = !!user ? (<UserSubMenu user={user} selection={"details"} />) : null;
    
    const dateActivityElements = !!user ? (<div className={"date-activity"}>
        <span id={"user-last-login-date"}>
            <FontAwesomeIcon icon={faArrowRightToBracket} />
            <DateTime showRelative={true} value={user?.lastLogin} defaultValue={"Hasn't logged in yet"} prefix={"Last Login: "} />
        </span>
        <span id={"user-created-date"}>
            <FontAwesomeIcon icon={faUserPlus} />
            <DateTime showRelative={true} value={user?.created} defaultValue={"Record creation date was not logged"} prefix={"Created: "} />
        </span>
        <span id={"user-modified-date"}>
            <FontAwesomeIcon icon={faFeatherPointed} />
            <DateTime showRelative={true} value={user?.modified} defaultValue={"User has never been modified"} prefix={"Last Modified: "} />
        </span>
    </div>) : null;
    
    return (
        <MasterScreenMain selection="users">
            <div className="pad">
                <h1><FontAwesomeIcon icon={faPersonWalking} /> Users</h1>

                <div className="details-body large multi-form">
                    { subMenu }
                    <div className="pad">
                        <h2>
                            <BackButton path="/people" />
                            { user?.name }
                            <span className="subtitle">User Details</span>
                            <RefreshButton onClick={() => getUserAsync(true)} />
                        </h2>

                        { dateActivityElements }
 
                        <p>User details appear on logs, reports, and partner correspondance. Largely for display and communications purposes.</p>
                        
                        <div id="user-details-body">
                            { userForm }
                        </div>
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
    
};

export default UserDetailsScreen;