import React from 'react';
import {Link} from "react-router-dom";

const BarGraph = (props) => {
    const { title, data, height, maxValue, key, onClick, color, name, id, emptyText, header, subtitle, href, onAxisFormat, size } = props;

    if (!data) { 
        return (<span>No data</span>);
    }
    
    let sizeClass = size === 'small' ? ' small' : '';
    let scale = size === 'small' ? 0.7 : 1.0;
    
    let h = height || 94;
    let max = maxValue || Math.max.apply(Math, data.map(function(o) { return typeof o === 'number' ? o : o.value; }));
    let yunits = max > 0 ? h / max : 0;
    let k = key || 'bar-graph-' + (Math.random() * 10000).toFixed(0).toString();
    
    let total = 0;
    let bars = data.map((item, index) => {
        let value = item;
        let label = null;
        
        if (typeof item === 'object') {
            label = item.label;
            value = item.value;
            total += item.value;
        }

        if (typeof onAxisFormat === 'function') {
            label = onAxisFormat(item);
        }
        
        const barHeight = Math.max(value * yunits * scale, 2 * scale);
        const labelElement = (!!label) ? (<label className="bar-label">{label}</label>) : null;
        
        return (
            <span className="bar-column" key={k + '-column-' + index.toString()}>
                <span className="bar-value">{ value }</span>
                <span className="bar" style={{height: barHeight.toString() + 'px'}}> </span>
                {labelElement}
            </span>
        );
    });

    if (data.length === 0) { 
        if (!!emptyText) {
            bars = (<span>{emptyText}</span>);
        }
        else return (<></>);
    }
    else if (total === 0 && !!emptyText) {
        bars = (<span className={"bar-graph-empty-text"}>{emptyText}</span>);
    }
    
    const onBarClick = (event) => {
        if (onClick) {
            onClick(event);
        }
    };
    
    let headerComponent = header || (<></>);
    let titleElement = (title && title.length > 0) ? (<span>{title}</span>) : (<>&nbsp; </>);
    
    const subTitleElement = !!href ? (<Link to={href}>{subtitle}</Link>) : (<>{subtitle}</>);
    
    return (
        <div className={ 'graph ' + name + sizeClass}>
            <div className="content">
                <h4>
                    {titleElement}
                    {headerComponent}
                </h4>
                <div className="bars" onClick={onBarClick}>
                    {bars}
                </div>
            </div>
            <label className="graph-subtitle">{ subTitleElement }</label>
        </div>
    );
};

export default BarGraph;
