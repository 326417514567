import React from 'react';
import MasterScreen from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFaceTired} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const Error404Screen = (props) => {
    const {} = props;

    return (
        <MasterScreen selector={""}>
            <div id="error-main" className="pad">
                <h1>
                    <FontAwesomeIcon icon={faFaceTired}/>
                    Error
                </h1>

                <div className="details-body">
                    <div className="pad x-large">
                        <h2>
                            404 Error.
                        </h2>

                        <p>
                            Page was not found. Sorry.<br/>
                            <Link to={"/"}>Return home then</Link>
                        </p>

                        <div>

                        </div>
                    </div>
                </div>
            </div>
        </MasterScreen>
    );

};

export default Error404Screen;
