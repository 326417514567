class UserTokenModel { 
    static json = {
        "user_id": "b8804d7e-9b80-459b-b8d6-963fa2852228",
        "token_type": 1,
        "is_expired": false,
        "expires": "2023-01-14T18:39:21.094747Z",
        "token": "ya29.a0AX9GBdUMgsovUMOwPbZgWZko1nsAciq-jyBe8uDCpcG_GJ8J8K1GgcogawGc0pr0ojSn09dM9lywWV3pYeSyxt1GEtBzE6Dor3PA-PJxYOidzpamad_MGu0bJfmmU4Y1qQPj1N9VoGSblYwrYQaDIdz64MBaPAd4aCgYKARASAQASFQHUCsbCx3Io2G6tsYE1lIXByYqduQ0167",
        "account_name": "jade@paylani.com",
        "client_app_id": "124658838710-fpu7hrnrim1gaqu2ptnt80aat18nt2p8.apps.googleusercontent.com",
        "platform": 2,
        "id": "2bd3a1d4-9b3b-4b61-8d14-29deb4695948",
        "created": "2023-01-02T23:26:46.523",
        "modified": "2023-01-14T17:39:22.195658Z"
    };

    constructor(json) {
        if (!json) json = {};
        
        const parent = json;
        if (!!json.auth_token) json = json.auth_token;
        
        this.userId = json.user_id || null;
        this.tokenType = json.token_type || 0;
        this.isExpired = json.is_expired || false;
        this.expires = json.expires || null;
        this.token = json.token || null;
        this.accountName = json.account_name || null;
        this.clientAppId = json.client_app_id || null;
        this.platform = json.platform || 2;
        this.id = json.id || null;
        
        this.redirectUrl = parent.redirect_url || null;
        this.initUrl = parent.init_url;
        this.name = parent.name;
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }

    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map((json) => new UserTokenModel(json));
    }
}

export default UserTokenModel;
