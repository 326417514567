import React, {useEffect, useState, useRef} from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {Link, useParams} from "react-router-dom";
import PartnerSubMenu from "../../components/partners/ui/PartnerSubMenu";
import BackButton from "../../components/common/ui/BackButton";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import MerchantSubMenu from "../../components/partners/ui/MerchantSubMenu";
import ToolTip from "../../components/common/ui/ToolTip";

import {
    faAddressCard, faAnchor, faAnchorCircleCheck, faAnchorCircleExclamation,
    faChampagneGlasses, faCircleCheck, faCloudArrowDown,
    faCreditCard,
    faPeopleGroup, faSync, faSyncAlt,
    faVcard
} from "@fortawesome/free-solid-svg-icons";

import '../../components/partners/ui/Partners.css';

import MerchantModel from "../../components/partners/models/MerchantModel";
import PartnerModel from "../../components/partners/models/PartnerModel";
import PartnerService from "../../components/partners/services/PartnerService";
import BatchService from "../../components/batches/services/BatchService";
import ProviderTypeModel from "../../components/batches/models/ProviderTypeModel";
import PagerController from "../../components/common/ui/pager/PagerController";
import Pager from "../../components/common/ui/pager/Pager";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";
import EmptyBox from "../../components/common/ui/EmptyBox";

const MerchantBatchesScreen = (props) => {
    const {merchantId} = useParams();
    const empty = "00000000-0000-0000-0000-000000000000";
    const pid = useParams().partnerId || empty;
    
    let [partnerId, setPartnerId] = useState(pid);
    let [merchant, setMerchant] = useState(PartnerService.instance.subMerchantMap[merchantId] || new MerchantModel()); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));
    let [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId] || new PartnerModel());
    let [batches, setBatches] = useState([]);
    
    let _;
    let [currentPage, setCurrentPage] = useState(0);

    let pageController = useRef(new PagerController(setCurrentPage, 20)).current;

    const refreshBatchesAsync = async (e) => {
        console.log('Refreshing batches...');
        return await BatchService.instance.getBatchesBySubMerchantAsync(merchantId).then((batches) => {
            setBatches(batches);
        });
    };

    const getPartnerAsync = async (partId) => {
        await PartnerService.instance.getPartnerAsync(partId || partnerId)
            .then((partner) => {
                console.log('Got Partner: ' + partner.customer.toJson().first_name);
                setPartner(partner);
            })
            .catch((error) => console.log('Ex: ' + error));
    };

    useEffect(() => {
        if (partner?.id) setPartnerId(partner.id);
    },[partner]);
    
    useEffect(() => {
        if (!!merchant && partnerId === empty || !partnerId || partnerId.length < 30)
            _ = getPartnerAsync(merchant.partnerId);
        
    }, [merchant]);
    
    useEffect(() => {
        _ = getPartnerAsync();
        
        PartnerService.instance.getSubMerchantAsync(merchantId, partnerId).then((subMerchant) => {
            console.log('Got SubMerchant: ' + subMerchant.name);
            setMerchant(subMerchant);
        });

        let x = refreshBatchesAsync();
        
    }, []);

    const dateStyle = {fontSize: '13px' };

    let emptyBox = batches.length === 0 ? (<EmptyBox>No Batches Submitted Yet</EmptyBox>) : null;
    
    let batchElements = pageController.mapLineItems(batches, (b) => {
        let rowStatusClass = b.isPickedUp ? ' picked-up' : '';
        let statusStyle = b.isPickedUp ? {fontSize: '13px', color: 'var(--header-green)'} : {};
        let desc = b.isPickedUp ? 'Picked up on ' + b.pickupDate.toDateTime() : 'Not picked up yet';
        let status = !!b.isPickedUp ? 'Picked up on ' + b.pickupDate.toDateTime() : b.batchStatusName;
        let statusIcon = b.getIcon();
        let icon = b.getIcon();
        
        if (status.toLowerCase() === 'completed') {
            status = 'Completed, but not picked up yet';
            statusStyle.fontSize = '13px';
            statusStyle.color = 'var(--yellow)';
        }
        
        let tooltipComponent = (<span><FontAwesomeIcon icon={icon} /> {desc}</span>);
        let tooltipStatusComponent = (<span><FontAwesomeIcon icon={icon} /> {status}</span>);
        
        return (
            <tr key={'batch-' + b.id} className={'batch-item status-' + b.batchStatusName.toLowerCase() + rowStatusClass}>
                <td>
                    <ToolTip value={tooltipComponent}>
                        <Link to={'/partners/' + partnerId + '/merchants/' + merchantId + '/batch/' + b.batchId}>
                            <FontAwesomeIcon icon={icon} />
                            {b.batchId}
                        </Link>
                    </ToolTip>
                </td>
                <td width="20%" style={dateStyle}><DateTime value={b.created} time={true} /></td>
                <td width="20%" style={dateStyle}><DateTime value={b.modified} defaultValue="Not Updated Yet" time={true} /></td>
                <td width="28%" style={statusStyle}><ToolTip value={tooltipStatusComponent}>{status}</ToolTip></td>
            </tr>
        );
    });

    let providerType = ProviderTypeModel.getProviderByType(merchant.providerType);
    let providerName = merchant.providerName;

    return (
        <MasterScreenMain selection="partners">
            <div className="pad" id="partner-merchant-details">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    {partner.name}
                </h1>

                <div className="details-body">
                    <PartnerSubMenu partner={partner} selection="enrollments" />

                    <div className="pad">
                        <h2>
                            <BackButton to={'/partners/' + partnerId + '/enrollments'} />
                            {merchant.name}:
                            <span className="subtitle">Batches</span>
                        </h2>
                        
                        <MerchantSubMenu partner={partner} merchant={merchant} selection={"batches"} />

                        <p>
                            Batches are submitted, then uploaded to a {providerName} SFTP server. {providerName} processes the batches then uploads the results to the same SFTP server for pickup.
                        </p>

                        <h3>
                            <FontAwesomeIcon icon={faCreditCard} />
                            Batch Activity

                            <RefreshButton onClick={refreshBatchesAsync} caption={"Refresh Batches"} />
                        </h3>
                        <table className="table-large" width="100%" id="merchant-batch-table">
                            <thead>
                            <tr>
                                <th className="name">BatchId</th>
                                <th width="20%">Submitted On</th>
                                <th width="20%">Updated On</th>
                                <th width="28%">Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {batchElements}
                            </tbody>
                        </table>
                        <Pager items={batches} controller={pageController} />
                        {emptyBox}
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
};

export default MerchantBatchesScreen;
