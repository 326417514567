import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBridgeCircleCheck, faChartArea, faChartLine,
    faCircle, faCreditCard,
    faDiagramProject, faEye, faFloppyDisk,
    faGears,
    faKey, faMagnifyingGlassChart,
    faRobot,
    faSuitcase
} from "@fortawesome/free-solid-svg-icons";

const ReportingSubMenu = (props) => {
    let selections = {};
    let selector = props.selection || 'overview';

    selections[selector] = 'selected';

    const w = 24;
    const h = 16;

    const cardBrandIcon = (
        <svg width={w} height={h} enableBackground="new 0 0 780 500" version="1.1" viewBox="100 -10 780 340" style={{backgroundColor: 'rgba(244, 0, 0, 0.0)', marginRight: '2', marginLeft: '0', paddingLeft: '0'}}>
            <path fill="#D9222A" d="m449.01 250c0 99.143-80.371 179.5-179.51 179.5s-179.5-80.361-179.5-179.5c0-99.133 80.362-179.5 179.5-179.5 99.137 0 179.51 80.371 179.51 179.5"/>
            <path shapeRendering="geometricPrecision" fill="#EE9F2D" d="m510.49 70.496c-46.379 0-88.643 17.596-120.5 46.467-6.49 5.889-12.548 12.237-18.125 18.996h36.267c4.965 6.037 9.536 12.387 13.685 19.012h-63.635c-3.827 6.122-7.281 12.469-10.342 19.008h84.313c2.894 6.185 5.431 12.53 7.601 19.004h-99.513c-2.09 6.234-3.832 12.58-5.217 19.008h109.94c2.689 12.49 4.045 25.231 4.042 38.008 0 19.935-3.254 39.112-9.254 57.021h-99.513c2.164 6.477 4.7 12.824 7.596 19.008h84.316c-3.063 6.541-6.519 12.889-10.347 19.013h-63.625c4.147 6.62 8.719 12.966 13.685 18.996h36.259c-5.57 6.772-11.63 13.127-18.13 19.013 31.857 28.866 74.117 46.454 120.5 46.454 99.139 0 179.51-80.361 179.51-179.5 0-99.129-80.371-179.5-179.51-179.5" />
        </svg>
    );
    
    return (
        <div className="sub-column" id="reporting-sub-menu">
            <ul>
                <li className="sub-menu-header">
                    More
                </li>
                <li className={selections['overview']}><Link to={ '/reporting'}><FontAwesomeIcon icon={faEye} /> Snapshot</Link></li>
                <li className={selections['batches']}><Link to={ '/reporting/batches'}><FontAwesomeIcon icon={faCreditCard} /> Batches &amp; Updates</Link></li>
                <li className={selections['card-brands']}><Link to={ '/reporting/card-brands'}>{cardBrandIcon} Card Brands</Link></li>
                <li className={("under-construction " + (selections['trends'] || "")).trim()}><Link to={ '/reporting/trends'}><FontAwesomeIcon icon={faChartLine} /> Trends</Link></li>
                <li className={("under-construction " + (selections['custom'] || "")).trim()}><Link to={ '/reporting/custom-reports'}><FontAwesomeIcon icon={faFloppyDisk} /> Saved Reports</Link></li>
            </ul>
        </div>
    );

};

export default ReportingSubMenu;
