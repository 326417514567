import React from 'react';
import VisaCard from "./CreditCards/VisaCard";
import MasterCard from "./CreditCards/MasterCard";
import DiscoverCard from "./CreditCards/DiscoverCard";
import AmericanExpressCard from "./CreditCards/AmericanExpressCard";

const CreditCardBrandPanel = (props) => {
    const { batch, fieldKey } = props;

    const fk = ["Submitted", "Count"].includes(fieldKey || "") ? fieldKey : batch.batchStatus === 2 ? "Count" : "Submitted";
    
    return (<div className="card-brands">
            <span className="card visa">
                <span className="content">
                    <VisaCard height={24} visaColor="#AAA9FF" swishColor="#AAF" />
                <span className="value">{ batch["visa" + fk] }</span>
            </span>
        </span>
        <span className="card mastercard">
            <span className="content">
                <MasterCard height={24} leftCircleColor="#D9555C" />
                <span className="value">{ batch["masterCard" + fk] }</span>
            </span>
        </span>
        <span className="card discover">
            <span className="content">
                <DiscoverCard height={24} />
                <span className="value">{ batch["discover" + fk] }</span>
            </span>
        </span>
        <span className="card amex">
            <span className="content">
                <AmericanExpressCard height={24} adjusted={true} />
                <span className="value">{ batch["amex" + fk] }</span>
            </span>
        </span>
    </div>);
};

export default CreditCardBrandPanel;
