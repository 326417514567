import React, { useRef, useState } from 'react';

const ActionButton = (props) => {
    let buttonId = props.id || 'form-button-' + Math.floor(Math.random() * 1000000).toString();
    let buttonRef = useRef(null);
    let [spinClassName, setSpinClassName] = useState("");
    
    const onButtonClickAsync = async function() {
        if (typeof props.onClick === 'function') { 
            setSpinClassName("spinning");
            await props.onClick();
            setSpinClassName("");
        }
    };

    let label = props.label || (props.children || 'Confirm');
    
    return (
        <button id={buttonId} className={"ui-action-button spinner"} ref={buttonRef} onClick={onButtonClickAsync}>
            <div>
                <span></span>
                <span>
                    { label }
                </span>
                <span className={spinClassName}><label></label></span>
            </div>
        </button>
    );
};

export default ActionButton;
