class PlanTierModel {
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        this.itemCount = json.item_count;
        this.price = json.price;
        this.planId = json.plan_id;
        this.tierType = json.tier_type;
        this.tierTypeName = json.tier_type_name || "";
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    toJson() { 
        return { 
            item_count: this.itemCount,
            price: this.price,
            tier_type: this.tierType,
        }
    }

    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map((json) => new PlanTierModel(json));
    }
}

export default PlanTierModel;
