import SupportAccessModel from "./SupportAccessModel";
import SupportTicketModel from "./SupportTicketModel";

class SupportTicketThreadModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.threadId = json.thread_id;
        this.id = (json.id || json.thread_id) || null;
        this.access = new SupportAccessModel(json.access);
        this.messageCount = json?.tickets?.length || 0;
        this.isRead = json?.is_read || false;
        this.startDate = json.start_date ? new Date(json.start_date) : null;
        this.endDate = json.end_date ? new Date(json.end_date) : null;
        this.supportTicketId = json.support_ticket_id || null;
        this.merchantId = json.merchant_id || null;
        this.tickets = SupportTicketModel.fromJsonArray(json.tickets);
        this.recentTicket = new SupportTicketModel(json.recent_ticket);
        
        if (!this.recentTicket?.id) this.recentTicket = null;
    }
    
    static fromJsonArray(json) {
        if (!json || !Array.isArray(json)) return [];
        
        return json.map(item => new SupportTicketThreadModel(item));
    }

    isMatch(term) {
        return this.getMatchValue(term) > 0;
    }

    getMatchValue(term) {
        if (!term || term.length < 1) {
            console.warn("Thread Filter Term no good");
            return 1;
        }
        
        let score = 0;
        
        if ((this.id || "").toLowerCase().indexOf(term) > -1) 
            score++;

        console.warn("Searching " + (this.tickets?.length || "null") + " tickets");
        for(let i = 0; i < this.tickets.length; i++) {
            score += this.tickets[i].getMatchValue(term);
        }

        return score;
    }
}

export default SupportTicketThreadModel;
