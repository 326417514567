import SupportAccessModel from "./SupportAccessModel";
import {faBug, faComments, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import PartnerModel from "../../partners/models/PartnerModel";

class SupportTicketModel {
    static typeIconMap = {
        bug: faBug,
        '1': faBug,
        inquiry: faComments,
        '2': faComments,
    };

    static typeColorMap = {
        bug: 'rgba(255, 100, 100, 0.5)',
        '1': 'rgba(255, 0, 0, 0.5)',
        inquiry: 'rgba(0, 255, 0, 0.5)',
        '2': 'rgba(0, 255, 0, 0.5)',
    };
    
    constructor(json) {
        if (!json) json = {};
        
        this.merchantId = json.merchant_id;
        this.merchant = new PartnerModel(json.merchant) || null;
        this.merchantName = this.merchant?.name || "Unknown Merchant";
        
        this.supportAccessId = json.support_access_id;
        this.replyUserId = json.reply_user_id;
        this.isReply = json.is_reply || false;
        this.userId = json.user_id;
        this.fromName = json.from_name;
        this.fromEmail = json.from_email;
        this.fromPhone = json.from_phone;
        this.subject = json.subject || null;
        this.body = json.body || null;
        this.messageId = json.message_id || null;
        this.threadId = json.thread_id || null;
        this.readDate = json.read_date ? new Date(json.read_date) : null;
        this.replyDate = json.reply_date ? new Date(json.reply_date) : null;
        this.platform = json.platform;
        this.ticketType = json.ticket_type || 0;
        this.ticketStatus = json.ticket_status || 0;
        this.status = json.status;
        this.sentDate = json.sent_date ? new Date(json.sent_date) : null;
        this.notes = json.notes || null;
        this.access = new SupportAccessModel(json.access);
        this.source = json.source || null;
        
        this.viewState = 0; // 0=collapsed, 1=expanded, 2=full
        this.isLoaded = false;
        
        this.id = json.id;
        
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }

    getIcon() {
        return SupportTicketModel.typeIconMap[this.ticketType.toString()] || faEnvelope;
    }

    getColor() {
        return SupportTicketModel.typeColorMap[this.ticketType.toString()] || 'rgba(255, 255, 255, 0.5)';
    }

    isMatch(term) { 
        return this.getMatchValue(term) > 0;
    }
    
    getMatchValue(term) {
        if (!term || term.toString().length < 2) return 1;

        term = term.toString().toLowerCase();
        let score = 0;
        
        // Check Ids
        if ((this.id || "").toLowerCase().indexOf(term) > -1 || (this.messageId || "").toLowerCase().indexOf(term) > -1)
            score++;

        console.log("Search: " + term);
        // Check names
        if ((this.fromName || "").toLowerCase().indexOf(term) > -1 || (this.fromEmail || "").toLowerCase().indexOf(term) > -1 || (this.fromPhone || "").toLowerCase().indexOf(term) > -1)
            score++;

        // Check Company/Merchant
        if ((this.merchantName || "").toLowerCase().indexOf(term) > -1 || (this.merchantId || "").toLowerCase().indexOf(term) > -1)
            score++;
        
        // Check content
        if ((this.subject || "").toLowerCase().indexOf(term) > -1 || (this.body || "").toLowerCase().indexOf(term) > -1)
            score++;

        return score;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        return jsonArray.map(json => new SupportTicketModel(json));
    }
}

export default SupportTicketModel;
