import React, {useState, useRef, useEffect} from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressCard, faCaretRight, faFaceFrown, faFaceSmile, faFileCsv, faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import PartnerModel from "../../components/partners/models/PartnerModel";
import {Link, useParams} from "react-router-dom";
import PartnerSubMenu from "../../components/partners/ui/PartnerSubMenu";
import EmailAddress from "@paylani/paylani-react-packages/dist/common/formatting/EmailAddress";
import ActionButton from "../../components/common/ui/ActionButton";
import '../../components/partners/ui/Partners.css';
import ToolTip from "../../components/common/ui/ToolTip";
import PartnerService from "../../components/partners/services/PartnerService";
import ProviderTypeModel from "../../components/batches/models/ProviderTypeModel";
import Pager from "../../components/common/ui/pager/Pager";
import PagerController from "../../components/common/ui/pager/PagerController";
import EmptyBox from "../../components/common/ui/EmptyBox";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";

const PartnerEnrollmentsScreen = (props) => {
    const { partnerId } = useParams();
    let [subMerchants, setSubMerchants] = useState(PartnerService.instance.subMerchantsMap[partnerId] || []);
    let [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId] || new PartnerModel({}));
    let [downloadingVisaClassName, setDownloadingVisaClassName] = useState('');
    let [downloadingMasterCardClassName, setDownloadingMasterCardClassName] = useState('');

    let [currentPage, setCurrentPage] = useState(0);
    let pageController = useRef(new PagerController(setCurrentPage)).current;
    let _;
    
    const getMerchantsAsync = async () => {
        return await PartnerService.instance.getSubMerchantsAsync(partnerId)
            .then((sm) => setSubMerchants(sm))
            .catch((error) => console.error(error));
    };
    
    const getPartnerAsync = async () => {
        return await PartnerService.instance.getPartnerAsync(partnerId)
            .then((partner) => {
                console.log('Got Partner: ' + partner.customer.toJson().first_name);
                setPartner(partner);
            })
            .catch((error) => console.log('Ex: ' + error));
    };
    
    useEffect(() => {
        _ = getPartnerAsync();
        _ = getMerchantsAsync();
    }, []);

    const d = new Date();
    const dateKey = d.getMonth().toString() + "_" + d.getDate().toString() + "_" + d.getFullYear().toString();
    
    const approveEnrollmentAsync = async (enrollmentId, e) => {
        return await PartnerService.instance.approveSubMerchantAsync(enrollmentId, partnerId).then((model) => { 
            _ = getMerchantsAsync();
        }).catch((err) => { 
            let msg = err?.response?.data?.messages[0] || err;
            _ = getMerchantsAsync();
            console.error(msg);
        });
    };
    
    const nameStyle = {width: '50%'};
    const tdStyle = {width: '25%'};

    const generateExcel = (cardType, e) => {
        if (cardType === 4) {
            if (downloadingVisaClassName !== '') return;
            setDownloadingVisaClassName('spinner')
        }
        else {
            if (downloadingMasterCardClassName !== '') return;
            setDownloadingMasterCardClassName('spinner');
        }

        console.log('CardType: ' + cardType);
        PartnerService.instance.createPartnerSpreadsheetAsync(partnerId, cardType).then((fileKey) => {
            if (cardType === 4) setDownloadingVisaClassName('')
            else setDownloadingMasterCardClassName('')

            window.location.href = '/api/partner/enrollmentspreadsheet/' + fileKey;
        }).catch((error) => {
            if (cardType === 4) setDownloadingVisaClassName('')
            else setDownloadingMasterCardClassName('')
            console.log('Ex: ' + error);
        });
    };
    
    let partnerIcon = ProviderTypeModel.getProviderByType(partner.providerType).getIcon(); //partner.providerType === 1 ? faF : faW;
    
    let activeMerchants = subMerchants.filter((x, index) => x.enrollmentStatus === 1);
    let enrollmentElements = pageController.mapLineItems(activeMerchants, (m) => {
        let icon = partnerIcon;
        let providerType = ProviderTypeModel.getProviderByType(m.providerType);
        
        icon = providerType.getIcon();
        
        let cn = 'enrollment-' + m.providerType.toString();

        return (
            <tr key={'submerchant-' + m.id} className={cn}>
                <td className="name"><Link to={'/partners/' + partnerId + '/merchants/' + m.id}><FontAwesomeIcon icon={icon} /> {m.trimmedName(32)}</Link></td>
                <td className="mid">{m.mid}</td>
                <td className="date"><DateTime time={true} value={m.created} /></td>
            </tr>
        );
    });
    
    let emptyBox = activeMerchants.length === 0 ? (<EmptyBox icon={faFaceFrown}>{partner.name} has no Active Merchants, which is a bummer</EmptyBox>) : null;
    
    let newEnrollments = subMerchants.filter((x, index) => x.enrollmentStatus === 0);
    let newEnrollmentElements = newEnrollments.map((m) => {
        return (
            <tr key={'new-enrollment-' + m.id}>
                <td className="name"><Link to={'/partners/' + partnerId + '/merchants/' + m.id}>{m.trimmedName(32)}</Link></td>
                <td className="date"><ToolTip value={m.created.toDateTime()}><DateTime time={true} showRelative={true} value={m.created} /></ToolTip> </td>
                <td className="check"><EmailAddress value={partner.email} defaultValue={'No Email'} /></td>
                <td><ActionButton onClick={approveEnrollmentAsync.bind(this, m.id)}>Approve</ActionButton></td>
            </tr>
        );
    });

    let settingsLink = (<>You can change this value in the <Link className={"light"} to={"/settings/automation#back"}>Settings <FontAwesomeIcon icon={faCaretRight} /> Automation</Link> Section</>);
    
    let newEnrollmentElementsPanel = (<></>);
    let newEnrollmentsMessage = (<>When an enrollment is created, it will be in a pending state for roughly 24 hours. {partner.name} has no current pending enrollments.</>);
    
    if (newEnrollments.length > 0) {
        newEnrollmentsMessage =  (<>A system job runs every hour to check for pending enrollments and update the status accordingly. {settingsLink}</>);
        newEnrollmentElementsPanel = (<div className="new-enrollments">
            <h3><FontAwesomeIcon bounce={true} icon={faFaceSmile} /> {newEnrollments.length.formatNumber(0)} New Enrollments</h3>
            <table className="table-large" width="100%">
                <thead>
                <tr>
                    <th className="name">Submerchant</th>
                    <th className="date">Enrollment Date</th>
                    <th className="check">Notify</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {newEnrollmentElements}
                </tbody>
            </table>
        </div>);
    }
    
    return (
        <MasterScreenMain selection="partners">
            <div className="pad" id="partner-enrollments">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    {partner.name}
                </h1>

                <div className="details-body">
                    <PartnerSubMenu partner={partner} selection="enrollments" />

                    <div className="pad">
                        <h2>
                            Submerchants
                            <RefreshButton onClick={getMerchantsAsync} />
                        </h2>
                        
                        <div className={"right"}>
                            <div className="excel-small">
                                <a onClick={generateExcel.bind(this, 4)}>
                                    <span><FontAwesomeIcon icon={faFileCsv} size="2x" /></span>
                                    <span>Visa Excel File *.xlsx</span>
                                    <span className={downloadingVisaClassName + " loading-icon spinning"}><label></label></span>
                                </a>
                            </div>
                            <div className="excel-small">
                                <a onClick={generateExcel.bind(this, 5)}>
                                    <span><FontAwesomeIcon icon={faFileCsv} size="2x" /></span>
                                    <span>MasterCard Excel File .xlsx</span>
                                    <span className={downloadingMasterCardClassName + " loading-icon spinning"}><label></label></span>
                                </a>
                            </div>

                        </div>
                        <p>
                            {newEnrollmentsMessage}
                        </p>

                        {newEnrollmentElementsPanel}

                        <h3><FontAwesomeIcon icon={faPeopleGroup} /> Approved Submerchants</h3>
                        <div className={"small-p large"}>The submerchants below have been approved after the 24 hour grace period. They are currently pending FiServ registration. Once completed, these merchants will be "Enrolled"</div>

                        <table className="table-large" width="100%" id="submerchant-table">
                            <thead>
                            <tr>
                                <th className="name">Submerchant</th>
                                <th className="mid">MID</th>
                                <th>Start Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {enrollmentElements}
                            </tbody>
                        </table>

                        <Pager id="partner-pager-bottom" controller={pageController} items={activeMerchants} />
                        {emptyBox}
                        
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
};

export default PartnerEnrollmentsScreen;