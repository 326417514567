import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import UserModel from "@paylani/paylani-react-packages/dist/people/models/UserModel";
import UserSessionModel from "../models/UserSessionModel";

class UserService {
    static instance = new UserService();
    
    constructor() {
        this.users = [];
        this.userMap = {};
    }

    async getUsersAsync() {
        const path = '/api/user';
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.users = UserModel.fromJsonArray(response.data);
            me.users.map((user) => me.userMap[user.id] = user);

            return me.users;
        });
    }

    async updateUserAsync(jsonData, userId) {
        const path = '/api/user/' + userId;
        const me = this;

        return await HttpService.instance.postAsync(path, jsonData).then((response) => {
            const u = UserModel.create(response.data);
            if (!!u?.id) { 
                me.userMap[u.id] = u;
                return u;
            }
            
            return null;
        });
    }
    
    async getUserAsync(userId) {
        const path = '/api/user/' + userId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            let user = UserModel.create(response.data);

            if (!!user.id) me.userMap[user.id] = user;

            return user;
        });
    }

    async getUserSessionsAsync(userId, startDate, endDate) {
        const path = '/api/user/' + userId + '/session';
        const me = this;

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((response) => {
            return UserSessionModel.fromJsonArray(response?.data);
        });
    }
    
    async getActivityAsync(userId) {
        const path = '/api/user/' + userId + '/activity';
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            return response.data;
        });
    };
    
    async sendPasswordResetLinkAsync(userId) { 
        const path = '/api/user/' + userId + '/reset';
        const me = this;
        let data = {};

        return await HttpService.instance.postAsync(path).then((response) => {
            return response;
        });
    }

    async deleteSessionsAsync(userId) {
        const path = '/api/user/' + userId + '/session';
        const me = this;

        return await HttpService.instance.deleteAsync(path).then((response) => {
            return response;
        });
    }
}

export default UserService;
