import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import SearchResultModel from "../models/SearchResultModel";

class SearchService { 
    static instance = new SearchService();
    
    async searchAsync(term) { 
        if (!term) return null;
        const path = "/api/search?term=" + encodeURI(term);
        
        return await HttpService.instance.getAsync(path).then((rsp) => { 
            return SearchResultModel.fromJsonArray(rsp.data);
        })
    }
}

export default SearchService;
