import CreditCardModel from "../../payments/models/CreditCardModel";

class BatchResultModel { 
    static unBillableColor = 'rgba(255, 255, 255, 0.5)';
    
    static resultTypes = [
        { id: -2, key: 'unsubscribed', name: 'Unsubscribed', isBillable: false, color: BatchResultModel.unBillableColor },
        { id: -1, key: 'error', name: 'Error', isBillable: false, color: 'red' },
        { id: 0, key: 'pending', name: 'Pending', isBillable: false, color: BatchResultModel.unBillableColor },
        { id: 1, key: 'no_change', name: 'No Change', isBillable: false, color: BatchResultModel.unBillableColor },
        { id: 2, key: 'card_number', name: 'Card Number Updated', isBillable: true, color: '#FFFF0088' },
        { id: 3, key: 'expiry', name: 'Expiration Updated', isBillable: true, color: '#AAFEA5AA' },   // --header-green
        { id: 4, key: 'valid', name: 'Valid', isBillable: true, color: 'white' },
        { id: 5, key: 'contact', name: 'Contact', isBillable: true, color: 'rgba(255, 100, 100, 0.6)' },
        { id: 6, key: 'closed_contact', name: 'Closed Contact', isBillable: true, color: '#FFF7EDAA' },
        { id: 7, key: 'no_match', name: 'No Match', isBillable: false, color: BatchResultModel.unBillableColor },
    ];
    
    static getResultTypeNames = () => BatchResultModel.resultTypes.map((item) => item.name);
    static getResultTypeKeys = () => BatchResultModel.resultTypes.map((item) => item.key);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        this.name = json.name;
        this.batchId = json.batch_id || null;
        this.txnId = json.txn_id || '';
        this.customerKey = json.customer_key || '';
        this.orderKey = json.order_key || '';
        this.cardType = json.card_type;
        this.cardMask = json.card_mask;
        this.expirationDate = json.exp_date;
        this.resultType = json.result_type;
        this.resultTypeName = json.result_type_name || 'Pending';
        this.message = json.message;
        this.eventId = json.event_id;
        this.newCardMask = json.new_card_mask;
        this.newExpDate = json.new_exp_date;
        this.created = json.created;
        this.modified = json.modified;
    }

    static fromJsonArray(jsonArray) {
        return jsonArray.map((json) => new BatchResultModel(json));
    }
}

export default BatchResultModel;
