import ReportItemModel from "./ReportItemModel";
import CreditCardModel from "../../payments/models/CreditCardModel";

class CardBrandTrendSeriesModel {
    static updatesReportKey = 'card_brand_trends';
    static cardBrandResultsReportKey = 'card_brand_results';
    
    constructor(json) {
        if (!json) json = {};
        
        this.visa = ReportItemModel.fromJsonArray(json.visa);
        this.mastercard = ReportItemModel.fromJsonArray(json.mastercard);
        this.discover = ReportItemModel.fromJsonArray(json.discover);
        this.amex = ReportItemModel.fromJsonArray(json.amex);
        
        this.startDate = json.start_date || null;
        this.endDate = json.end_date || null;
        this.name = json.name || 'Card Brand Trends';
        this.dataMethod = json.data_method || '';
        this.serviceMethod = json.service_method || '';
        this.isEmpty = json.is_empty === true;
    }
    
    hasStartDate(targetStartDate) { 
        const format = "yyyy-MM-dd";
        return this.startDate?.formatDate(format) === targetStartDate?.formatDate(format);
    }
    
    toLineChartDataSet(borderWidth, pointRadius) {
        let labels = this.visa?.map((item) => item.label) ?? [];
        
        return {
            labels: labels, // ['VISA', 'MasterCard', 'Discover', 'Amex'],
            datasets: [
                this.toLineChartSeries('visa', borderWidth, pointRadius),
                this.toLineChartSeries('mastercard', borderWidth, pointRadius),
                this.toLineChartSeries('discover', borderWidth, pointRadius),
                this.toLineChartSeries('amex', borderWidth, pointRadius)
            ]
        };
    }
    
    toLineChartSeries(cardKey, borderWidth, pointRadius) {
        if (typeof cardKey !== 'string' || !CreditCardModel.cardKeys.includes(cardKey)) cardKey = 'visa';
        
        if (typeof borderWidth !== 'number') borderWidth = 1;
        if (typeof pointRadius !== 'number') pointRadius = 1.5;

        let items = this[cardKey];
        if (!items) return {};
        
        let card = CreditCardModel.cardDescriptors[cardKey];
        let color = card?.color || 'rgba(255, 255, 255, 0.8)';

        return ReportItemModel.createLineChartSeries(items, card?.name || cardKey, color, borderWidth, pointRadius);
    }
}

export default CardBrandTrendSeriesModel;
