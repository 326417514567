import WebhookModel from "../../webhooks/models/WebhookModel";

class PartnerWebhookModel { 
    static json = {
        "webhook": {
            "name": "CardSync Batch Available",
            "description": null,
            "method": "POST",
            "example_body": null,
            "sorting": 20,
            "webhook_type": 20,
            "id": "c4487c83-1e0b-4aa1-8d5d-9d9815b9ec92",
            "created": "2021-12-06T20:27:14.413",
            "modified": null
        },
        "url": "https://webhook.site/cc4062c4-733a-4054-8517-c65d72a74b93",
        "id": "a062b627-b23c-446a-80a9-9fe498961ff8",
        "created": "2021-12-06T20:29:19.877",
        "modified": "2022-07-14T19:42:24.84"
    };
    
    constructor(json) {
        if (!json) json = {};
        
        this.webhook = new WebhookModel(json.webhook);
        
        this.url = json.url;
        this.id = json.id;
        this.created = json.created;
        this.modified = json.modified;
        this.webhookId = json.webhook?.id || null;
    }
    
    static fromJsonArray(jsonArray) {
        let list = [];
        
        for(let i = 0; i < jsonArray.length; i++) {
            let wl = new PartnerWebhookModel(jsonArray[i]);
            if (!!wl.id) {
                list.push(wl);
            }
        }
        
        return list;
    }
}

export default PartnerWebhookModel;
