import Tag from "./Tag";

class PlatformMessageModel { 
    static getMockMessages() {
        return [
            {
                "id": '12345-6789',
                "name": "Welcome Message and Instructions",
                "subject": "Welcome to CardSync",
                "body": "This is a test message.",
                "created": new Date().addMinutes(-10),
                "modified": null,
                "message_type": "Email",
                "tags": [
                    {name: 'partner-id', attributes: [], default_value: '4321-0000-2222-1111'},
                    {name: 'partner-name', attributes: [], default_value: 'Cyberdyne Systems'},
                    {name: 'partner-address', attributes: [], default_value: '123 Main St.'},
                    {name: 'partner-email', attributes: [], default_value: 'partners@cyberdyne.io'},
                    {name: 'partner-phone', attributes: [], default_value: '(800) 212-0212'}
                ]
            },
            {
                "id": '23456-78901',
                "name": "New CardSync Enrollment Approved",
                "subject": "New CardSync Enrollment Approved",
                "body": "This is a test message.",
                "created": "2022-06-21T00:00:00.000Z",
                "modified": "2022-06-22T00:00:00.000Z",
                "message_type": "Email",
                "tags": [
                    {name: 'marchant-name', attributes: []},
                    {name: 'marchant-address', attributes: []},
                    {name: 'marchant-email', attributes: []},
                    {name: 'marchant-phone', attributes: []}
                ]
            },
            {
                "id": '321-0098-00001',
                "name": "API Key Changed",
                "subject": "Your Api Key Has Been Changed",
                "body": "This is a test message.",
                "created": "2021-03-13T00:00:00.000Z",
                "modified": "2021-03-15T00:00:00.000Z",
                "message_type": "Email",
                "tags": []
            },
            {
                "id": '444-222-3333',
                "name": "Monthly Billing and Usage Statement",
                "subject": "CardSync Billing Statement MM/YY",
                "body": "This is a test message.",
                "created": "2022-06-01T00:00:00.000Z",
                "modified": "2022-01-01T00:00:00.000Z",
                "message_type": "Email",
                "tags": []
            },
            {
                "id": '111-222-333',
                "name": "Admin: FiServe Enrollment XLSX",
                "subject": "New CardSync Enrollment Submission",
                "body": "This is a test message.",
                "created": "2022-01-05T00:00:00.000Z",
                "modified": null,
                "message_type": "Email",
                "tags": []
            },
            {
                "id": '44-332-222-2-2',
                "name": "Multi-Factor Authentication",
                "subject": "Your Authorization Code is...",
                "body": "This is a test message.",
                "created": "2022-01-05T00:00:00.000Z",
                "modified": null,
                "message_type": "Sms",
                "tags": []
            }
        ];
    }
    
    constructor(message) {
        this.id = message.id;
        this.name = message.name;
        this.subject = message.subject;
        this.body = message.body;
        this.created = message.created.toDate();
        this.modified = message.modified;
        this.messageType = message.message_type;
        this.tags = message.tags.map((t) => new Tag(t));
    }
}
export default PlatformMessageModel;
