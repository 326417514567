import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressBook, faAddressCard, faAlignLeft, faBatteryHalf,
    faComments,
    faDiagramProject,
    faGears, faPersonWalking,
    faRobot,
    faSuitcase, faUnlockKeyhole
} from "@fortawesome/free-solid-svg-icons";

const UserSubMenu = (props) => {
    let selections = {};
    let selector = props.selection || 'details';
    let user = props.user;
    let userId = user.id;
    
    selections[selector] = 'selected';

    const path = "/people/" + userId;
    
    let profileImageElement = !!user.imageUrl ?
        (<img src={user.imageUrl} alt="" />) :
        (<span className="image"><FontAwesomeIcon icon={faPersonWalking} /></span>);

    return (
        <div className="sub-column" id="user-submenu">
            <div className="image profile">
                <div id={"user-profile-image"}>{ profileImageElement }</div>
            </div>

            <ul>
                <li className="sub-menu-header">
                    More
                </li>
                <li className={selections['details']}><Link to={path + ""}><FontAwesomeIcon icon={faAddressCard} /> User Details</Link></li>
                <li className={selections['access']}><Link to={path + "/access"}><FontAwesomeIcon icon={faUnlockKeyhole} /> Accessibility</Link></li>
                <li className={selections['activity']}><Link to={path + "/activity"}><FontAwesomeIcon icon={faAlignLeft} /> Activity History</Link></li>
                <li className={selections['sessions']}><Link to={path + "/sessions"}><FontAwesomeIcon icon={faBatteryHalf} /> Session History</Link></li>
            </ul>
        </div>
    );
};

export default UserSubMenu;
