import React from 'react';
import NumberDisplay from "@paylani/paylani-react-packages/dist/common/formatting/NumberDisplay";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquarePollVertical } from "@fortawesome/free-solid-svg-icons";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import {Link} from "react-router-dom";
import CreditCardBrandPanel from "../../../payments/ui/CreditCardBrandPanel";

const BatchResultsActivity = (props) => {
    const { batch, partner, onClick } = props;

    let merchantPath = '/partners/' + partner.id + '/merchants/' + batch.subMerchantId;
    let path = merchantPath + '/batch/' + batch.batchId;
    
    const updateCount = batch.updateCount || 0;
    const percent = updateCount > 0 ? ((updateCount / batch.submittedCount) * 100).toFixed(1) : 0.0;

    const onClickHandler = (e) => {
        if (typeof onClick === 'function') onClick();
        else console.warn('No onClick handler provided for BatchSubmissionActivity: ' + batch.batchId);
    };
    
    const fieldKey = batch.batchStatus === 2 ? "Count" : "Submitted";
    
    return (
        <div className="partner-activity batch-submission results">
            <div className="batch-submission-total-count">
                <NumberDisplay value={percent} path={path} />%
                <label><NumberDisplay decimalPlaces={0} value={updateCount} /> Of <NumberDisplay value={batch.submittedCount} /> Updates</label>
            </div>
            <h4>
                <FontAwesomeIcon icon={faSquarePollVertical} />
                <Link to={path}>Batch Card Updates {batch.batchId}</Link>
                <span className="batch-date" onClick={onClickHandler}><DateTime value={(batch.modified || batch.created).toString()} time={true} /></span>
                <label>Submerchant: <Link to={merchantPath}>{batch.subMerchant?.name}</Link></label>
            </h4>
            <CreditCardBrandPanel batch={batch} />
        </div>
    );
};

export default BatchResultsActivity;