import React from 'react';
import { faCheckToSlot, faCheckDouble, faClock } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import NumberDisplay from "@paylani/paylani-react-packages/dist/common/formatting/NumberDisplay";
import {Link} from "react-router-dom";
import CreditCardBrandPanel from "../../../payments/ui/CreditCardBrandPanel";

const BatchSubmissionActivity = (props) => {
    const {batch, partner, onClick} = props;

    let statusElement = (<FontAwesomeIcon icon={faClock} />);
    if (batch.status === 1) statusElement = (<FontAwesomeIcon icon={faCheckDouble} />);

    let merchantPath = '/partners/' + partner.id + '/merchants/' + batch.subMerchantId;
    let path = merchantPath + '/batch/' + batch.batchId;

    let batchStatusName = batch.batchStatusName;
    if (!!batchStatusName) batchStatusName = batchStatusName.toLowerCase();
    else batchStatusName = 'unknown';
    
    const onClickHandler = (e) => {
        if (typeof onClick === 'function') onClick();
        else console.warn('No onClick handler provided for BatchSubmissionActivity: ' + batch.batchId);
    };

    const fieldKey = batch.batchStatus === 2 ? "Count" : "Submitted";
    
    return (
        <div className={'partner-activity batch-submission ' + batchStatusName}>
            <div className="batch-submission-total-count">
                <NumberDisplay value={batch.submittedCount} path={path} />
                <label>Submitted</label>
            </div>
            <h4>
                <FontAwesomeIcon icon={faCheckToSlot} />
                <Link to={path}>Submitted Batch {batch.batchId}</Link>
                <span className="batch-date" onClick={onClickHandler}><DateTime value={batch.created} time={true} /></span>
                <span className="batch-status-element">{statusElement}</span>
                <label>Submerchant: <Link to={merchantPath}>{batch.subMerchant?.name}</Link></label>
            </h4>
            <CreditCardBrandPanel batch={batch} />
    
        </div>
    );
};

export default BatchSubmissionActivity;
