import React from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGears} from "@fortawesome/free-solid-svg-icons";
import SettingsSubMenu from "../../components/settings/ui/SettingsSubMenu";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";

const ComplianceScreen = (props) => {
    const pciFolderUrl = "https://drive.google.com/drive/u/1/folders/16Wo7GBOPoMc20A_vyxTSiFZyi3SUJ8xv";
    
    return (
        <MasterScreenMain selection="settings">
            <div className="pad">
                <h1><FontAwesomeIcon icon={faGears} /> Settings</h1>

                <div className="details-body">
                    <SettingsSubMenu selection="pci" />

                    <div className="pad">
                        <h2>Compliance</h2>

                        <p>
                            All documents are stored in the Paylani Google Drive, shared on a company level.<br/>
                            <a href={pciFolderUrl}>Click here</a> to go directly to the PCI Documents folder
                        </p>
                        
                        <ul className={"action-options"}>
                            <li>
                                <h4>PCI Documentation</h4>
                                <p>
                                    The outline below should help you get started and on track with PCI documentation.<br/>
                                    <a href="https://www.pcisecuritystandards.org/document_library/?category=pcidss&document=pci_dss" target="_blank">PCI DSS Official Website Documents</a>
                                    <></> &nbsp; | &nbsp; <a href={"https://drive.google.com/drive/u/1/folders/16Wo7GBOPoMc20A_vyxTSiFZyi3SUJ8xv"} target="_blank">CardSync PCI 2022 Documents Folder</a>
                                    <br/><br/>

                                    <strong className={"h4"}>Schedule of Milestones</strong>
                                    <ol>
                                        <li>
                                            <strong>Kick-Off Call</strong> - Aug 10, 11:00am - 12:00pm Pacific<br/>
                                            Call to discuss deliverables and requirements. All materials due in one week
                                        </li>
                                        <li>
                                            <strong>Deliver Materials to Auditor (Due)</strong> - Aug 17, 11:00am<br/>
                                            All materials due. Delays may cause additional charges and fees
                                        </li>
                                        <li>
                                            <strong>Audit Begins (No later than)</strong> - Sept 1, 11:00am<br/>
                                            Demonstrate the security of the environment and the platform.
                                        </li>

                                        <li>
                                            <strong>ROC Delivery (Hard deadline)</strong> - Sept 29, 11:59pm<br/>
                                            Demonstrate the security of the environment and the platform.
                                        </li>
                                    </ol>
                                </p>
                            </li>
                            <li>
                                <h4>Security Policy</h4>
                                <p>
                                    Policy describing how passwords and other security measures are to be implemented and enforced<br/>
                                    <a href="https://docs.google.com/document/u/1/d/1OhWd2LzEIZjfWFPnvyUmXFDMkyyaLcfo" target="_blank">Security Policy - 2022</a> (.docx)
                                </p>
                            </li>
                            <li>
                                <h4>Operating Procedures</h4>
                                <p>
                                    Company wide operating procedures relating to behavior within the CardSync environments and platform<br/>
                                    <a href="https://docs.google.com/document/d/1W6IYqeCeaOAGAKvFcWNVphPOUaq34jk6/" target="_blank">Operating Procedures - 2022</a> (.docx)
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );

};

export default ComplianceScreen;
