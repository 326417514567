import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import WebhookModel from "../models/WebhookModel";
import WebhookLogModel from "../models/WebhookLogModel";
import PartnerWebhookModel from "../../partners/models/PartnerWebhookModel";

class WebhookService { 
    static instance = new WebhookService();
    
    constructor() {
        this.webhookMap = {};
        this.webhooks = [];
        
        this.getWebhooksAsync().then((webhooks) => {}).catch((err) => {
            console.error('Failed to get webhooks: ' + err);
            return null;
        });
    }
    
    async getWebhooksAsync(force) {
        if (!force && this.webhooks.length > 0) return this.webhooks;
        
        const me = this;
        const url = '/api/webhook';

        return await HttpService.instance.getAsync(url).then((rsp) => me.createWebhooksList(rsp?.data));
    }

    async getWebhookLogsAsync(startDate, endDate) {
        const me = this;
        const url = '/api/webhooklog?start-date=' + startDate + '&end-date=' + endDate;

        return await HttpService.instance.getAsync(url).then((rsp) => WebhookLogModel.fromJsonArray(rsp.data));
    }

    async repostWebhookBatchAsync(batchId) {
        if (typeof batchId === 'undefined' || typeof batchId !== 'string' || batchId === null) {
            console.error('Failed to post Batch Webhook because batchId is ' + (typeof batchId).toString());
            return;
        }

        let me = this;
        let url = '/api/batch/' + batchId + '/webhook';
        
        return await HttpService.instance.postAsync(url, {}).then((rsp) => { 
            return new WebhookLogModel(rsp.data);
        });
    }

    async repostWebhookEnrollmentAsync(subMerchantId) {
        if (typeof subMerchantId === 'undefined' || typeof subMerchantId !== 'string') {
            console.error('Failed to post Batch Webhook because batchId is ' + (typeof subMerchantId).toString());
            return;
        }

        let me = this;
        let url = '/api/enrollment/' + subMerchantId + '/webhook';

        return await HttpService.instance.postAsync(url, {}).then((rsp) => {
            return new WebhookLogModel(rsp.data);
        });
    }

    async repostWebhookLogAsync(webhookLogId) {
        if (typeof webhookLogId === 'undefined' || typeof webhookLogId !== 'string') {
            throw 'Failed to post Webhook Log because the webhookLog parameter is ' + (typeof webhookLogId).toString();
        }

        let me = this;
        let url = '/api/webhooklog/' + webhookLogId;

        return await HttpService.instance.postAsync(url, {}).then((rsp) => {
            return new WebhookLogModel(rsp.data);
        });
    }
    
    createWebhooksList(jsonArray) {
        if (!jsonArray) return this.webhooks;
        if (!Array.isArray(jsonArray)) return this.webhooks;
        
        this.webhooks = [];
        
        for(let i = 0; i < jsonArray.length; i++) { 
            let w = new WebhookModel(jsonArray[i]);
            if (!!w.id) {
                this.webhooks.push(w);
                this.webhookMap[w.id] = w;
            }
        }
        
        return this.webhooks;
    }
}

export default WebhookService;
