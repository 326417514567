import PlanTierModel from "./PlanTierModel";

class PlanModel { 
    static create = (json) => new PlanModel(json);
    constructor(json) {
        if (typeof json === 'undefined' || json === null) json = {};
            
        this.id = json?.id || '';
        this.name = json?.name || '';
        this.description = json?.description || '';
        this.mrc = json?.monthly_fee || 0;
        this.updateFee = json?.transaction_fee || 0;
        this.planType = json?.plan_type;
        this.setupFee = json?.setup_fee;
        this.minimumAmount = json?.minimum_amount;
        this.merchantPooling = json?.merchant_pooling || false;
        
        this.status = json?.status || 0;
        let tiers = PlanTierModel.fromJsonArray(json?.tiers || []);
        
        if ((this.id.toLowerCase() === "E7B44660-8482-4409-88ED-8CE15A35ADBF".toLowerCase() || this.id === "68c2a68c-3639-4f39-8730-8573af529002") && tiers.length === 0) { 
            const ja = [
                { id: "001-0002-0003", item_count: 1000, price: 0.25, plan_id: this.id, tier_type: 0 },
                { id: "002-0002-0003", item_count: 5000, price: 0.20, plan_id: this.id, tier_type: 0 },
                { id: "003-0002-0003", item_count: 50000, price: 0.17, plan_id: this.id, tier_type: 1 },
            ];
            
            tiers = PlanTierModel.fromJsonArray(ja);
        }
        
        this.mrcTiers = tiers.filter((t) => t.tierType === 0);
        this.transactionTiers = tiers.filter((t) => t.tierType === 1);
        
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
        
        if (typeof this.planType === 'undefined' || this.planType === null)
            this.planType = 1;
    }
    
    toJson() {
        return {
            name: this.name,
            description: this.description,
            monthly_fee: this.mrc,
            transaction_fee: this.updateFee,
            minimum_amount: this.minimumAmount,
            setup_fee: this.setupFee,
            mrc_tiers: this.tiers?.map((x) => x.toJson()) || [],
            transaction_tiers: [],
        };
    };
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map((json) => PlanModel.create(json))
    }
}

export default PlanModel;
