class PartnerActivityStatsModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.partnerId = json.partner_id || '';
        this.partnerName = json.partner_name || '';
        this.subMerchantCount = json.sub_merchant_count || 0;
        this.batchCount = json.batch_count || 0;
        this.submissionCount = json.submission_count || 0;
        this.updateCount = json.update_count || 0;
        
        this.updateRate = this.submissionCount !== 0 ? this.updateCount / this.submissionCount : 0;
    }
    
    static fromJsonArray(jsonArray) {
        let models = [];
        
        for (let json of jsonArray) {
            let sd = json.start_date?.toDate();
            let ed = json.end_date?.toDate();
            
            models.push(new PartnerActivityStatsModel(json));
        }
        
        return models;
    }
}

export default PartnerActivityStatsModel;
