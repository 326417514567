import React, {useEffect, useState} from 'react';
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";

const MerchantForm = (props) => {
    const { merchant, onFormSubmit } = props;
    const [payload, setPayload] = useState(merchant?.toJson() || {});
    const [newPoolState, setNewPoolState] = useState(false);
    
    const validateForm = (e) => {
        console.log('Validated form: ' + e.toString());
    };

    const handleChange = (fieldId, e) => {
        let value = e.target.value;
        setPayload((values => ({...values, [fieldId]: value})));

        if (typeof props.onChange === 'function') {
            props.onChange(payload);
        }
    };

    const onSubmit = (e) => {
        validateForm(e);
        onFormSubmit(e, payload);
    };
    
    const setNewPool = (e) => {
        if (e.target.value === "+") setNewPoolState(true);
        else setNewPoolState(false);
    };
    
    useEffect(() => {
        if (newPoolState) { 
            setTimeout(() => {
                //
            }, 100);
        }
        
    }, [newPoolState]);

    const hasPooling = true; // merchant?.partner?.plan?.merchantPooling === true;
    
    const poolDropdownElement = hasPooling ?
        (<div className="form-group">
            <label htmlFor="merchant-pooling">Assign to Pool</label>
            <select defaultValue={""} onChange={setNewPool}>
                <option value={"0"}>None</option>
                <option value={"000000001"}>Blue Pool: 30 Merchant Cap</option>
                <option value={"000000002"}>Green Pool: 50 Merchant Cap</option>
                <option value={"+"}>+ Add New</option>
            </select>
        </div>) :
        null;

    const addNewElement = newPoolState ? (<div className="form-group">
        <label htmlFor="merchant-new-pool">New Pool Name</label>
        <input type="text" id="merchant-new-pool" />
    </div>) : null;

    let submitElement = props.useButton ? (
        <div className="button">
            <FormButton id="merchant-update-button" onClick={onSubmit} label="Update Merchant Details" />
        </div>
    ) : (<></>);

    return (<div className="form">
        { poolDropdownElement }
        { addNewElement }
        <div className="form-group">
            <label htmlFor="merchant-name">Legal Entity Name</label>
            <input type="text" id="merchant-name" value={payload.name} onChange={handleChange.bind(this, "name")} />
        </div>

        <div className="form-group">
            <label htmlFor="merchant-email">Email</label>
            <input type="text" id="merchant-email" value={payload.email} onChange={handleChange.bind(this, "email")} />
        </div>

        <div className="form-group">
            <label htmlFor="merchant-phone">Phone</label>
            <input type="text" id="merchant-phone" value={payload.phone} onChange={handleChange.bind(this, "phone")} />
        </div>

        <div className="form-group">
            <label htmlFor="merchant-website">Website</label>
            <input type="text" id="merchant-website" value={payload.website} onChange={handleChange.bind(this, "website")} />
        </div>

        {submitElement}
    </div>);
};

export default MerchantForm;
