import React, {useEffect, useState, useRef} from 'react';
import {useParams} from "react-router-dom";
import UserService from "../../components/people/services/UserService";
import UserModel from "@paylani/paylani-react-packages/dist/people/models/UserModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAnchorCircleCheck, faCircleCheck,
    faClipboardCheck,
    faGear,
    faPersonWalking, faQuestion,
    faSkull, faSync
} from "@fortawesome/free-solid-svg-icons";
import MasterScreenMain from "../../MasterScreenMain";
import UserSubMenu from "../../components/people/ui/UserSubMenu";
import BackButton from "../../components/common/ui/BackButton";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";
import PhoneNumber from "@paylani/paylani-react-packages/dist/common/formatting/PhoneNumber";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import PagerController from "../../components/common/ui/pager/PagerController";
import Pager from "../../components/common/ui/pager/Pager";

const UserActivityHistoryScreen = (props) => {
    const { userId } = useParams();
    let [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    let [activityHistory, setActivityHistory] = useState([]);
    let [currentPage, setCurrentPage] = useState(0);
    let pageController = useRef(new PagerController(setCurrentPage, 24)).current;

    let _;
    
    const getUserAsync = async (e) => {
        UserService.instance.getUserAsync(userId).then((user) => {
            setUser(user);
        });
    };
    
    const getActivityAsync = async (e) => {
        return await  UserService.instance.getActivityAsync(userId).then((activityHistory) => {
            console.log('Got: ' + userId);
            setActivityHistory(activityHistory);  
        });
    };
    
    const refreshUser = () => {
        if (!user?.id) _ = getUserAsync();
        _ = getActivityAsync();
    };

    useEffect(() => {
        refreshUser();
    }, []);

    const d = (new Date()).addMinutes(-10);
    
    
    const getActivityIcons = () => {
        return {
            '0': {icon: faCircleCheck, className: 'x'},
            'SYNC': {icon: faSync, className: 'activity-sync'},
            '1': {icon: faQuestion, className: 'x'},
            'SENT': {icon: faCircleCheck, className: 'activity-sent'},
            'SENT-': {icon: faAnchorCircleCheck, className: 'x'},
            '4': {icon: faClipboardCheck, className: 'x'},
            '5': {icon: faSkull, className: 'x'},
            '6': {icon: faGear, className: 'x'},
        }
    };
    
    const getIcon = (activity) => {
        let activityIcons = getActivityIcons();
        let icon = activityIcons[activity.action + '-' + activity.status];
        
        if (!icon) icon = activityIcons[activity.action];
        if (!icon) icon = activityIcons['0'];
        
        if (activity.severity === 2) icon.className += ' activity-warning';
        if (activity.severity === 3) icon.className += ' activity-error';
        if (activity.severity > 3) icon.className += ' activity-critical';
        
        return icon;
    };
    
    let activityElements = pageController.mapLineItems(activityHistory, (activity) => {
        let display = getIcon(activity);
        let icon = display?.icon;
        let color = display?.color || 'rgba(255, 255, 255, 0.6)';
        
        console.log(display.className);
        
        return (
            <tr key={activity.id} className={display.className}>
                <td className={"activity-text-td"}><span className={"icon"}><FontAwesomeIcon icon={icon} /></span> {activity.text}</td>
                <td className={"activity-date-td"}><DateTime value={activity.created.toDate()} time={true} /></td>
            </tr>
        );
    });

    const userSubMenu = !!user?.id ? (<UserSubMenu user={user} selection={"activity"} />) : null;
    
    return (
        <MasterScreenMain selection="users">
            <div className="pad">
                <h1>
                    <FontAwesomeIcon icon={faPersonWalking} />
                    Users
                </h1>

                <div className="details-body">
                    { userSubMenu }
                    <div className="pad">
                        <h2>
                            <BackButton path="/people" />
                            { user?.name }
                            <span className="subtitle">Activity History</span>
                            <RefreshButton onRefresh={refreshUser} />
                        </h2>

                        <p>
                            Below is a list of all the activities {user?.name} has engaged in on the platform
                        </p>
                        
                        <div id="user-activity-body">
                            <table className={"table-x-large"} width={"100%"}>
                                <thead>
                                    <tr>
                                        <th className={"activity-text-td"}>Activity</th>
                                        <th className={"activity-date-td"}>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {activityElements}
                                </tbody>
                            </table>
                            
                            <Pager controller={pageController} items={activityHistory} />
                        </div>
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );

};

export default UserActivityHistoryScreen;
