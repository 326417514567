import React, {useRef, useEffect, useState} from 'react';
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";

const PartnerForm = (props) => {
    const { controller, methodKey } = props;
    const [payload, setPayload] = useState(props.partner?.toJson() || {});
    const [errors, setErrors] = useState({});
    const onFormSubmit = props.onSubmit;

    const nameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const websiteRef = useRef();
    
    const validateForm = (data) => {
        const er = {};
        
        if (!data) data = {};
        if (!data.name) er.name = "Name is required";
        if (!data.email) er.email = "Email is required";

        return er;
    };

    const createJson = () => {
        return {
            name: nameRef.current?.value,
            email: emailRef.current?.value,
            phone: phoneRef.current?.value,
            website: websiteRef.current?.value,
        };
    }
    
    const handleError = (ex) => {
        let message = ex.response?.data?.message || ("Error: " + ex.message);
        setErrors({ general: message });
    }
    
    const onSubmitAsync = async (e) => {
        const data = createJson();
        
        const er = validateForm(data);
        setErrors(er);
        
        if (Object.keys(er).length > 0) {
            return;
        }
        
        if (typeof onFormSubmit !== 'function') return;
        
        const rsp = onFormSubmit(data);
        const result = typeof rsp.then === "function" ? 
            await rsp.then((r) => r).catch((ex) => handleError(ex)) :
            rsp;
    };
    
    useEffect(() => { 
        if (!!controller?.formGetters) controller.formGetters[methodKey || "partner"] = () => createJson();
    }, []);
    
    let submitElement = props.useButton ? (
        <div className="button">
            <div className={"form-error"}>{errors.general}</div>
            <FormButton id="address-update-button" onClick={onSubmitAsync} label="Update Account Details" />
        </div>
    ) : (<></>);

    return (
        <div className="form">
            <div className="form-group">
                <label htmlFor="name">Company Name</label>
                <input type="text" id="name" defaultValue={payload.name} ref={nameRef} />
                <div className={"form-error"}>{errors.name}</div>
            </div>

            <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="text" id="email" defaultValue={payload.email} ref={emailRef}/>
                <div className={"form-error"}>{errors.email}</div>
            </div>

            <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input type="tel" id="phone" defaultValue={payload.phone} ref={phoneRef} />
                <div className={"form-error"}>{errors.phone}</div>
            </div>
            
            <div className="form-group">
                <label htmlFor="partner-website">Website</label>
                <input type="text" id="website" defaultValue={payload.website} ref={websiteRef} />
                <div className={"form-error"}>{errors.website}</div>
            </div>

            {submitElement}
        </div>
    );
};

export default PartnerForm;