import React, {useEffect, useState, useRef} from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import PhoneNumber from "@paylani/paylani-react-packages/dist/common/formatting/PhoneNumber";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import {faAddressCard} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Pager from "../../components/common/ui/pager/Pager";
import {Link} from "react-router-dom";

import PartnerService from "../../components/partners/services/PartnerService";
import PagerController from "../../components/common/ui/pager/PagerController";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";

const PartnersScreen = (props) => {
    let [partners, setPartners] = useState(PartnerService.instance.partners || []);
    let [batchActivity, setBatchActivity] = useState(PartnerService.instance.batchActivity || []);
    let _;

    const [currentPage, setCurrentPage] = useState(0);
    const pageController = useState(new PagerController(setCurrentPage))[0];
    
    const getPartnersAsync = async (force = false) => {
        if (Array.isArray(partners) && partners.length > 0 && !force)
            return Promise.resolve(partners);
        
        return await PartnerService.instance.getPartnersAsync().then((ps) => {
            if (Array.isArray(ps) && ps.length > 0) {
                setPartners(ps);
                return ps;
            }
            
            return partners;
        });
    };
    
    useEffect(() => {
        _ = getPartnersAsync();
    }, []);

    const nameStyle = { width: '28%' };
    const countStyle = { width: '12%' };
    const emailStyle = { width: '25%' };
    const phoneStyle = { width: '14%' };
    
    const partnerElements = pageController.mapLineItems(partners, (partner, index) => {
        let path = '/partners/' + partner.id;
        
        return (
            <tr key={index}>
                <td style={nameStyle}>
                    <Link to={path}>{partner.name}</Link>
                </td>
                <td className="small-cell" style={countStyle}><Link to={path + '/enrollments'}>{partner.subMerchants}</Link></td>
                <td className="email-cell" style={emailStyle}>{partner.email}</td>
                <td className="phone-cell" style={phoneStyle}><PhoneNumber value={partner.phone} defaultValue={'N/A'} /></td>
                <td><DateTime time={true} value={partner.created} /></td>
            </tr>
        );
    });

    return (
        <MasterScreenMain selection="partners">
            <div className="pad">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    Partners
                </h1>

                <div className="details-body">
                    <div className="pad">
                        <h2>
                            CardSync Partner Master List
                            <RefreshButton onRefresh={() => getPartnersAsync(true)} />
                        </h2>

                        <table className="table-x-large" width="100%">
                            <thead>
                                <tr>
                                    <th style={nameStyle}>Name</th>
                                    <th className="small-cell" style={countStyle}>SubMerchants</th>
                                    <th className="email-cell" style={emailStyle}>Email</th>
                                    <th className="phone-cell" style={phoneStyle}>Phone</th>
                                    <th>Created</th>
                                </tr>
                            </thead>
                        
                            <tbody>
                            { partnerElements }
                            </tbody>

                        </table>
                        <Pager id="partner-pager-bottom" controller={pageController} items={partners} />
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
};

export default PartnersScreen;
