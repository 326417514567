import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import BatchModel from "../models/BatchModel";
import BatchResultModel from "../models/BatchResultModel";

class BatchService { 
    static instance = new BatchService();
    
    constructor() {
        this.batchMap = {};
        this.recentBatches = [];
    }

    async getRecentBatchesAsync(startDate, endDate, count) {
        if (typeof count === 'number') count = '?count=' + count;
        else count = '';
        
        const path = '/api/batch' + count;
        const me = this;
        
        return HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => { 
            me.recentBatches = BatchModel.fromJsonArray(rsp.data);
            
            return me.recentBatches;
        });
    }
    
    async getBatchesBySubMerchantAsync(subMerchantId) {
        const url = '/api/submerchant/' + subMerchantId + '/batch';
        return HttpService.instance.getAsync(url).then((rsp) => BatchModel.fromJsonArray(rsp.data));
    }

    async getBatchAsync(batchId, includeResults) {
        if (!!includeResults) includeResults = "?results=true";
        else includeResults = "";
        
        const me = this;
        const url = '/api/batch/' + batchId + includeResults;
        console.log('Getting Batch: ' + url);
        
        return HttpService.instance.getAsync(url).then((rsp) => {
            let batch = new BatchModel(rsp.data);
            
            if (includeResults || !!me.batchMap[batchId]) me.batchMap[batchId] = batch;
            
            return batch;
        });
    }
    
    async resubmitBatchesAsync(batchIds, isStaging = false) {
        if (!batchIds || batchIds.length === 0) return;
        
        if (!Array.isArray(batchIds)) {
            if (typeof batchIds === 'string') batchIds = [batchIds];
            else throw new Error("Invalid batchIds parameter. Must be a BatchId string (not guid) or an array of BatchIds");
        }

        console.log("Resubmitting batch...");

        // TODO: Fix
        const url = isStaging ? "https://sandbox.cardsync.io" : "/api/batch";
        return HttpService.instance.postAsync(url, batchIds).then((rsp) => { 
            const batchJsonArray = rsp.data;
            console.log("Resubmitted OK");
            return BatchModel.fromJsonArray(batchJsonArray);
        });
    }
    
    async getBatchResultsAsync(batchId) {
        const me = this;
        const url = '/api/batch/' + batchId + '/result';
        
        return HttpService.instance.getAsync(url).then((rsp) => {
            let results = BatchResultModel.fromJsonArray(rsp.data);
            
            if (!!me.batchMap[batchId]) me.batchMap[batchId].results = results;
            
            return results;
        });
    }
    
    async getPartnerBatchResultsAsync(partnerId, cartType = 3) { 
        const url = '/api/partner/' + partnerId + '/batch-result/' + cartType.toString();
        return HttpService.instance.getAsync(url).then((rsp) => {
            return BatchResultModel.fromJsonArray(rsp.data);
        });
    }
    
    async updateAmexBatchResultSeNumberAsync(resultId, seNumber) {
        const url = "/api/batch-result/" + resultId + "/se-number";
        const data = { value: seNumber };
        
        console.warn(JSON.stringify(data, null, 4));
        
        return HttpService.instance.postAsync(url, data).then((rsp) => {
            return new BatchResultModel(rsp.data);
        });
    };
    
    async updateBatchCountsAsync(batchId) {
        const me = this;
        const url = '/api/batch/' + batchId;

        return HttpService.instance.putAsync(url, {}).then((rsp) => {
            me.batchMap[batchId] = new BatchModel(rsp.data);
            return me.batchMap[batchId];
        });
    }
    
    removeBatchMap(batchId) { 
        delete this.batchMap[batchId];
    }
}

export default BatchService;
