
class StatementHistoryItemModel {

    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || '';
        this.partnerId = json.card_sync_merchant_id || '';
        this.activeMerchants = json.active_merchants;
        this.newEnrollments = json.new_enrollments;
        this.updateCount = json.update_count;

        this.subTotal = json.subTotal;
        this.message = json.message;
        this.total = json.total;

        this.status = json.status;
        this.serviceDate = json.service_date ? new Date(json.service_date) : null;
        this.serviceDateValue = json.service_date?.toString() || "N/A";
        this.invoiceNumberDisplay = json.invoice_number_display;

        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }

    static fromJsonArray(jsonArray) { 
        if (!jsonArray) return [];
        return jsonArray.map(json => new StatementHistoryItemModel(json)).filter((item) => item.id.length > 10);
    }
}

export default StatementHistoryItemModel;
