class NotificationService {
    static instance = new NotificationService();
    
    static createMockNotificationsJson() { 
        return [
            { id: '00-00-01', title: 'New CardSync Partner', entity_type: 1, entity_id: '43-21-01', path: '/partners/9012-09876543-009988', created: new Date(), modified: null },
            { id: '00-00-02', title: 'Konnektive Enrollment', entity_type: 2, entity_id: '5678-09876543-009988', path: '/partners/5678-09876543-009988/enrollments', created: new Date().addMinutes(-5), modified: null },
            { id: '00-00-03', title: 'Bluefin Enrollment', entity_type: 2, entity_id: '1234-09876543-009988', path: '/partners/1234-09876543-009988/enrollments', created: new Date().addMinutes(-15), modified: null },
            { id: '00-00-04', title: 'New Trouble Ticket', entity_type: 3, entity_id: '00-003', path: '/support/00-003', created: new Date().addDays(-1), modified: null },
            { id: '00-00-05', title: 'Batch Processed', entity_type: 4, entity_id: 'f8g9h0i1-f8g9h0i1-f8g9h0i1', path: '/partners/1234-09876543-009988/merchants/7890-09876543-332/batch/f8g9h0i1-f8g9h0i1-f8g9h0i1', created: '2022-06-20T08:32:00', modified: null },
        ]
    }
    
    constructor() {
        this.notifications = [];
        
        this.onUiUpdate = (data) => { 
            console.log("Master Screen event not set.");
        };
    }
    
    removeNotification(id) { 
        const index = this.notifications.findIndex((n) => n.id === id);
        
        if (index >= 0) {
            this.notifications.splice(index, 1);
            return true;
        }
        
        return false;
    }
    
    setNotification(id, title, path, entity_type = 0, entity_id = null, created = null, modified = null) {
        const index = this.notifications.findIndex((n) => n.id === id);
        
        if (!created || typeof created !== "object") created = new Date(created);
        if (!entity_id) entity_id = "0000-0000-0000";
        if (!entity_type) entity_type = 0;
        
        if (index >= 0) {
            this.notifications[index] = { id, title, entity_type, entity_id, path, created, modified };
        } else {
            this.addNotification(id, title, path, entity_type, entity_id, created, modified);
        }
    }
    
    addNotification(id, title, path, entity_type = 0, entity_id = null, created = null, modified = null) {
        this.notifications.push({ id, title, entity_type, entity_id, path, created, modified });
    }
}

export default NotificationService;