import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";

class ProviderReportingService { 
    static instance = new ProviderReportingService();
    
    constructor() {
        this.reportMap = {};
    }
    
    async getCardBrandResultTypeCountsAsync(providerType, startDate, endDate, cacheKey) {
        const path = "/api/provider/" + providerType + "/report/batch/card/brand/counts";
        const me = this;
        
        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            me.reportMap[cacheKey] = rsp.data;
            return rsp.data;
        });
        
    }
}

export default ProviderReportingService;
