import React, {useEffect, useRef} from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGears} from "@fortawesome/free-solid-svg-icons";
import SettingsSubMenu from "../../components/settings/ui/SettingsSubMenu";
import AutomationSubMenu from "../../components/settings/ui/AutomationSubMenu";
import JobService from "../../components/settings/services/JobService";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";
import PagerController from "@paylani/paylani-react-packages/dist/common/ui/pagination/PagerController";
import Pager from "@paylani/paylani-react-packages/dist/common/ui/pagination/Pager";
import LoadingBox from "@paylani/paylani-react-packages/dist/common/ui/LoadingBox";

const AutomationJobLogsScreen = (props) => {
    const {} = props;

    let _;
    let [jobsHistory, setJobsHistory] = React.useState(null);
    let [currentPage, setCurrentPage] = React.useState(0);
    let pageController = useRef(new PagerController(setCurrentPage, 12)).current;
    
    const refreshJobsAsync = async (e) => {
        return await JobService.instance.getJobHistoryAsync().then((jobs) => {
            setJobsHistory(jobs);
        });
    };

    const getDuration = (start, end, prefix = 'in ', defaultValue = "") => {
        if (!start || !end) return (<>{defaultValue}</>);
        
        let duration = end - start;
        let durationInHours = duration / 3600000;
        let durationInSeconds = duration / 1000;
        
        return (<span className={""}>{prefix + durationInSeconds.formatNumber(1)} seconds</span>);
    };
    
    const getMessage = (job) => {
        let prefix = (job.status === -2) ? 'Failed: ' : '';
        
        if (job.jobType === 1) {
            // Batch process..
            let msg = job.message;
            if (job.status < 0) return (<>{prefix + msg}</>);
            
            if (msg.length > 64) {
                msg = "Processed(" + job.status + ")";
                prefix = "";
            }
            
            const minutes = 10;
            const threshTime = (new Date()).addMinutes(-minutes);
            
            let dv = job.status === 1 ? "Instance" : (job.status === 0 ? "Running..." : "Failed");
            const startTime = job.startTime?.getTime() || 0;
            const endTime = job.startTime?.getTime() > 0 && job.startTime.getTime() < threshTime.getTime() ? new Date().getTime() : job.endTime?.getTime();
            const duration = !!endTime ? (endTime - startTime) / 1000 : 0;
            
            if (duration > 36000) {
                if (job.status === 0) return (<>Timed out</>);
            }

            return (<>{prefix + msg} {getDuration(job.startTime?.getTime(), endTime, "in ", dv)}</>);
        }

        return (<>{prefix + job.message} {getDuration(job.startTime?.getTime(), job.endTime?.getTime())}</>);
    };
    
    useEffect(() => {
        _ = refreshJobsAsync()
    }, []);

    let historyElements = pageController.mapLineItems(jobsHistory, (job) => {
        const dv = job.status === 1 ? "No Date Logged" : (job.status === 0 ? "--" : "Failed");
        
        return (
            <tr key={job.id} className={"job-summary-" + job.status} title={job.id}>
                <td>{job.jobTypeName}</td>
                <td className={"job-summary-" + job.status}>{job.statusName}</td>
                <td><DateTime value={job.startTime} time={true} /></td>
                <td><DateTime defaultValue={dv} value={job.endTime} time={true} /></td>
                <td className={"small job-summary-" + job.status}>{getMessage(job)}</td>
            </tr>
        );
    });
    
    const loadingBox = jobsHistory === null ? (<LoadingBox />) : null;
    
    const bodyElement = jobsHistory != null ? (<div id="job-logs">
            <table width={"100%"} className={"table-x-large"}>
                <thead>
                <tr>
                    <th>Job Type</th>
                    <th>Status</th>
                    <th>Started</th>
                    <th>Completed</th>
                    <th>Result</th>
                </tr>
                </thead>
    
                <tbody>
                {historyElements}
                </tbody>
            </table>
            <Pager items={jobsHistory} controller={pageController} />
        </div>) : null;

    return (
        <MasterScreenMain selection="settings">
            <div className="pad form">
                <h1><FontAwesomeIcon icon={faGears} /> Settings</h1>

                <div className="details-body">
                    <SettingsSubMenu selection="automation" />

                    <div className="pad">
                        <h2>
                            Automated Jobs History
                            <RefreshButton onClick={refreshJobsAsync} />
                        </h2>

                        <AutomationSubMenu selection="logs" />

                        <p>
                            History of all the automated jobs that have been run.
                        </p>

                        { loadingBox }
                        { bodyElement }

                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
    
    
};

export default AutomationJobLogsScreen;
