import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faComments,
    faEnvelope,
    faHandPeace,
    faSearch,
    faSquareArrowUpRight,
    faSync
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import MasterScreenMain from "../../MasterScreenMain";
import SupportTicketService from "../../components/support/services/SupportTicketService";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";
import {Tooltip} from "@mui/material";

const CustomerSupportScreen = (props) => {
    const {} = props;
    
    let _;
    const [threads, setThreads] = useState(SupportTicketService.instance.threads || null);
    const [oAuthModel, setOAuthModel] = useState(SupportTicketService.instance.oAuthModel || null);
    const [filterTerm, setFilterTerm] = useState("");
    
    const getThreadsAsync = async (force = false) => {
        if (!force && threads?.length > 0) return threads;
        
        const sd = null;
        const ed = null;
        console.log("Getting threads...");
        const th = await SupportTicketService.instance.getSupportTicketThreadsAsync(sd, ed);
        
        console.log("Got threads");
        if (!!th && Array.isArray(th)) setThreads(th);
        
        return th;
    };
    
    const deleteUserTokenAsync = async (userToken) => {
        const newAuth = {...oAuthModel};
        newAuth.authTokens = newAuth.authTokens.filter((t) => t.id !== userToken.id);

        setOAuthModel(newAuth);
        await SupportTicketService.instance.deleteUserTokenAsync(userToken);
    };
    
    const initOAuthAsync = async (force) => {
        if (oAuthModel?.length > 0 && !force) return;
        
        const model = await SupportTicketService.instance.getGoogleOAuthUrlAsync("/support");
        if (!!model) setOAuthModel(model);
    };
    
    const filterTickets = (e) => {
        const term = (e.currentTarget?.value || "").trim();
        console.log(term);
        
        if (term.length > 2) {
            setFilterTerm(term);
            return;
        }
        
        setFilterTerm("");
    };
    
    const createOAuthElement = () => {
        if (oAuthModel?.authTokens?.length > 0) {
            const s = oAuthModel.authTokens.length === 1 ? "" : "es";
            const accountElements = oAuthModel.authTokens.map((token, i) => {
                const element = (<a className={"google-account"} key={"account-token-element-link-" + i}>{ token.accountName }</a>);
                if (token.accountName?.toLowerCase() === "jade@paylani.com") return (<Tooltip title={(<a onClick={() => deleteUserTokenAsync(token)}>Delete</a>)} key={"account-token-element-tooltip-" + i}>{ element }</Tooltip>);
                return element;
            });
            
            accountElements.push((<a href={oAuthModel.initUrl} key={"account-add-element-000"} className={"google-account add"}>+ Add Gmailbox <FontAwesomeIcon icon={faSquareArrowUpRight} /></a>));
            
            return (<span className={"connected"}><a onClick={() => null}><FontAwesomeIcon icon={faEnvelope} /> Inbox{s} ({oAuthModel.authTokens.length})+</a></span>);
        }
        
        if (!!oAuthModel?.initUrl) return (<span className={"not-connected"}><a href={oAuthModel.initUrl} target={"_google"}>Connect Gmail Account <FontAwesomeIcon icon={faSquareArrowUpRight} /></a></span>);
        
        return (<span className={"not-connected"}><a href={"/"} target={"_google"}>Getting Connections...</a></span>);
    }
    
    const refreshAsync = async () => {
        await getThreadsAsync(true);
    };
    
    useEffect(() => {
        if (!!filterTerm) { 
            console.warn("Filter by: '" + filterTerm + "'");
        }
    }, [filterTerm]);
    
    useEffect(() => {
        _ = getThreadsAsync();
        _ = initOAuthAsync();
    }, []);
    
    let newMessageCount = 0;
    
    const getPartnerName = (m) => {
        if (m.merchantId?.toUpperCase() === '5CCD9CCB-3A43-4812-BCDB-997DC6F09044') return 'Paylani DBA CardSync';
        return 'Cyberdyne Partners, Inc';
    };
    
    const filteredThreads = !!threads && threads.length > 0 && !!filterTerm && filterTerm.length > 2 ? 
        threads.filter((t) => {
            const im = t.getMatchValue(filterTerm);
            console.log("Try Term [" + im + "]: " + t.id);
            
            return im > 0;
        }) :
        threads;
    
    const messageList = filteredThreads.sort((a, b) => a.recentTicket.sentDate.toDate() > b.recentTicket.sentDate.toDate() ? -1 : 1).map((thread) => {
        const m = thread.recentTicket;
        const color = m.getColor();
        const messageTypeIcon = (<FontAwesomeIcon icon={m.getIcon()} style={{color: color}} />);
        const className = thread.isRead ? 'read' : 'unread';
        const threadId = m.threadId;
        const messageCountElement = thread.messageCount > 1 ? (<span className={"message-count"}>{thread.messageCount}</span>) : null;
        
        if (m.readDate === null) newMessageCount++;
        
        return (<tr className={className} key={m.id}>
            <td title={m.merchantId}><Link to={'/support/' + threadId}>{ messageTypeIcon }{m.partnerName || getPartnerName(m)} {messageCountElement}</Link></td>
            <td className={"from-name"}><Link to={'/support/' + threadId}>{m.fromName}</Link></td>
            <td className={"subject"}><Link to={'/support/' + threadId}>{m.subject}</Link></td>
            <td><DateTime time={true} value={m.sentDate} showRelative={true} /></td>
        </tr>);
    });
    
    const threadCountElement = threads?.length > 0 ? (<span className={"thread-count"}>{threads.length} Threads</span>) : null;
    const oAuthElement = createOAuthElement();

    const topMenuElement = (
        <div id={"support-menu"} className={"form"}>
            <span id={"filtering"}>
                <input id={"filter-text"} type={"text"} onChange={filterTickets} className={"filter-text"} placeholder={"Search in Tickets"} />
                <span className={"search-icon"}>
                    <FontAwesomeIcon icon={faSearch} />
                </span>
            </span>
            { oAuthElement }
            <span>
                <Link to={"/support"}><FontAwesomeIcon icon={faComments} /> Support Tickets</Link>
            </span>
        </div>
    );
    
    return (
        <MasterScreenMain selection="support">
            <div className="pad">
                <h1>
                    <FontAwesomeIcon icon={faHandPeace} />
                    Customer Support
                </h1>

                <div className="details-body">
                    <div className="pad">
                        <h2>
                            Support Tickets ({threadCountElement})
                            <RefreshButton onClick={refreshAsync} icon={faSync} caption={"Refresh"} />
                        </h2>
                        
                        { topMenuElement }
                        
                        <table className="table-x-large" width="100%" id="tickets-list-table">
                            <thead>
                            <tr>
                                <th>&nbsp; Partner/Merchant</th>
                                <th className={"from-name"}>From Name</th>
                                <th className={"subject"}>Subject</th>
                                <th>Last Update</th>
                            </tr>
                            </thead>

                            <tbody>
                            { messageList }
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );

};

export default CustomerSupportScreen;
