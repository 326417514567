import React, {useState, useRef, useEffect} from 'react';
import './App.css';
import "./components/common/ui/ui.css";
import MasterScreenMain from "./MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faHandsClapping, faHouseUser } from "@fortawesome/free-solid-svg-icons";
import CardBrandLineGraph from "./components/reporting/ui/CardBrandLineGraph";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import {Link} from "react-router-dom";
import ReportingService from "./components/reporting/services/ReportingService";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import BatchService from "./components/batches/services/BatchService";
import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import ToolTip from "./components/common/ui/ToolTip";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";
import PagerController from "./components/common/ui/pager/PagerController";
import Pager from "./components/common/ui/pager/Pager";
import SearchService from "./components/search/services/SearchService";
import SearchBox from "./components/search/ui/SearchBox";
import Controller from "@paylani/paylani-react-packages/dist/common/controllers/Controller";

function App() {
    /* TODO: Add to CardBrandLineGraph */
    
    ChartJS.register(
        CategoryScale,
        BarElement,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        ArcElement
    );

    const defaultLineGraphTitle = 'Submissions by Card Brand';
    let _;
    let stateTitles = [
        defaultLineGraphTitle,
        'Updates by Card Brand'
    ];
    
    const submissionsReportKey = 'master-submissions';
    const updatesReportKey = 'master-updates';
    
    const [chartState, setChartState] = useState({title: defaultLineGraphTitle, state: 0});
    const [cardBrandTrendData, setCardBrandTrendData] = useState(null);
    const [dashboardData, setDashboardData] = useState(ReportingService.instance.dashboard || {});
    const [recentBatches, setRecentBatches] = useState(BatchService.instance.recentBatches || []);
    const [searchResults, setSearchResults] = useState(null);
    const [searchController, setSearchController] = useState(new Controller());

    const searchRef = useRef();
    
    let [currentPage, setCurrentPage] = useState(0);
    let [user, setUser] = useState(AuthenticationService.instance.session?.user || null);

    let pageController = useRef(new PagerController(setCurrentPage, 16)).current;

    let startDate = new Date().addDays(-14);
    
    const getRecentBatchesAsync = async (force, e) => {
        console.log('Getting recent batches [' + recentBatches.length + ']...')
        if (!user) return;

        if (force === true || BatchService.instance.recentBatches.length === 0) {
            return await BatchService.instance.getRecentBatchesAsync().then((batches) => setRecentBatches(batches));
        }

        console.log('Cached recent batches.');
        setRecentBatches(BatchService.instance.recentBatches);
    };
    
    const getSubmissionDataAsync = async (force, e) => {
        let submissionData = ReportingService.instance.reportMap[submissionsReportKey];

        if (!!submissionData && !force) {
            console.log('Got Cached Submission Data')
            setCardBrandTrendData(submissionData.toLineChartDataSet());
            return;
        }
        
        //console.warn('Getting Submission Data');
        return await ReportingService.instance.getMasterCardBrandSubmissionsAsync(startDate, null, submissionsReportKey).then((model) => {
            console.log('Got Submission Data');
            setCardBrandTrendData(model.toLineChartDataSet());
        });
    };

    const getUpdatesDataAsync = async (setValues) => {
        let updateData = ReportingService.instance.reportMap[updatesReportKey];
        
        if (!!updateData) {
            //console.log('Got Cached Updates Data')
            if (setValues === true) setCardBrandTrendData(updateData.toLineChartDataSet());
            return;
        }
        
        //console.warn('Getting Updates Data');
        return await ReportingService.instance.getMasterCardBrandUpdatesAsync(startDate, null, updatesReportKey).then((model) => {
            console.log('Got Server Updates Data');
            if (setValues === true) setCardBrandTrendData(model.toLineChartDataSet());
        });
    };

    const getDashboardDataAsync = async (force) => {
        if (!!ReportingService.instance.dashboard && !force) {
            //console.log('Got Cached Dashboard Data')
            setDashboardData(ReportingService.instance.dashboard);
            return;
        }
        
        //console.warn('Getting dashboard data');
        return await ReportingService.instance.getMasterDashboardDataAsync().then((model) => {
            setDashboardData(model);
        });
    };

    AuthenticationService.instance.onSessionSet = (session) => {
        user = session?.user;
        setUser(session?.user);
        
        return (session?.user === null);    // If we return 'true' here, this will continue to fire any time the session is set.
    };

    const getPortalData = (force) => {
        _ = getSubmissionDataAsync(force);
        _ = getUpdatesDataAsync(false);
        _ = getDashboardDataAsync(force);
        _ = getRecentBatchesAsync(force);
    }
    
    const getPortalDataAsync = async (force) => {
        return Promise.all([
            getSubmissionDataAsync(force),
            getUpdatesDataAsync(false),
            getDashboardDataAsync(force),
            getRecentBatchesAsync(force)
        ]);
    }
    const onSessionChange = (session) => {
        console.log('OnSessionChange');
        getPortalData(true);
    };
    
    const searchAsync = async (e) => {
        const term = searchRef.current?.value;
        if (!term) return;
        
        await SearchService.instance.searchAsync(term).then((results) => {
            if (results?.length > 0) {
                console.log(results);
                setSearchResults(results);
            }
        });
    };
    
    useEffect(() => {
        getPortalData();
    }, []);

    const toggleState = (e) => {
        let newState = { title: defaultLineGraphTitle, state: 0 };
        if (chartState.state === 0) {
            newState.state = 1;
        }

        newState.title = stateTitles[newState.state];
        setChartState(newState);
    };
    
    const setLineGraphState = (state) => {
        if (state === chartState.state) return;
        
        const newState = { title: stateTitles[state], state: state };
        setChartState(newState);
        
        if (state === 1) _ = getUpdatesDataAsync(true);
        else _ = getSubmissionDataAsync();
    };
    
    let controlSelections = ['', ''];
    controlSelections[chartState.state] = 'selected';
    
    let controls = (<span id="home-line-controls">
        <a className={controlSelections[0]} onClick={(e) => setLineGraphState(0)}>Submissions</a>
        <a className={controlSelections[1]} onClick={(e) => setLineGraphState(1)}>Updates</a>
    </span>)
    
    let welcomeMessage = !!user ? (user?.gender === 'F' ? 'Welcome, ' + user?.firstName : 'Welcome, Mr. ' + user?.lastName) : 'Welcome, Fellow Traveller';
    
    let cardColumnStyle = {width: '120px'};
    let batchIdStyle = { width: '312px'};
    let cardCountStyle = { width: '90px' };
    
    let recentBatchesElements = pageController.mapLineItems(recentBatches, (batch) => {
        const b = batch;
        let rowStatusClass = b.isPickedUp ? ' picked-up' : '';
        let statusStyle = b.isPickedUp ? {fontSize: '13px', color: 'var(--header-green)'} : {};
        let desc = b.isPickedUp ? 'Picked up on ' + b.pickupDate.toDateTime() : 'Not picked up yet';
        let status = !!b.isPickedUp ? 'Picked up on ' + b.pickupDate.toDateTime() : b.batchStatusName;
        let statusIcon = b.getIcon();
        let icon = b.getIcon();

        if (status.toLowerCase() === 'completed') {
            status = 'Completed, but not picked up yet';
            statusStyle.fontSize = '13px';
            statusStyle.color = 'var(--yellow)';
        }

        let tooltipComponent = (<span><FontAwesomeIcon icon={icon} /> {desc}</span>);
        let tooltipStatusComponent = (<span><FontAwesomeIcon icon={icon} /> {status}</span>);
        
        return (<tr className={'batch-item' + rowStatusClass} key={"recent-" + batch.id}>
            <td style={batchIdStyle}>
                <ToolTip value={tooltipComponent}>
                    <Link to={"/partners/" + batch.subMerchant?.partnerId + "/merchants/" + batch.subMerchant?.id + "/batch/" + batch.batchId}>
                        <FontAwesomeIcon icon={icon} />
                        {batch.batchId}
                    </Link>
                </ToolTip>
            </td>
            <td>
                <Link to={"/partners/" + batch.subMerchant?.partnerId + "/merchants/" + batch.subMerchant?.id + ""}>{batch.subMerchant?.name || 'Unknown Merchant'}</Link>
            </td>
            <td style={cardColumnStyle}>
                {batch.submittedCount.formatNumber()}
            </td>
            <td>
                <Link to={"/partners/" + batch.subMerchant?.partnerId}>{batch.subMerchant?.partner?.name || 'Unknown Partner'}</Link>
            </td>
            <td><DateTime value={batch.modified || batch.created} time={true} /></td>
        </tr>);
    });
    
    const snapshotPanel = (<div id="dashboard-snapshot">
        <Link to={"/reporting/batches"} className="dark round16 rows" onClick={(e) => setLineGraphState(0)}>
            <ul>
                <li>Batches Submitted</li>
                <li>Last Submission Was</li>
                <li><DateTime value={dashboardData?.lastSubmission?.xAxisDate} showRelativeDate={true} time={true} defaultValue={"Never"} /></li>
            </ul>
            <span className="large">{ (dashboardData?.batchSubmissions?.value || 0.0).formatNumber(0) }</span>
        </Link>

        <a className="dark round16 rows" onClick={(e) => setLineGraphState(1)}>
            <ul>
                <li>Updates Today</li>
                <li>Last Update</li>
                <li><DateTime value={dashboardData?.lastUpdate?.xAxisDate} showRelativeDate={true} defaultValue={"Never"} /></li>
            </ul>
            <span className="large">{ (dashboardData?.cardUpdates?.value || 0.0).formatNumber(0) }</span>
        </a>

        <a className="dark round16 rows">
            <ul>
                <li>Batches Processed</li>
                <li>Last Process Was</li>
                <li><DateTime value={dashboardData?.lastProcess?.xAxisDate} showRelativeDate={true} defaultValue={"Never"} /></li>
            </ul>
            <span className="large">{ (dashboardData?.batchUpdates?.value || 0.0).formatNumber(0) }</span>
        </a>
    </div>);
    const graphPanel = (<CardBrandLineGraph title={chartState.title} data={cardBrandTrendData} chartControls={controls} />);
    const recentPanel = (<div id="recent-batches">
            <h3>
                Recent Batches
                <RefreshButton onClick={async () => await getRecentBatchesAsync(true)} />
            </h3>
            <table className="table-x-large" width="100%">
                <thead>
                <tr>
                    <th style={batchIdStyle}>BatchId</th>
                    <th>Merchant</th>
                    <th style={cardColumnStyle}>Cards</th>
                    <th>Partner</th>
                    <th>Date</th>
                </tr>
                </thead>
                <tbody>
                {recentBatchesElements}
                </tbody>
            </table>

            <Pager items={recentBatches} controller={pageController} />

        </div>);
    
    return (
        <MasterScreenMain onSessionChange={onSessionChange}>
            <div id="dashboard-main" className="pad">
                <h1>
                    <FontAwesomeIcon icon={faHouseUser} />
                    Dashboard
                </h1>

                <div className="details-body">
                    <div className="pad x-large">
                        <h2>
                            <FontAwesomeIcon icon={faHandsClapping} />
                            {welcomeMessage}
                            
                            <RefreshButton onClick={async () => getPortalDataAsync(true)} />
                        </h2>

                        <p>
                            Batch submissions appear to be on par, historically. 
                            Processing seems to be running smoothly. 
                            And no anomalies have been detected in card updates.
                        </p>

                        <SearchBox controller={searchController} />

                        { snapshotPanel }
                        { graphPanel }
                        { recentPanel }
                        
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
}

export default App;
