import React, {useState, useEffect} from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRightArrowLeft, faCircleRight,
    faCommentDots, faDownload,
    faEnvelope, faFaceDizzy,
    faGears, faHeadSideCough, faMagnifyingGlass, faMagnifyingGlassArrowRight, faMicrochip,
    faPaperPlane,
    faPersonFallingBurst, faRightFromBracket, faSkull, faTrashArrowUp
} from "@fortawesome/free-solid-svg-icons";
import SettingsSubMenu from "../../components/settings/ui/SettingsSubMenu";
import AutomationSubMenu from "../../components/settings/ui/AutomationSubMenu";
import RefreshButton from "@paylani/paylani-react-packages/dist/common/ui/RefreshButton";
import JobService from "../../components/settings/services/JobService";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import NotificationService from "../../components/messaging/services/NotificationService";

const AutomationJobsScreen = (props) => {
    const {} = props;

    let _;

    const [classNames, setClassNames] = useState({batchProcessClassName: "", syncSpinClassName: "", purgeSpinClassName: "", searchSpinClassName: ""});
    const [processProgress, setProcessProgress] = useState({ state: 0, message: "Processing..." });
    const [activeJobId, setActiveJobId] = useState(null);
    
    const setBatchProcessClassName = (className) => {
        //batch-process
        setClassNames({...classNames, batchProcessClassName: className });
    };
    
    const setSyncSpinClassName = (className) => {
        setClassNames({...classNames, syncSpinClassName: className });
    };
    
    const setPurgeSpinClassName = (className) => {
        setClassNames({...classNames, purgeSpinClassName: className });
    };
    
    const setSearchSpinClassName = (className) => {
        setClassNames({...classNames, searchSpinClassName: className });
    };
    
    const refreshJobsAsync = async (e) => {
        //
    };
    
    const processBatchesAsync = async (e) => {
        setBatchProcessClassName("spinning");
        
        const d = new Date();
        const processResult = await JobService.instance.processBatchesAsync();

        let diff = (new Date()).getTime() - d.getTime();
        console.log("Diff-A: " + diff);
        
        // Make it looks like it is doing something...
        if (diff < 1000) await new Promise(resolve => setTimeout(resolve, 1500));

        diff = (new Date()).getTime() - d.getTime();
        console.log("Diff-B: " + diff);

        NotificationService.instance.removeNotification("worldpay");
        NotificationService.instance.removeNotification("fiserv");
        
        if (typeof NotificationService.instance.onMasterScreen === "function")
            NotificationService.instance.onUiUpdate({ "type": "batch_process", "message": "Batch processing complete." });
        
        setBatchProcessClassName("");
    };
    
    const syncUsageAsync = async (e) => {
        setSyncSpinClassName("spinning");
        return await JobService.instance.syncUsageAsync().then((job) => {
            setSyncSpinClassName("");
        });
    };

    const updateSearchIndexAsync = async (e) => {
        setSearchSpinClassName("spinning");
        return await JobService.instance.syncUsageAsync().then((job) => {
            setSearchSpinClassName("");
        });
    };
    
    const purgeSensitiveDataAsync = async (e) => {
        setPurgeSpinClassName("spinning");
        return await JobService.instance.purgeSensitiveDataAsync().then((job) => {
            setPurgeSpinClassName("");
        }).catch((error) => {
            setPurgeSpinClassName("");
        });
    };
    
    useEffect(() => {
        _ = refreshJobsAsync()
    }, []);
    
    const processProgressElement = processProgress.state > 0 ? 
        (<span className={"progress job-state-" + processProgress.state?.toString()}>{processProgress.message}</span>) :
        null
    
    return (
        <MasterScreenMain selection="settings">
            <div className="pad form">
                <h1><FontAwesomeIcon icon={faGears} /> Settings</h1>

                <div className="details-body">
                    <SettingsSubMenu selection="automation" />

                    <div className="pad">
                        <h2>
                            Automated Jobs
                            <RefreshButton onClick={refreshJobsAsync.bind(this)} />
                        </h2>
                        
                        <AutomationSubMenu selection="jobs" />

                        <p>
                            Automated jobs are scheduled to run at regular intervals. You can also run them manually here.
                        </p>

                        <div id="job-logs">
                            
                            <ul className="action-options">
                                <li>
                                    <h4><FontAwesomeIcon icon={faMicrochip} /> Process Batches from SFTP</h4>
                                    <p>Connects to the provider's SFTP account, downloads the files, then processes them via Secured Batch Processing API in the respective environment.</p>
                                    <div className="buttons">
                                        <a onClick={processBatchesAsync.bind(this, 'batch-process')}>
                                            <span className={"spinner"}>
                                                <FontAwesomeIcon icon={faDownload} /> Process Batches
                                                <span className={classNames.batchProcessClassName}><label></label></span>
                                            </span>
                                        </a>
                                        
                                        <span>{ processProgressElement }</span>
                                    </div>
                                </li>
                                
                                <li>
                                    <h4><FontAwesomeIcon icon={faArrowRightArrowLeft} /> Sync Batch Result Data</h4>
                                    <p>Reports using large record counts use an indexed summary dataset for query speed and efficiency. This job syncs the summary dataset so reports are current.</p>
                                    <div className="buttons">
                                        <a onClick={syncUsageAsync.bind(this, 'sync-usage')}>
                                            <span className={"spinner"}>
                                                <FontAwesomeIcon icon={faCircleRight} /> Sync Batch Results
                                                <span className={classNames.syncSpinClassName}><label></label></span>
                                            </span>
                                        </a>
                                        
                                    </div>
                                </li>

                                <li>
                                    <h4><FontAwesomeIcon icon={faHeadSideCough} /> Purge Sensitive Data</h4>
                                    <p>Deletes sensitive data from storage based on the <a href={"https://docs.google.com/document/d/1uboLp-ISzgrv0z5HAFRcOmbfkkR7FCYg/"} target={"_blank"}>CardSync Data Retention Policiy</a></p>
                                    <div className="buttons">
                                        <a onClick={purgeSensitiveDataAsync.bind(this, 'purge')}>
                                            <span className={"spinner"}>
                                                <FontAwesomeIcon icon={faTrashArrowUp} /> Purge Data
                                                <span className={classNames.purgeSpinClassName}><label></label></span>
                                            </span>
                                        </a>
                                    </div>
                                </li>
                                
                            </ul>

                        </div>

                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
    
};

export default AutomationJobsScreen;