import AddressModel from "@paylani/paylani-react-packages/dist/geo/models/AddressModel";
import CustomerModel from "../../people/models/CustomerModel";

class PartnerModel {
    static startDate = new Date();
    
    toJson = () => { 
        return {
            name: this.name + '',
            email: this.email || '',
            phone: this.phone || '',
            website: this.website || '',
            created: this.created
        };
    }
    
    constructor(json, hasDetails) {
        if (!json) json = {};
        
        this.id = json.id;
        this.name = json.name || '';
        this.website = json.website;
        this.email = json.email;
        this.phone = json.phone || '';
        this.planId = json.plan_id;
        this.status = json.status || 1;
        this.completedEnrollments = json.completed_enrollment_count || 0;
        this.pendingEnrollments = json.pending_enrollment_count || 0;
        this.subMerchants = this.completedEnrollments + this.pendingEnrollments;
        this.created = new Date(Date.parse(json.created));
        this.modified = json.modified !== null ? new Date(Date.parse(json.modified)) : null;
        
        const billingCustomer = json.customer || json.billing_customer;
        this.customer = !!billingCustomer ? new CustomerModel(billingCustomer) : new CustomerModel({ id: null, first_name: '', last_name: '' });
        
        this.hasDetails = (hasDetails === true);
    }
}

export default PartnerModel;
