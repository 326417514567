import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import PartnerService from "../../components/partners/services/PartnerService";
import PartnerModel from "../../components/partners/models/PartnerModel";
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard, faArrowTrendUp} from "@fortawesome/free-solid-svg-icons";
import PartnerSubMenu from "../../components/partners/ui/PartnerSubMenu";
import PartnerOverviewSubMenu from "../../components/partners/ui/PartnerOverviewSubMenu";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS, Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import ReportItemModel from "../../components/reporting/models/ReportItemModel";
import ReportingService from "../../components/reporting/services/ReportingService";
import BatchResultTypeTrendSeriesModel from "../../components/reporting/models/BatchResultTypeTrendSeriesModel";
import BarChartGraph from "../../components/reporting/ui/BarChartGraph";
import ReportingSubMenu from "../../components/reporting/ui/ReportingSubMenu";
import DateRangeSelector from "@paylani/paylani-react-packages/dist/common/ui/date-range-selector/DateRangeSelector";

const PartnerBatchReportingScreen = (props) => {
    const { partnerId } = useParams();
    let [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId] || new PartnerModel({}));


    ChartJS.register(
        CategoryScale,
        BarElement,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        ArcElement
    );

    let _;
    const {} = props;

    const partnerReportKey = 'partner-report';
    const monthlyCardResultsKey = 'monthly-card-results';
    const monthlyCardResultTypesKey = 'batch-report';
    const monthlyBatchSubmissionsKey = 'monthly-batch-submissions';
    const monthlyCardSubmissionsKey = 'monthly-card-submissions';

    const lineTitles = [
        "Card Update Types",
        "All Card Submissions + Updates",
        "Batch Submissions",
    ];

    let ed = new Date().addDays(1);
    let sd = ed.toFirstOfTheYear();

    const initDate = ReportItemModel.createDateRange(sd, ed);
    let [dateRange, setDateRange] = useState(initDate);
    
    let [batchActivity, setBatchActivity] = useState(PartnerService.instance.batchActivity || []);
    let [monthlyCardResults, setMonthlyCardResults] = useState(ReportingService.instance.reportMap[ReportItemModel.getKeyFromDate(monthlyCardResultsKey, initDate.startDate, initDate.endDate)] || []);
    let [monthlyBatchSubmissions, setMonthlyBatchSubmissions] = useState(ReportingService.instance.reportMap[ReportItemModel.getKeyFromDate(monthlyBatchSubmissionsKey, initDate.startDate, initDate.endDate)] || []);
    let [monthlyCardSubmissions, setMonthlyCardSubmissions] = useState(ReportingService.instance.reportMap[ReportItemModel.getKeyFromDate(monthlyCardSubmissionsKey, initDate.startDate, initDate.endDate)] || []);

    let [sortKey, setSortKey] = useState({ key: 'x', mult: 0 });
    let [lineGraphState, setLineGraphState] = useState(0);
    let [monthlyResultTypes, setMonthlyResultTypes] = useState(new BatchResultTypeTrendSeriesModel());

    const getCachedData = (key, sd, ed) => {
        let dr = (!!sd && !!ed) ? ReportItemModel.createDateRange(sd, ed) : dateRange;
        let cacheKey = ReportItemModel.getKeyFromDate(key + '-' + partnerId, dr.startDate, dr.endDate);
        
        return { dr: dr, data: PartnerService.instance.reportMap[cacheKey], cacheKey: cacheKey };
    }
    
    const getBatchResultTypeTrendDataAsync = async (force, sd, ed) => {
        let { data, dr, cacheKey } = getCachedData(monthlyCardResultTypesKey, sd, ed);
        
        if (!!data && !force) {
            setMonthlyResultTypes(data);
            return data;
        }

        console.warn('Getting Monthly Result Types ' + dr.startDate);
        return await PartnerService.instance.getBatchResultTypesByMonthAsync(partnerId, dr.startDate, dr.endDate, cacheKey).then((resultSeries) => {
            setMonthlyResultTypes(resultSeries);
            return resultSeries;
        });
    };

    const getMonthlyResultsDataAsync = async (force, sd, ed) => {
        let { data, dr, cacheKey } = getCachedData(monthlyCardResultsKey, sd, ed);

        if (!!data && !force) {
            setMonthlyCardResults(data);
            return data;
        }

        return await PartnerService.instance.getCardUpdatesByMonthAsync(partnerId, dr.startDate, dr.endDate, cacheKey).then((stats) => {
            setMonthlyCardResults(stats);
            return stats;
        });
    };

    const getMonthlySubmissionsDataAsync = async (force, sd, ed) => {
        let { data, dr, cacheKey } = getCachedData(monthlyBatchSubmissionsKey, sd, ed);

        if (!!data && !force) {
            setMonthlyBatchSubmissions(data);
            return data;
        }

        return await PartnerService.instance.getBatchSubmissionsByMonthAsync(partnerId, dr.startDate, dr.endDate, cacheKey).then((stats) => {
            setMonthlyBatchSubmissions(stats);
            return stats;
        });
    };

    const getMonthlyCardSubmissionsDataAsync = async (force, sd, ed) => {
        let { data, dr, cacheKey } = getCachedData(monthlyCardSubmissionsKey, sd, ed);

        if (!!data && !force) {
            setMonthlyCardSubmissions(data);
            return data;
        }

        console.log('Getting Monthly Card Submissions: ' + sd);
        return PartnerService.instance.getCardSubmissionsByMonthAsync(partnerId, dr.startDate, dr.endDate, cacheKey).then((stats) => {
            setMonthlyCardSubmissions(stats);
            return stats;
        });
    };

    const refreshData = (force, sd, ed) => {
        force = (force === true);
        
        console.log('Refreshing Data (' + force + '): ' + sd);
        
        _ = getBatchResultTypeTrendDataAsync(force, sd, ed);
        _ = getMonthlyResultsDataAsync(force, sd, ed);
        _ = getMonthlySubmissionsDataAsync(force, sd, ed);
        _ = getMonthlyCardSubmissionsDataAsync(force, sd, ed);
    };

    const onDateRangeChange = (sd, ed) => {
        let dr = ReportItemModel.createDateRange(sd, ed);
        setDateRange(dr);

        refreshData(true, sd, ed);
    };

    useEffect(() => {
        refreshData()
        PartnerService.instance.getPartnerAsync(partnerId).then((partner) => setPartner(partner));
    }, []);

    const nameStyle = { width: '40%' };
    const countStyle = { width: '15%' };
    const averageLowPercent = 0.05;
    const averageHighPercent = 0.2;
    const goodPercent = 0.10;

    const createMonthlyDataSets = (title) => {
        let dataSets = [];
        let labels = [];

        switch(lineGraphState){
            case 0:
                return monthlyResultTypes.toLineChartDataSet(1, 1.5);
            case 2:
                labels = monthlyBatchSubmissions.map((x) => x.label);
                dataSets = [
                    ReportItemModel.createLineChartSeries(monthlyBatchSubmissions, title || "Batch Submissions", '#FFFF0099'),
                ];
                break;
            default:
                labels = monthlyCardResults.map((x) => x.label);
                dataSets = [
                    ReportItemModel.createLineChartSeries(monthlyCardSubmissions, title || "Card Submissions", '#88CCDDFF'),
                    ReportItemModel.createLineChartSeries(monthlyCardResults, title || "Card Updates", '#3BD23DFF'),
                ];
                break;
        }

        return {
            labels: labels,
            datasets: dataSets
        };
    };

    let cardUpdateTrendData = createMonthlyDataSets();

    let controlSelections = ['', ''];
    controlSelections[lineGraphState] = 'selected';

    let controls = (<span id="home-line-controls">
        <a className={controlSelections[0]} onClick={(e) => setLineGraphState(0)}>{ lineTitles[0] }</a>
        <a className={controlSelections[1]} onClick={(e) => setLineGraphState(1)}>{ lineTitles[1] }</a>
        <a className={controlSelections[2]} onClick={(e) => setLineGraphState(2)}>{ lineTitles[2] }</a>
    </span>);

    //console.warn('Batch reports rendered!');

    let mainGraph = (<BarChartGraph title={lineTitles[lineGraphState]} data={cardUpdateTrendData} chartControls={controls} />);

    return (
        <MasterScreenMain selection="partners">
            <div className="pad full-screen-dialog form">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    {partner.name}
                </h1>

                <div className="details-body body">
                    <PartnerSubMenu partner={partner} selection="overview" />

                    <div className="pad" style={{width: "100%"}}>
                        <h2>Batch Activity <span className={"subtitle"}>All {partner.name} Submerchants</span></h2>
                        <PartnerOverviewSubMenu partner={partner} selection={"batches"} />

                        <p>
                        </p>

                        <DateRangeSelector startDate={dateRange.startDate} endDate={dateRange.endDate} onRefresh={onDateRangeChange} />

                        {mainGraph}


                    </div>
                </div>
            </div>

        </MasterScreenMain>
    );

    
};

export default PartnerBatchReportingScreen;