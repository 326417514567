import PlanModel from "../models/PlanModel";
import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";

class PlanService { 
    static instance = new PlanService();
    
    constructor() {
        this.plans = [];
        this.planMap = {};
        
        this.getPlansAsync().then().catch((ex) => {
            console.error("Failed to get plans: " + ex);
        });
    }
    
    async getPlansAsync() {
        const me = this;
        const url = '/api/plan';
        
        return await HttpService.instance.getAsync(url).then((rsp) => {
            console.log("Got Plans: " + rsp?.data?.length);
            return me.setPlans(rsp?.data);
        }).catch((err) => console.error('Failed to get plans: ' + err));
    }

    async getPlanAsync(planId) {
        if (!planId) throw new Error("Missing PlanId");
        
        const me = this;
        const url = '/api/plan/' + planId;

        return await HttpService.instance.getAsync(url).then((rsp) => {
            const p = new PlanModel(rsp?.data);
            me.planMap[planId] = p;
            return p;
        }).catch((err) => console.error('Failed to get plans: ' + err));
    }

    async createPlanAsync(plan) {
        alert("Not implemented or otherwise not published yet.");
    }
    
    async updatePlanAsync(plan) {
        alert("Not implemented or otherwise not published yet.");
    }
    
    setPlans(jsonArray) { 
        if (!Array.isArray(this.plans)) this.plans = [];
        if (!jsonArray || !Array.isArray(jsonArray)) return this.plans;
        
        this.plans = PlanModel.fromJsonArray(jsonArray);
        
        return this.plans;
    }
}

export default PlanService;
