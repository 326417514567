import React, { useState, useEffect } from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp} from "@fortawesome/free-solid-svg-icons";
import ReportingSubMenu from "../../components/reporting/ui/ReportingSubMenu";
import DateRangeSelector from "@paylani/paylani-react-packages/dist/common/ui/date-range-selector/DateRangeSelector";
import PartnerService from "../../components/partners/services/PartnerService";
import {Link} from "react-router-dom";
import ReportingService from "../../components/reporting/services/ReportingService";
import BatchResultTypeTrendSeriesModel from "../../components/reporting/models/BatchResultTypeTrendSeriesModel";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import ReportItemModel from "../../components/reporting/models/ReportItemModel";
import BarChartGraph from "../../components/reporting/ui/BarChartGraph";

const BatchReportsScreen = (props) => {
    ChartJS.register(
        CategoryScale,
        BarElement,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        ArcElement
    );
    
    let _;
    const {} = props;

    const batchReportKey = 'batch-report';
    const partnerReportKey = 'partner-report';
    const monthlyCardResultsKey = 'monthly-card-results';
    const monthlyBatchSubmissionsKey = 'monthly-batch-submissions';
    const monthlyCardSubmissionsKey = 'monthly-card-submissions';

    const lineTitles = [
        "Card Update Types",
        "All Card Submissions + Updates",
        "Batch Submissions",
    ];

    let ed = new Date().addDays(1);
    let sd = ed.toFirstOfTheYear();
    
    const initDate = ReportItemModel.createDateRange(sd, ed);

    let [dateRange, setDateRange] = useState(initDate);

    let [partnerStats, setPartnerStats] = useState([]);
    let [batchActivity, setBatchActivity] = useState(PartnerService.instance.batchActivity || []);
    let [monthlyCardResults, setMonthlyCardResults] = useState(ReportingService.instance.reportMap[ReportItemModel.getKeyFromDate(monthlyCardResultsKey, initDate.startDate, initDate.endDate)] || []);
    let [monthlyBatchSubmissions, setMonthlyBatchSubmissions] = useState(ReportingService.instance.reportMap[ReportItemModel.getKeyFromDate(monthlyBatchSubmissionsKey, initDate.startDate, initDate.endDate)] || []);
    let [monthlyCardSubmissions, setMonthlyCardSubmissions] = useState(ReportingService.instance.reportMap[ReportItemModel.getKeyFromDate(monthlyCardSubmissionsKey, initDate.startDate, initDate.endDate)] || []);
    
    let [sortKey, setSortKey] = useState({ key: 'x', mult: 0 });
    let [lineGraphState, setLineGraphState] = useState(0);
    let [monthlyResultTypes, setMonthlyResultTypes] = useState(new BatchResultTypeTrendSeriesModel());
    
    const getBatchResultTrendDataAsync = async (force, sd, ed) => {
        let dr = (!!sd && !!ed) ? ReportItemModel.createDateRange(sd, ed) : dateRange;
        let cacheKey = ReportItemModel.getKeyFromDate(batchReportKey, dr.startDate, dr.endDate);
        let data = ReportingService.instance.reportMap[cacheKey];
        
        return await ReportingService.instance.getMasterBatchResultTypesByMonthAsync(dr.startDate, dr.endDate, cacheKey).then((resultSeries) => {
            console.log('Got batch result trends: ');
            setMonthlyResultTypes(resultSeries);
            
            return resultSeries;
        });
    };
    
    const getPartnerStatsDataAsync = async (force, sd, ed) => {
        let dr = (!!sd && !!ed) ? ReportItemModel.createDateRange(sd, ed) : dateRange;
        let cacheKey = ReportItemModel.getKeyFromDate(partnerReportKey, dr.startDate, dr.endDate);
        let data = ReportingService.instance.reportMap[cacheKey];

        if (!!data && !force) {
            console.log('Set Cached Monthly Results: ' + cacheKey)
            setPartnerStats(data);
            return data;
        }

        return await ReportingService.instance.getPartnerStatsAsync(dr.startDate, dr.endDate, cacheKey).then((stats) => {
            setPartnerStats(stats);
            return stats;
        });
    };
    
    const getMonthlyResultsDataAsync = async (force, sd, ed) => {
        let dr = (!!sd && !!ed) ? ReportItemModel.createDateRange(sd, ed) : dateRange;
        let cacheKey = ReportItemModel.getKeyFromDate(monthlyCardResultsKey, dr.startDate, dr.endDate);
        let data = ReportingService.instance.reportMap[cacheKey];

        if (!!data && !force) {
            console.log('Set Cached Monthly Results: ' + cacheKey)
            setMonthlyCardResults(data);
            return data;
        }
        
        return await ReportingService.instance.getMasterCardUpdatesByMonthAsync(dr.startDate, dr.endDate, cacheKey).then((stats) => {
            setMonthlyCardResults(stats);
            return stats;
        });
    };

    const getMonthlySubmissionsDataAsync = async (force, sd, ed) => {
        let dr = (!!sd && !!ed) ? ReportItemModel.createDateRange(sd, ed) : dateRange;
        let cacheKey = ReportItemModel.getKeyFromDate(monthlyBatchSubmissionsKey, dr.startDate, dr.endDate);
        let data = ReportingService.instance.reportMap[cacheKey];

        if (!!data && !force) {
            console.log('Set Cached Monthly Batch Submissions: ' + cacheKey)
            setMonthlyBatchSubmissions(data);
            return data;
        }
        
        return await ReportingService.instance.getMasterBatchSubmissionsByMonthAsync(dr.startDate, dr.endDate, cacheKey).then((stats) => {
            setMonthlyBatchSubmissions(stats);
            return stats;
        });
    };
    
    const getMonthlyCardSubmissionsDataAsync = async (force, sd, ed) => {
        let dr = (!!sd && !!ed) ? ReportItemModel.createDateRange(sd, ed) : dateRange;
        let cacheKey = ReportItemModel.getKeyFromDate(monthlyCardSubmissionsKey, dr.startDate, dr.endDate);
        let data = ReportingService.instance.reportMap[cacheKey];

        if (!!data && !force) {
            console.log('Set Cached Monthly Card Submissions: ' + cacheKey)
            setMonthlyCardSubmissions(data);
            return data;
        }

        return ReportingService.instance.getMasterCardSubmissionsByMonthAsync(dr.startDate, dr.endDate, cacheKey).then((stats) => {
            setMonthlyCardSubmissions(stats);
            return stats;
        });
    };

    const refreshData = (force, sd, ed) => {
        _ = getBatchResultTrendDataAsync(force, sd, ed);
        _ = getPartnerStatsDataAsync(force, sd, ed);
        _ = getMonthlyResultsDataAsync(force, sd, ed);
        _ = getMonthlySubmissionsDataAsync(force, sd, ed);
        _ = getMonthlyCardSubmissionsDataAsync(force, sd, ed);
    };

    const onDateRangeChange = (sd, ed) => {
        let dr = ReportItemModel.createDateRange(sd, ed);
        setDateRange(dr);

        refreshData(true, sd, ed);
    };

    useEffect(() => {
        refreshData()
    }, []);

    const nameStyle = { width: '40%' };
    const countStyle = { width: '15%' };
    const averageLowPercent = 0.05;
    const averageHighPercent = 0.2;
    const goodPercent = 0.10;

    let stats = (sortKey.mult === 0) ? (partnerStats || []) : partnerStats.sort((a, b) => {
        return (b[sortKey.key] - a[sortKey.key]) * sortKey.mult;
    });

    let partnerActivityListElements = stats.map((item) => {
        let pStyle = { width: '15%' };

        if (item.updateRate !== 0) {
            if (item.updateRate > averageHighPercent) pStyle.color = 'var(--yellow)';
            else if (item.updateRate < averageLowPercent) pStyle.color = 'var(--warning-red)';
            else if (item.updateRate > goodPercent) pStyle.color = 'var(--paylani-green-faded)';
        }
        
        return (
            <tr key={'partner-report-' + item.partnerId}>
                <td style={nameStyle}><Link to={"/partners/" + item.partnerId}>{item.partnerName}</Link></td>
                <td style={countStyle}>{item.subMerchantCount.formatNumber(0)}</td>
                <td style={countStyle}>{item.batchCount.formatNumber(0)}</td>
                <td style={countStyle}>{item.submissionCount.formatNumber(0)}</td>
                <td style={countStyle}>{item.updateCount.formatNumber(0)}</td>
                <td style={pStyle}>{item.updateRate.toPercent(2)}</td>
            </tr>
        );
    });
 
    const sortPartnerActivity = (key) => {
        let mult = sortKey.key === key ? sortKey.mult * -1 : 1;
        setSortKey({ key: key, mult: mult });
    };
    
    const createMonthlyDataSets = (title) => {
        let dataSets = [];
        let labels = [];
        
        switch(lineGraphState){
            case 0:
                return monthlyResultTypes.toLineChartDataSet(1, 1.5);
            case 2:
                labels = monthlyBatchSubmissions.map((x) => x.label);
                dataSets = [
                    ReportItemModel.createLineChartSeries(monthlyBatchSubmissions, title || "Batch Submissions", '#FFFF0099'),
                ];
                break;
            default:
                labels = monthlyCardResults.map((x) => x.label);
                dataSets = [
                    ReportItemModel.createLineChartSeries(monthlyCardSubmissions, title || "Card Submissions", '#88CCDDFF'),
                    ReportItemModel.createLineChartSeries(monthlyCardResults, title || "Card Updates", '#3BD23DFF'),
                ];
                break;
        }

        return {
            labels: labels,
            datasets: dataSets
        };
    };
    
    let cardUpdateTrendData = createMonthlyDataSets();

    let controlSelections = ['', ''];
    controlSelections[lineGraphState] = 'selected';
    
    let controls = (<span id="home-line-controls">
        <a className={controlSelections[0]} onClick={(e) => setLineGraphState(0)}>{ lineTitles[0] }</a>
        <a className={controlSelections[1]} onClick={(e) => setLineGraphState(1)}>{ lineTitles[1] }</a>
        <a className={controlSelections[2]} onClick={(e) => setLineGraphState(2)}>{ lineTitles[2] }</a>
    </span>);

    console.warn('Batch reports rendered!');
    
    let mainGraph = (<BarChartGraph title={lineTitles[lineGraphState]} data={cardUpdateTrendData} chartControls={controls} />);
    //    (<CardBrandLineGraph title={lineTitles[lineGraphState]} data={cardUpdateTrendData} chartControls={controls} />) :
       

    return (
        <MasterScreenMain selection="reporting">
            <div id="reporting-" className="pad">
                <h1>
                    <FontAwesomeIcon icon={faArrowTrendUp} />
                    Reporting
                </h1>

                <div className="details-body form">
                    <ReportingSubMenu selection="batches" />

                    <div className="pad">
                        <h2>Batches and Updates</h2>

                        <p>
                        </p>
                        
                        <DateRangeSelector startDate={dateRange.startDate} endDate={dateRange.endDate} onRefresh={onDateRangeChange} />

                        {mainGraph}

                        <h3 className={"subtitle"}>Partner Activity Stats</h3>
                        <table className="table-x-large" width={"100%"}>
                            <thead>
                                <tr>
                                    <th style={nameStyle}>Partner Name</th>
                                    <th style={countStyle}><a onClick={() => sortPartnerActivity("subMerchantCount")}>Submerchants</a></th>
                                    <th style={countStyle}><a onClick={() => sortPartnerActivity("batchCount")}>Batches</a></th>
                                    <th style={countStyle}><a onClick={() => sortPartnerActivity("submissionCount")}>Cards</a></th>
                                    <th style={countStyle}><a onClick={() => sortPartnerActivity("updateCount")}>Updates</a></th>
                                    <th style={countStyle}><a onClick={() => sortPartnerActivity("updateRate")}>Percentage</a></th>
                                </tr>
                            </thead>
                            
                            <tbody>
                            {partnerActivityListElements}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </MasterScreenMain>
    );
    
};

export default BatchReportsScreen;
