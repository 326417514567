import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import StatementModel from "../../partners/models/StatementModel";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";

class StatementService { 
    static instance = new StatementService();

    constructor() {
        this.statementMap = {};
    }
    
    async getBillingInfoAsync(partnerId) {
        //
    }
    
    async getStatementStatusAsync(statementId) {
        const path = '/api/statement/' + statementId;
        
        return await HttpService.instance.getAsync(path).then((rsp) => {
            return new StatementModel(rsp.data);
        });
    }

    async createStatementAsync(partnerId, serviceDate) {
        if (DateTime.isDate(serviceDate)) serviceDate = serviceDate.toDate().toFormDate();
        else serviceDate = '';

        let path = '/api/partner/' + partnerId + '/statement';
        let data = {};
        
        return await HttpService.instance.postAsync(path, data).then((rsp) => {
            return new StatementModel(rsp.data);
        });
    }
    
    async updateStatementStatusAsync(statementId, status) {
        if (typeof status !== 'number') status = 1;
        const path = '/api/statement/' + statementId;

        return await HttpService.instance.getAsync(path).then((rsp) => {
            return new StatementModel(rsp.data);
        });
    }
    
    async getGatewayPaymentStatusAsync(paymentId) {
        const path = "/api/payment/" + paymentId + "/status";
        
        return await HttpService.instance.getAsync(path).then((rsp) => {
            return rsp.data;
        });
    }
}

export default StatementService;
