import React, {useEffect, useState} from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressCard,
    faBuildingColumns,
    faCircleCheck,
    faCloudArrowDown,
    faCreditCard, faIdBadge, faPenNib,
    faPeopleGroup,
    faVcard
} from "@fortawesome/free-solid-svg-icons";
import PartnerSubMenu from "../../components/partners/ui/PartnerSubMenu";
import '../../components/partners/ui/Partners.css';

import {Link, useParams} from "react-router-dom";
import BackButton from "../../components/common/ui/BackButton";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import MerchantSubMenu from "../../components/partners/ui/MerchantSubMenu";
import MerchantForm from "../../components/partners/ui/MerchantForm";

import MerchantModel from "../../components/partners/models/MerchantModel";
import PartnerModel from "../../components/partners/models/PartnerModel";
import PartnerService from "../../components/partners/services/PartnerService";

const MerchantEditScreen = (props) => {
    const {partnerId, merchantId} = useParams();

    let [partner, setPartner] = useState(PartnerService.instance.partnerMap[partnerId] || new PartnerModel());
    let [merchant, setMerchant] = useState(PartnerService.instance.subMerchantMap[merchantId] || new MerchantModel()); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));

    useEffect(() => {
        if (!partner.hasDetails) PartnerService.instance.getPartnerAsync(partnerId)
            .then((partner) => {
                console.log('Got Partner: ' + partner.customer.toJson().first_name);
                setPartner(partner);
            })
            .catch((error) => console.log('Ex: ' + error));

        PartnerService.instance.getSubMerchantAsync(merchantId, partnerId).then((subMerchant) => {
            console.log('Got SubMerchant: ' + subMerchant.name);
            setMerchant(subMerchant);
        });

    }, []);
    
    return (
        <MasterScreenMain selection="partners">
            <div className="pad" id="partner-merchant-details">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    {partner.name}
                </h1>

                <div className="details-body">
                    <PartnerSubMenu partner={partner} selection="enrollments" />

                    <div className="pad">
                        <h2>
                            <BackButton to={'/partners/' + partnerId + '/enrollments'} />
                            {merchant.name}:
                            <span className="subtitle">Details</span>
                        </h2>

                        <MerchantSubMenu partner={partner} merchant={merchant} selection={"edit"} />

                        <p>
                            This merchant appears as "{merchant.name}" on reporting, and is for display purposes.<br/>
                            <Link to={"/partners/" + partnerId}>{partner.name}</Link> is the parent Partner of
                            {merchant.name}.
                        </p>

                        <h3 style={{marginTop: '64px', marginBottom: '32px'}}>
                            <FontAwesomeIcon icon={faPenNib} />
                            Edit Merchant Details on File
                        </h3>
                        
                        <MerchantForm merchant={merchant} useButton={true} />
                        
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
};

export default MerchantEditScreen;
