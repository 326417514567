import React, {useState, useRef, useEffect} from 'react';
import PlansMasterScreen from "./PlansMasterScreen";
import {useParams} from "react-router-dom";
import PlanService from "../../components/plans/services/PlanService";
import PlanForm from "../../components/plans/ui/PlanForm";

const PlanEditScreen = (props) => {
    const { planId } = useParams();

    const [plan, setPlan] = useState(PlanService.instance.planMap[planId] || null);
    let _;
    
    const getPlanAsync = async (force) => {
        if (!force && !!plan?.id) return plan;
        
        console.warn("Getting PlanId: " + planId);
        
        return await PlanService.instance.getPlanAsync(planId).then((p) => {
            if (!!p) {
                setPlan(p);
                return p;
            }
            
            return plan;
        });
    };

    const updatePlanAsync = async (json, e) => {
        console.log(JSON.stringify(json));
        
        return await new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(true);
            }, 2000);
        });
    };
    
    const onComplete = async (e) => {
        console.log("Complete.");
    };
    
    useEffect(() => {
        _ = getPlanAsync();
    }, []);

    const title = !!planId && planId.length > 30 ? (<>Edit Plan: <span className={"subtitle"}>{plan?.name}</span></>) : "Create New Plan";
    const planForm = !!plan?.id ? (<PlanForm plan={plan} onClick={updatePlanAsync} onComplete={onComplete} />) : null;
    
    return (<PlansMasterScreen bodyClassName={"large"} title={title}>
        <p>Plans dictate how much each partner will pay for service. In addition to the fees, only the <strong>
            Plan Name</strong> will be seen by the partner. I.e., the description will not</p>

        { planForm }
        
    </PlansMasterScreen>)
};

export default PlanEditScreen;
