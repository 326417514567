import React from 'react';
import {Link} from "react-router-dom";

const MerchantSubMenu = (props) => {
    const { partner, merchant, selection } = props;

    const partnerId = partner.id;
    const merchantId = merchant.id;
    
    let sel = selection || '';
    let selections = {};
    
    selections[sel] = 'selected';
    
    return (
        <div className="submenu merchant">
            <Link className={selections['batches']} to={'/partners/' + partnerId + '/merchants/' + merchantId + ''}>Batches</Link>
            <Link className={selections['details']} to={'/partners/' + partnerId + '/merchants/' + merchantId + '/details'}>Enrolled Details</Link>
            <Link className={selections['edit']} to={'/partners/' + partnerId + '/merchants/' + merchantId + '/edit'}>Edit Account Details</Link>
        </div>
    );
};

export default MerchantSubMenu;

