import {faArrowsRotate, faCircleCheck, faCloudArrowDown, faHourglass} from "@fortawesome/free-solid-svg-icons";
import BatchResultModel from "./BatchResultModel";
import MerchantModel from "../../partners/models/MerchantModel";

class BatchModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        this.batchId = json.batch_id || null;
        this.pickupDate = json.pickup_date;
        this.isPickedUp = json.pickup_date !== null;
        this.batchStatus = json.batch_status || 0;
        this.batchStatusName = json.batch_status_name || 'pending';
        this.lastCheckDate = json.last_check_date;
        
        this.submittedCount = json.submitted_count;
        
        this.noChangeCount = json.no_change_count;
        this.noMatchCount = json.no_match_count;
        
        this.cardNumberUpdateCount = json.card_number_update_count;
        this.cardExpiryUpdateCount = json.card_expiry_update_count;
        this.validCount = json.valid_count;
        this.contactCount = json.contact_count;
        this.contactClosedCount = json.contact_closed_count;
        
        this.updateCount = this.cardNumberUpdateCount +
            this.cardExpiryUpdateCount +
            this.validCount +
            this.contactCount +
            this.contactClosedCount;
        
        this.providerType = json.card_sync_provider_type;
        this.remoteId = json.remote_id;
        this.token = json.token;
        this.expires = json.batch_expires;
        this.batchFileName = json.batch_file_name;
        this.message = json.message;
        this.processId = json.card_sync_batch_process_id;
        this.status = json.status || 0;
        this.created = json.created || '';
        this.modified = json.modified;
        this.results = !!json.results && Array.isArray(json.results) ? BatchResultModel.fromJsonArray(json.results) : [];
        
        this.visaCount = json.visa_count || 0;
        this.discoverCount = json.discover_count || 0;
        this.masterCardCount = json.mastercard_count || 0;
        this.amexCount = json.amex_count || 0;

        this.visaSubmitted = json.visa_submitted || 0;
        this.discoverSubmitted = json.discover_submitted || 0;
        this.masterCardSubmitted = json.mastercard_submitted || 0;
        this.amexSubmitted = json.amex_submitted || 0;
        
        this.subMerchantId = json.card_sync_merchant_id || '';
        this.subMerchant = new MerchantModel(json.merchant);
        
        console.log(JSON.stringify(this, null, 4));
    }
    
    static fromJsonArray(jsonArray) {
        return jsonArray.map((json) => new BatchModel(json));
    }
    
    setCounts(newBatch) { 
        this.visaCount = newBatch.visaCount;
        this.discoverCount = newBatch.discoverCount;
        this.masterCardCount = newBatch.masterCardCount;
        this.amexCount = newBatch.amexCount;
        
        this.validCount = newBatch.validCount;
        this.contactCount = newBatch.contactCount;
        this.contactClosedCount = newBatch.contactClosedCount;
        this.cardNumberUpdateCount = newBatch.cardNumberUpdateCount;
        this.cardExpiryUpdateCount = newBatch.cardExpiryUpdateCount;
        this.submittedCount = newBatch.submittedCount;
        this.noChangeCount = newBatch.noChangeCount;
        this.noMatchCount = newBatch.noMatchCount;
        
        return this;
    }
    
    getIcon() { 
        if (this.pickupDate !== null) return faCircleCheck;
        if (this.batchStatusName === null) return faCloudArrowDown;
        
        switch(this.batchStatusName.toLowerCase()) {
            case 'pending':
                return faHourglass;
            case 'processing':
                return faArrowsRotate;
        }
        
        return faCloudArrowDown;
    }
}

export default BatchModel;
