import PartnerModel from "./PartnerModel";
import UserModel from "@paylani/paylani-react-packages/dist/people/models/UserModel";

class MerchantModel { 
    constructor(json, partner = null) {
        if (!json) json = {};
        
        this.id = json.id;
        this.mid = json.mid || json.cardsync_identifier;
        
        this.name = json.name || '';
        this.email = json.email || '';
        this.phone = json.phone || '';
        this.website = json.website || '';
        this.status = json.status;
        this.providerType = json.provider;
        this.providerName = json.provider_name || '';
        this.providerAssignDate = json.provider_assign_date;

        this.partnerId = json.partner_id || '';
        this.partner = partner || new PartnerModel(json.partner);

        this.user = new UserModel(json.user);
        this.userDate = json.user?.created || json.created;
        
        // Enrollment
        let enrollment = json.enrollment || {};
        
        this.enrollmentName = enrollment.name;
        this.enrollmentId = enrollment.id;
        this.enrollmentStatus = enrollment.status;
        this.visaCount = enrollment.visa_count;
        this.masterCardCount = enrollment.master_card_count;
        this.discoverCount = enrollment.discover_count;
        this.amexCount = enrollment.amex_count;
        this.approveDate = enrollment.approve_date?.toDate() ||  null;
        this.cancelDate = enrollment.cancel_date;
        
        this.created = json.created?.toDate() || null;
        this.modified = json.modified?.toDate() ?? null;
        
        this.providerStatus = "Pending Approval";
        this.providerStatusDescription = "This merchant needs to be approved.";
        
        if (this.enrollmentStatus === 1) { 
            if (!!enrollment.approve_date) {
                this.providerStatus = "Approved";
                this.providerStatusDescription = "This is approved and awaiting Enrollment finalization.";
                
                if (!!this.providerAssignDate) {
                    this.providerStatus = "Enrolled";
                    this.providerStatusDescription = "Enrollment finalized :)";
                }
            }
        }
        
        this.providerStatusKey = this.providerStatus.replaceAll(' ', '-').toLowerCase();
    }
    
    trimmedName(maxLength, ellipsis = '...') { 
        if (!this.name) return this.name;
        if (typeof maxLength !== 'number' || maxLength > this.name.length) return this.name;
        
        return this.name.substring(0, maxLength) + ellipsis;
    }
    
    toJson() { 
        return {
            id: this.id,
            name: this.name,
            email: this.email,
            phone: this.phone,
            website: this.website
        };
    }
}

export default MerchantModel;