import React from 'react';
import {Link} from "react-router-dom";

const PartnerOverviewSubMenu = (props) => {
    const { partner, selection } = props;

    const partnerId = partner.id;

    let sel = selection || 'main';
    let selections = {};
    selections[sel] = 'selected';

    return (
        <div className="submenu merchant">
            <Link className={selections['main']} to={'/partners/' + partnerId + ''}>Main</Link>
            <Link className={selections['batches']} to={'/partners/' + partnerId + '/batchactivity'}>Batch Activity</Link>
            <Link className={selections['cardbrands']} to={'/partners/' + partnerId + '/cardbrandactivity'}>Card Brand Activity</Link>
        </div>
    );

};

export default PartnerOverviewSubMenu;
