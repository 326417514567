import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MasterScreenMain from "../../MasterScreenMain";
import {Link, Navigate, useParams} from "react-router-dom";
import {
    faClose,
    faCopy,
    faDownLeftAndUpRightToCenter,
    faExpandAlt,
    faHandPeace,
    faReply
} from "@fortawesome/free-solid-svg-icons";
import BackButton from "../../components/common/ui/BackButton";
import '../../components/support/ui/Support.css';
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import SupportTicketService from "../../components/support/services/SupportTicketService";
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";
import Controller from "@paylani/paylani-react-packages/dist/common/controllers/Controller";
import {Tooltip} from "@mui/material";

const TicketDetailsScreen = () => {
    let _;
    const { threadId, supportTicketId } = useParams();
    const [thread, setThread] = useState(SupportTicketService.instance.threads?.find((th) => th.id === threadId) || null);
    const [redirectUrl, setRedirectUrl] = useState("");
    
    const initialState = {
        // preThreads: 0,
        // postThreads: 0,
        // form: 0,
        expanded: 0,
        delete: "",
        reply: "",
    };
    
    const [viewState, setViewState] = useState(initialState);
    const ticketId = supportTicketId || (thread?.recentTicket?.id || null);
    
    const initTicket = thread?.tickets?.find((t) => t.id === ticketId);
    const [ticket, setTicket] = useState(initTicket || null);
    const [errorMessage, setErrorMessage] = useState("");
    
    const replyRef = useRef();
    const firstId = thread?.tickets?.length > 0 ? thread?.tickets[0].id : null;
    
    console.warn("ParamId: " + supportTicketId);
    console.log("TicketId: " + ticketId);
    console.log("Ticket.id: " + ticket?.id);
    
    const getThreadAsync = async (force) => {
        if (!force && thread?.id?.length > 30) return;
        
        const th = await SupportTicketService.instance.getSupportTicketThreadAsync(threadId);
        if (!!th?.id) setThread(th);
    };

    const getTicketAsync = async (force = false) => {
        if (!ticketId) {
            //console.error("No TicketId: " + ticketId);
            return ticket;
        }
        
        if (!force && !!ticket?.id && ticket.id === ticketId) {
            //console.warn("Ticket already loaded (2).");
            return ticket;
        }

        const existingTicket = (ticket?.id !== ticketId) ?
            thread?.tickets?.find((t) => t.id === ticketId) :
            null;
        
        if (!force && !!existingTicket?.id) {
            //console.warn("Setting Existing Ticket: " + existingTicket.id);
            setTicket(existingTicket);
            return existingTicket;
        }
        
        console.log("Getting Ticket: " + ticketId);
        const t = await SupportTicketService.instance.getSupportTicketAsync(ticketId);
        
        if (!!t?.id) setTicket(t);
        
        return t || ticket;
    };
    
    const deleteMessageAsync = async (deleteTicketId, execute = false) => {
        if (typeof deleteTicketId !== "string") return;
        
        if (!execute) {
            setViewState({...viewState, delete: deleteTicketId});
            return;
        }
        
        await SupportTicketService.instance.deleteSupportTicketAsync(deleteTicketId);
        await getThreadAsync(true);
        
        const mustLeave = ticketId === deleteTicketId;
        setViewState({...viewState, delete: ""});
        if (mustLeave) setRedirectUrl("/support/" + threadId);
    };

    const executeActionsAsync = async () => {
        if (!!viewState.delete) await deleteMessageAsync(viewState.delete, true);
    };
    
    const focusReply = () => {
        setTimeout(() => {
            replyRef.current?.focus();
        }, 350);
    };
    
    const submitReplyAsync = async (supportTicket) => {
        const body = (replyRef.current?.value || "").trim();
        if (!body) return;
        
        const id = supportTicket?.id || ticketId;
        
        const data = {
            thread_id: threadId,
            reply_to_id: id,
            body: body,
        };
        
        const newTicket = await SupportTicketService.instance.createSupportTicketAsync(data).catch((err) => {
            const errorMessage = err?.response?.data?.message || "Error: " + JSON.stringify(err, null, 4);
            setErrorMessage(errorMessage);
            
            return null;
        });
        
        if (!newTicket?.id) return;
        
        await getThreadAsync(true);
        
        setViewState({...viewState, reply: ""});
        setRedirectUrl("/support/" + threadId + "/" + newTicket.id);
    };
    
    const scrollToContent = () => {
        setTimeout(() => {
            const content = document.getElementById("content-" + ticketId);
            if (!!content) {
                if (ticketId !== firstId) {
                    content.scrollIntoView();
                    return;
                }

                content.scrollTop = 0;
            }

        }, 100);
    };
    
    const copyText = (text) => {
        Controller.copyText(text).then((result) => {
            //
        });
    };
    
    useEffect(() => {
        if (!!redirectUrl) setRedirectUrl("");
    }, [redirectUrl]);
    
    useEffect(() => {
        _ = getThreadAsync();
        _ = executeActionsAsync();
        
        if (!!viewState.reply) focusReply();
        else if (!!viewState.expanded) scrollToContent();
        
    }, [viewState]);

    useEffect(() => {
        _ = getTicketAsync();
        
    }, [thread]);

    useEffect(() => {
        _ = getTicketAsync();
    }, [supportTicketId]);
    
    if (!!redirectUrl) return (<Navigate to={redirectUrl} />);

    const errorElement = !!errorMessage ? (<div className={"error"}>{ errorMessage }</div>) : null;

    const createTicketBody = (supportTicket) => {
        if (!supportTicket?.id) return null;
        
        let body = supportTicket.body;
        const isSelected = supportTicket.id === ticketId;
        const senderName = supportTicket?.isReply ? "CardSync Support" : supportTicket?.merchantName;
        const readDateElement = !!supportTicket?.readDate ? (<span className={"read-date"}>Read on <DateTime value={supportTicket?.readDate} time={true} /></span>) : null;

        if (typeof body === 'string' && body.length > 0) {
            body = body.replaceAll('&lt;', '<');
            body = body.replaceAll('&gt;', '>');
            body = body.replaceAll('&amp;', '&');
        }

        const replyForm = viewState.reply === supportTicket.id ? (<div className={"form"}>
            <div className={"reply-form"}>
                <div className={"reply-as"}>Replying as </div>
                { errorElement }
                <textarea placeholder="Reply..." ref={replyRef}></textarea>
                <div className={"reply-buttons"}>
                    <FormButton onClick={submitReplyAsync}>Reply</FormButton>
                    <span><a onClick={() => setViewState({...viewState, reply: "" })}>Cancel</a></span>
                </div>
            </div>
        </div>) :  null;

        const cn = viewState.expanded ? "ticket" + (isSelected ? "-selected" : "") : "";
        const deleteElement = viewState.delete === supportTicket.id ?
            (<a>Deleting...</a>) :
            (<a onClick={() => deleteMessageAsync(supportTicket.id)}><FontAwesomeIcon icon={faClose}/> Delete</a>);
        
        return (<div id={"content-" + supportTicket.id} className={("body " + cn).trim()}>
            <div className="subject">
                <span>Subject: </span>

                {supportTicket?.subject}

                <span className={"action-buttons"}>
                    { deleteElement }
                    <a onClick={() => copyText(supportTicket.body)}><FontAwesomeIcon icon={faCopy}/> Copy</a>
                    <a onClick={() => setViewState({...viewState, reply: supportTicket.id})}><FontAwesomeIcon icon={faReply}/> Reply</a>
                </span>
            </div>

            <p>{body}</p>
            
            {replyForm}

            <div className="date">
                <Tooltip title={supportTicket.fromEmail || "No Email"}><span className="from">From: <Link to={"/partners/" + supportTicket?.merchantId}>{senderName}</Link> ({supportTicket?.fromName})</span></Tooltip>

                <span>Sent: <DateTime value={supportTicket?.sentDate} time={true}/></span>
                {readDateElement}

                <span>
                    {supportTicket?.messageId || "000-000"}
                </span>
            </div>
        </div>);
    };
    
    const tickets = thread?.tickets || [];
    const previousTickets = tickets?.filter((t) => t.sentDate.toDate() < ticket?.sentDate.toDate() && t.id !== ticketId) || [];
    const nextTickets = tickets?.filter((t) => t.sentDate.toDate() > ticket?.sentDate.toDate() && t.id !== ticketId) || [];

    const createExpandedThreadMessageElements = (t, key) => {
        if (!key) key = "expanded-" + t.id;
        
        let preview = t.subject;
        
        if (t.subject === ticket?.subject) { 
            preview = t.body;
        }
        
        return (<div key={key} className={"collapsed-ticket"}>
            <Link to={"/support/" + threadId + "/" + t.id}>
                <span className={"date"}><DateTime value={t?.sentDate} time={true} /></span>
                <span className={"name"}>{ t.fromName }</span>
                <span className={"preview"}>{ preview }</span>
            </Link>
        </div>);
    };
    
    const createThreadSummaryElement = (items, title, onClick) => {
        if (items.length === 0) return null;
        return (<div className={"thread-summary"}><a onClick={() => onClick()}>{ title || "More Messages" } ({ items.length })</a></div>);
    };
    
    const createPreviousElements = () => {
        if (viewState.expanded > 0) {
            return previousTickets.map((t) => createTicketBody(t));
        }
        
        return viewState.preThreads === 1 ? previousTickets.map((t, i) => {
            return createExpandedThreadMessageElements(t, "pre-" + i);
        }) : createThreadSummaryElement(previousTickets,"Previous Messages", () => setViewState({...viewState, preThreads: 1}));
    };
    
    const createNextElements = () => {
        if (viewState.expanded > 0) {
            return nextTickets.map((t) => createTicketBody(t));
        }
        
        return viewState.postThreads === 1 ? nextTickets.map((t, i) => {
            return createExpandedThreadMessageElements(t, "post-ticket-" + i);
        }) : createThreadSummaryElement(nextTickets, "Additional Messages", () => setViewState({...viewState, postThreads: 1}));        
    };
    
    const previousElements = createPreviousElements();
    const nextElements = createNextElements();
    const ticketBodyElement = createTicketBody(ticket);
    
    const expandElement = viewState.expanded === 0 ? (<div className={"expand"}><a onClick={() => setViewState({...viewState, expanded: 1})}><FontAwesomeIcon icon={faExpandAlt} /> Expand Messages</a></div>) : 
        (<div className={"expand"}><a onClick={() => setViewState({...viewState, reply: "", expanded: 0})}><FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} /> Collapse Messages</a></div>);
    
    return (
        <MasterScreenMain selection="support">
            <div className="pad">
                <h1>
                    <FontAwesomeIcon icon={faHandPeace} />
                    Customer Support
                </h1>

                <div className="details-body" id="customer-support">
                    <div className="pad">
                        <h2 className={"support-ticket-header"}>
                            <BackButton to={"/support"} />
                            Ticket: { ticket?.subject }
                            <FontAwesomeIcon icon={ticket?.getIcon()} color={ticket?.getColor()} className="ticket-type-icon" />
                            
                            <span className={"action-buttons"}>
                                { expandElement }
                            </span>
                        </h2>
                        
                        <div>{ previousElements }</div>
                        { ticketBodyElement }
                        <div>{ nextElements }</div>
                    </div>
                </div>
            </div>
        </MasterScreenMain>
    );
    
};

export default TicketDetailsScreen;