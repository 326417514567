import React from 'react';
import {Bar} from 'react-chartjs-2';

const BarChartGraph = (props) => {
    const {title, options, data, chartControls, onMouseMove, subtitle, subTitle} = props;

    if (!data) return (<>Nada</>);
    
    let labels = data.labels;

    const barOptions = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: 12,
        },
        scales: {
            x: {
                grid: {
                    drawBorder: false,
                    color: 'rgba(255, 255, 255, 0.05)',
                }
            },
            y: {
                grid: {
                    drawBorder: false,
                    color: 'rgba(255, 255, 255, 0.05)',
                },
            }
        },
        plugins: {
            legend: { position: 'top' },
            title: { display: false }
        }
    };
    
    let header = (<></>);
    let footer = (<></>);

    let chartSubtitle = subtitle || subTitle;
    let chartTitle = title;
    let controls = chartControls ? (<span className="line-controls">{chartControls}</span>) : (<></>);

    if (chartTitle || chartControls) {
        header = (<h3 className="bar-chart-graph-header">
            <span>{chartTitle}</span>
            {controls}
        </h3>);
    }

    if (chartSubtitle) footer = (<div className="bar-chart-graph-footer">{chartSubtitle}</div>);
    
    return (<div className="bar-chart-graph">
        {header}
        <div className="bar-chart-graph-container">
            <Bar height={"272"} options={barOptions} data={data} onMouseMove={onMouseMove} />
        </div>
        {footer}
    </div>);
};

export default BarChartGraph;

