import React from 'react';
import MasterScreenMain from "../../MasterScreenMain";
import {
    faBriefcase,
    faCloudSun,
    faComments,
    faGears,
    faPersonChalkboard,
    faRobot,
    faShieldHalved
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SettingsSubMenu from "../../components/settings/ui/SettingsSubMenu";
import {Link} from "react-router-dom";

const SettingsScreen = (props) => {
    
    return (
        <MasterScreenMain selection="settings">
            <div className="pad">
                <h1><FontAwesomeIcon icon={faGears} /> Settings</h1>

                <div className="details-body">
                    <SettingsSubMenu selection="overview" />
                    
                    <div className="pad">
                        <h2>Settings Main</h2>

                        <p>
                            This section will allow you to manage your settings... Eventually.
                        </p>
                        
                        <div id={'settings-main'}>
                            <div className={"box dark round24"}>
                                <div>
                                    <h4>Automation</h4>
                                    <Link to={"/settings/automation/jobs"}><FontAwesomeIcon icon={faRobot} /></Link>
                                    <p>
                                        Configure when jobs run and what they do
                                    </p>
                                </div>
                            </div>

                            <div className={"box dark round24"}>
                                <div>
                                    <h4>Plans</h4>
                                    <Link to={"/plans"}><FontAwesomeIcon icon={faPersonChalkboard} /></Link>
                                    <p>
                                        Manage plans and pricing for Partners
                                    </p>
                                </div>
                            </div>                            
                            
                            <div className={"box dark round24"}>
                                <div>
                                    <Link to={"/settings/company"}>
                                        <h4>Company Information</h4>
                                        <FontAwesomeIcon icon={faBriefcase} />
                                        <p>
                                            Comany name and contact information
                                        </p>
                                    </Link>
                                </div>
                            </div>

                            <div className={"box dark round24"}>
                                <div>
                                    <Link to={"/settings/system-messages"}>
                                        <h4>Platform Emails</h4>
                                        <FontAwesomeIcon icon={faComments} />
                                        <p>
                                            Messages sent out from the platform
                                        </p>
                                    </Link>
                                </div>
                            </div>

                            <div className={"box dark round24"}>
                                <div>
                                    <Link to={"/settings/pci"}>
                                        <h4>Compliance</h4>
                                        <FontAwesomeIcon icon={faShieldHalved} />
                                        <p>
                                            Policies and procedures, including PCI
                                        </p>
                                    </Link>
                                </div>
                            </div>

                            <div className={"box dark round24"}>
                                <div>
                                    <Link to={"/settings/system"}>
                                        <h4>Environments</h4>
                                        <FontAwesomeIcon icon={faCloudSun} />
                                        <p>
                                            Change to/from Production and Staging
                                        </p>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>  
            </div>
        </MasterScreenMain>
    );
};

export default SettingsScreen;
