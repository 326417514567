import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAnchor, faCloudSun, faComments,
    faCreditCard, faDiagramProject,
    faFolderOpen, faGears,
    faIdCardClip, faKey,
    faLock,
    faPeopleGroup, faPersonChalkboard, faRobot, faShieldHalved, faSuitcase
} from "@fortawesome/free-solid-svg-icons";

const SettingsSubMenu = (props) => {
    let selections = {};
    let selector = props.selection || 'overview';
    
    selections[selector] = 'selected';

    return (
        <div className="sub-column">
            <ul>
                <li className="sub-menu-header">
                    More
                </li>
                <li className={selections['overview']}><Link to={ '/settings'}><FontAwesomeIcon icon={faGears} /> Overview</Link></li>
                <li className={selections['automation']}><Link to={ '/settings/automation/jobs'}><FontAwesomeIcon icon={faRobot} /> Automation</Link></li>
                <li className={selections['plans']}><Link to={ '/plans'}><FontAwesomeIcon icon={faPersonChalkboard} /> Plans</Link></li>
                <li className={selections['company']}><Link to={ '/settings/company'}><FontAwesomeIcon icon={faSuitcase} /> Company Info</Link></li>
                <li className={selections['messages']}><Link to={ '/settings/system-messages'}><FontAwesomeIcon icon={faComments} /> Platform Emails</Link></li>
                <li className={selections['pci']}><Link to={ '/settings/pci'}><FontAwesomeIcon icon={faShieldHalved} /> Compliance</Link></li>
                <li className={selections['env']}><Link to={ '/settings/system'}><FontAwesomeIcon icon={faCloudSun} /> Environments</Link></li>
            </ul>
        </div>  
    );
};

export default SettingsSubMenu;
