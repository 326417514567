import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import JobModel from "../models/JobModel";

class JobService { 
    static instance = new JobService();

    constructor() {
        this.jobs = [];
        this.lastFtpChekcs = {};
        this.getters = {};
    }

    /**
     * Kicks off the batch process job.
     * @param providerType {string|number|null} - Provider type to process. 1 = Fiserv, 2 = Worldpay, null = both
     * @returns {Promise<JobModel>|Promise<[JobModel]>}
     */
    async processBatchesAsync(providerType = "both") {
        if (typeof providerType === "string")
            providerType = providerType.toLowerCase();
        
        if (["worldpay", "wp", "2", 2].includes(providerType)) { 
            providerType = "/worldpay";
        } else if (["fiserv", "fs", "1", 1].includes(providerType)) {
            providerType = "/fiserv";
        } else {
            providerType = "";
        }
        
        const path = "/api/batch-process" + providerType
        console.log("Processing Batches: " + path);
        
        return await HttpService.instance.postAsync(path, {}).then((rsp) => {
            if (Array.isArray(rsp?.data)) return JobModel.fromJsonArray(rsp?.data);
            return new JobModel(rsp?.data);
        });
    }
    
    async getFtpFilesAsync(providerType = "both") {
        if (typeof providerType === "string")
            providerType = providerType.toLowerCase();

        let timerKey = "";
        
        if (["worldpay", "wp", "2", 2].includes(providerType)) {
            providerType = "/worldpay";
            timerKey = "worldpay";
        } else if (["fiserv", "fs", "1", 1].includes(providerType)) {
            providerType = "/fiserv";
            timerKey = "fiserv";
        } else {
            providerType = "/worldpay";
            timerKey = "worldpay";
        }

        const getterKey = "get-ftp-files-" + timerKey;
        
        if (!!this.getters[getterKey]) {
            console.warn("Still getting SFTP Files. Returning null.");
            return null;
        }

        this.getters[getterKey] = new Date();
        
        const me = this;
        const path = "/api/batch-process" + providerType
        console.log("Getting SFTP Files: " + path);

        return await HttpService.instance.getAsync(path, {}).then((rsp) => {
            delete me.getters[getterKey];
            me.lastFtpChekcs[timerKey] = new Date();
            return rsp?.data;
        }).catch((ex) => {
            delete me.getters[getterKey];
            console.error(ex);
            return { 
                file_count: -1,
                success: false,
                message: ex,
                files: []
            };
        });
    }
    
    async syncUsageAsync(startDate, endDate) {
        const me = this;
        const path = HttpService.instance.createUrlWithDateRange('/api/utilities/sync/usage', startDate, endDate);

        return await HttpService.instance.postAsync(path, {}).then((rsp) => {
            return new JobModel(rsp?.data);
        });
    }
    
    async purgeSensitiveDataAsync() {
        const me = this;
        const path = '/api/utilities/purge';

        return await HttpService.instance.postAsync(path, {}).then((rsp) => {
            return new JobModel(rsp?.data);
        });
    }

    async getJobHistoryAsync(startDate, endDate) {
        const me = this;
        const path = '/api/utilities/job';
        
        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            this.jobs = JobModel.fromJsonArray(rsp?.data);
            
            return this.jobs;
        });
    }
}

export default JobService;
