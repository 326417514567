class Tag {
    constructor(tag) {
        this.name = tag.name || 'empty';
        this.attributes = tag.attributes || (tag.attribs || []);
        this.dataType = tag.data_type || 'string';
        this.defaultValue = tag.default_value || 'empty';
    }

    createDefaultRender() { 
        return (<></>);
    }
}

export default Tag;
