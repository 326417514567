import React from 'react';
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";
import CreditCardForm from "@paylani/paylani-react-packages/dist/payments/ui/CreditCardForm";
import AchForm from "@paylani/paylani-react-packages/dist/payments/ui/AchForm";
import NumberDisplay from "@paylani/paylani-react-packages/dist/common/formatting/NumberDisplay";

const StatementPaymentForm = (props) => {
    const { statement, customer } = props;

    const onSubmitAsync = async (e) => {
        //
    };

    const accountMask = !!customer?.bankAccount?.accountNumber ? "*** *** " + customer.bankAccount.accountNumber.substr(customer.bankAccount.accountNumber.length - 4) : ""; 
    
    const achSelect = !!customer?.bankAccount?.accountNumber ? (<div className={"payment-selector selected"}>
        <span>Pay By ACH: { accountMask}</span><span><NumberDisplay value={statement.total} type={"currency"} /></span></div>) : null;
    const achForm = achSelect === null ? (<AchForm ach={customer?.bankAccount} />) : null;

    const creditCardSelect = achSelect === null && !!customer.customerVaultId ? (<div className={"payment-selector selected"}>Pay By Credit Card on File: { customer?.creditCard?.getCardMask()}</div>) : null;
    const creditCardForm = creditCardSelect === null ? (<CreditCardForm />) : null;
    
    return (<div className={"form x-large"}>
        {achSelect}
        {creditCardSelect}
    </div>);
};

export default StatementPaymentForm;
